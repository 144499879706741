// @ts-nocheck

import HlsMediaTech from '@package/media-player-tech/src/tech/hls/hls-instance';
import Html5MediaTech from '@package/media-player-tech/src/tech/html5/html5-instance';
import ShakaPlayerMediaTech from '@package/media-player-tech/src/tech/shaka/shaka-player-instance';
import type { TimeSeconds } from '@PLAYER/player/base/number';
import type { PlayerProjector } from '@PLAYER/player/modules/instance/interfaces';
import { FeatureToggle } from '@SDK/api';
import { deviceService, featuresService } from '@SMART/services';
import { OperationSystem } from '@SMART/services/device/device-types';
import type { HlsConfig } from 'hls.js';

interface Options {
  isDurationInfinity?: boolean;
  projector: PlayerProjector;
}

const videoMaxHeightLevelMap: Record<PlayerProjector, number> = {
  vod: Infinity,
  live: Infinity,
  kinom: 500,
  'content-background': 500,
  'smarttv-main-page-player': 500,
  'my-channel': 800,
  playlist: Infinity,
};

interface BufferRecordType {
  bufferingGoalBefore: TimeSeconds;
  bufferingGoalAfter: TimeSeconds;
  rebufferingGoal: TimeSeconds;
}

const kinomBufferMap: BufferRecordType = {
  bufferingGoalBefore: 3,
  bufferingGoalAfter: 6,
  rebufferingGoal: 1,
};

const bufferSizeProjectorMap: Record<PlayerProjector, BufferRecordType> = {
  vod: {
    bufferingGoalBefore: 18,
    bufferingGoalAfter: 60,
    rebufferingGoal: 1,
  },
  live: {
    bufferingGoalBefore: 24,
    bufferingGoalAfter: 36,
    rebufferingGoal: 1,
  },
  kinom: kinomBufferMap,
  playlist: kinomBufferMap,
  'smarttv-main-page-player': kinomBufferMap,
  'my-channel': kinomBufferMap,
  'content-background': kinomBufferMap,
};

export default function usePlayerMediaSourceTech() {
  const initializeMediaSourceTech = (options: Options = {}) => {
    const isShakaApiEnabled = featuresService.getFeatureFlag(FeatureToggle.ShakaPlayerApiEnabled).status;
    const { isDurationInfinity = false, projector } = options;

    const { bufferingGoalAfter, bufferingGoalBefore, rebufferingGoal } = bufferSizeProjectorMap[projector];

    if (isShakaApiEnabled) {
      const shakaConfig: Partial<shaka.extern.PlayerConfiguration> = {
        restrictions: {
          maxHeight: videoMaxHeightLevelMap[projector],
        },
        abr: {
          clearBufferSwitch: true,
        },
        manifest: {
          hls: {
            liveSegmentsDelay: 3,
          },
        },
        streaming: {
          stallEnabled: true,
          bufferingGoal: bufferingGoalAfter,
          bufferBehind: bufferingGoalBefore,
          rebufferingGoal,
        },
      };

      return new ShakaPlayerMediaTech({ shakaConfig });
    }

    if (deviceService.os === OperationSystem.Desktop) {
      const hlsConfig: Partial<HlsConfig> = {
        backBufferLength: bufferingGoalBefore,
        maxBufferLength: bufferingGoalAfter,
      };

      if (projector === 'live') {
        hlsConfig.liveSyncDuration = 3 * 6;
      }

      return new HlsMediaTech({ hlsConfig });
    }

    return new Html5MediaTech({ isDurationInfinity });
  };

  return {
    initializeMediaSourceTech,
  };
}
