<template>
  <div :class="$style.wrapper">
    <h1 v-if="isTitleShown && title" :class="$style.title">
      {{ title }}
    </h1>

    <p v-if="isTitleShown && subtitle" :class="$style.subtitle">
      {{ subtitle }}
    </p>

    <div v-if="limit" :class="$style.limit">
      <div :class="$style.limitTag">{{ limit }}+</div>

      <div :class="$style.limitDescription">
        {{ $t('player.smoking') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isTitleShown: {
      type: Boolean,
      required: false,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    limit: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/fonts';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: adjustPx(32px) adjustPx(48px);
}

.title {
  color: var(--color-notheme-text-primary);

  @include f-headline;
}

.subtitle {
  margin-top: adjustPx(8px);
  color: var(--color-notheme-text-secondary);
}

.limit {
  display: flex;
  margin-top: adjustPx(24px);
  color: var(--color-notheme-text-primary);

  &Tag {
    padding: adjustPx(16px);
    border: 1px solid var(--color-notheme-icon-primary);
    border-radius: adjustPx(20px);

    @include f-body;
  }

  &Description {
    margin-left: adjustPx(16px);
    max-width: adjustPx(600px);
    overflow: hidden;

    @include f-caption-2;
  }
}
</style>
