import useLogger from '@package/logger/src/use-logger';
import { Disposable } from '@package/sdk/src/core';

import { globalSettings } from '../global-settings';

const logger = useLogger('environment-service');

type EnvironmentVariableKey =
  | 'buildDate'
  | 'apiRCSocketURL'
  | 'isStrictMode'
  | 'debugApiBaseProdURL'
  | 'yandexMetricaId'
  | 'webAppURL'
  | 'dsmlPartnerId'
  | 'dsmlPassword'
  | 'appVersion'
  | 'apiBaseURL'
  | 'apiSecretKey'
  | 'isDev'
  | 'isRelease'
  | 'apiFlagsBaseURL';

export class EnvironmentService extends Disposable {
  private map: Record<EnvironmentVariableKey, any> = {
    debugApiBaseProdURL: 'https://api.viju.ru/',
    buildDate: process.env.BUILD_DATE,
    yandexMetricaId: '95696750',
    apiSecretKey: 'a2jCEwAWqLepW4A+V/4hMIRCr5PSb5bzoeLUEFtkm61Qa3JfdVVbacqJtvHPLJc6xdt3VMtHVL2zfbzcBWVSXA==',
    appVersion: () => this.getAppVersion(),
    isDev: process.env.NODE_ENV === 'development',
    apiRCSocketURL:
      (this.isDev ? this.getEnv('API_RC_SOCKET_URL') : process.env.API_RC_SOCKET_URL) ||
      'wss://centrifugo-ws.testvip.ru/connection/websocket',
    webAppURL: (this.isDev ? this.getEnv('WEB_APP_URL') : process.env.WEB_APP_URL) || 'https://viju.ru/',
    dsmlPartnerId:
      (this.isDev ? this.getEnv('DSML_PARTNER_ID') : process.env.DSML_PARTNER_ID) ||
      '581e818b-0058-4fda-808c-d9c41c864640',
    dsmlPassword: (this.isDev ? this.getEnv('DSML_PASSWORD') : process.env.DSML_PASSWORD) || 'eJh2IP1DbwtUN#O<',
    apiBaseURL: (this.isDev ? this.getEnv('API_BASE_URL') : process.env.API_BASE_URL) || 'https://api.viju.ru/',
    isRelease: (this.isDev ? this.getEnv('IS_RELEASE_MODE') : process.env.IS_RELEASE_MODE) === 'true',
    isStrictMode: false,
    apiFlagsBaseURL: this.isDev
      ? this.getEnv('API_FLAGS_BASE_URL')
      : process.env.API_FLAGS_BASE_URL || 'https://flags.viju.ru/',
  };

  constructor() {
    super();
  }

  private get isDev() {
    return process.env.NODE_ENV === 'development';
  }

  private getEnv(env: string) {
    if (this.isDev) {
      return process.env[env];
    }

    return process.env[env];
  }

  private getAppVersion() {
    const version = process.env.APP_VERSION;

    if (process.env.IS_RELEASE_MODE === 'true') {
      return `${version}-prod-${globalSettings.vueVersion === 'vue3' ? 'modern' : 'legacy'}`;
    }

    return `${version}-stage-${globalSettings.vueVersion === 'vue3' ? 'modern' : 'legacy'}`;
  }

  public init(vars: Partial<Record<EnvironmentVariableKey, any>>) {
    this.map = {
      ...this.map,
      ...vars,
    };
  }

  public getVariable<T>(key: EnvironmentVariableKey): T {
    if (key in this.map) {
      return this.map[key];
    }

    logger.warn(`EnvironmentService - Expect ${key} to be defined`);

    return this.map[key];
  }
}
