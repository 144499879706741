import { AvailableContentType, type Media } from '@package/sdk/src/api';

const availableStatusWithMessage = [
  AvailableContentType.UNAVAILABLE,
  AvailableContentType.UNAVAILABLESOON,
  AvailableContentType.AVAILABLESOON,
];

export default function useMediaContentAvailability() {
  const isUnavailable = (content?: Media) => {
    const availability = content?.availability;

    return (
      availability === AvailableContentType.UNAVAILABLE || availability === AvailableContentType.TimeshiftAvailable
    );
  };

  const isAvailableSoon = (content?: Media) => content?.availability === AvailableContentType.AVAILABLESOON;

  const isUnavailableSoon = (content?: Media) => content?.availability === AvailableContentType.UNAVAILABLESOON;

  const isAvailable = (content?: Media) =>
    content?.availability === AvailableContentType.AVAILABLE || isUnavailableSoon(content);

  const isAvailabilityOverlayShown = (content: Media) => availableStatusWithMessage.includes(content.availability);

  return {
    showAvailabilityOverlay: isAvailabilityOverlayShown, // support for old naming
    isAvailabilityOverlayShown,
    isAvailable,
    isAvailableSoon,
    isUnavailable,
    isUnavailableSoon,
  };
}
