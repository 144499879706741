// @ts-nocheck

import useLogger from '@package/logger/src/use-logger';
import { isUndefinedOrNull } from '@package/sdk/src/core/std/types';

import {
  DEFAULT_APP_ID,
  DEFAULT_DEVICE_NAME,
  getSafeApplicationId,
  getSafeHumanReadableModelName,
  getTizenVersion,
  registerTizenKeys,
  TIZEN_CHROME_VERSION,
  VENDOR_NAME,
} from '../../utils/get-tizen-utils';
import { globalSettings } from '../global-settings';
import { AbstractManufacturerService } from './abstract-manufacturer-service';
import type { SetVolumeLevel } from './device-types';
import { TizenPerformanceFeaturesMap } from './performance-features';

const logger = useLogger('tizen-service');

export namespace TizenOS {
  export interface ApplicationInstance {
    exit(): void;
    appInfo: {
      id: string;
    };
  }

  export interface TVInputDeviceService {
    registerKey(key: string): void;
  }

  export interface ApplicationService {
    getCurrentApplication(): ApplicationInstance;
  }

  export interface NetworkService {
    isConnectedToGateway(): boolean;
  }

  export interface Instance {
    tvinputdevice: TVInputDeviceService;
    application: ApplicationService;
    network: NetworkService;
  }
}

declare global {
  const tizen: TizenOS.Instance;

  interface Window {
    tizen: TizenOS.Instance;
  }
}

export class TizenService extends AbstractManufacturerService {
  constructor() {
    const tizenVersion = getTizenVersion();
    const chromeVersion = TIZEN_CHROME_VERSION[tizenVersion.toString()];

    super(new TizenPerformanceFeaturesMap(), chromeVersion);
  }

  public setVolume(volume: SetVolumeLevel) {
    console.info(volume);
  }

  public resolveStartupDeeplink(): Promise<void> {
    return Promise.resolve(undefined);
  }

  public getVolume(): number {
    return 0;
  }

  public getHumanReadableDeviceName(): string {
    return getSafeHumanReadableModelName(logger);
  }

  public getManufacturerName(): string {
    return VENDOR_NAME;
  }

  public getDeviceInfo(): unknown {
    return {
      appId: getSafeApplicationId(),
      modelName: this.getHumanReadableDeviceName(),
    };
  }

  public init() {
    try {
      for (const key of registerTizenKeys) {
        window.tizen.tvinputdevice.registerKey(key);
      }
    } catch (error) {
      logger.error('TizenService#init', JSON.stringify(error.message));
    }

    return Promise.resolve(undefined);
  }

  public exit() {
    try {
      window.tizen.application.getCurrentApplication().exit();
      return true;
    } catch (error) {
      globalSettings.sentry.captureException(error);
      return false;
    }
  }
}
