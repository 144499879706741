import { isUndefinedOrNull } from '@package/sdk/src/core';

import { globalSettings, type InternalRef } from '../global-settings';

export enum AlertMessageTypes {
  Error = 'error',
  ErrorVoiceSearch = 'error-voice-search',
  Success = 'success',
  Warning = 'warning',
}

export interface AlertOptions {
  message: string;
  type?: AlertMessageTypes;
  hideIcon?: boolean;
  timeoutMs?: number;
}

export const DEFAULT_ALERT_HIDE_TIMEOUT_MS = 3000;

export class AlertService {
  public alerts: InternalRef<AlertOptions[]> | undefined;
  private closeTimeout = 0;

  public initialize() {
    this.alerts = globalSettings.ref([]);
  }

  public addAlert(options: AlertOptions) {
    if (!this.alerts) {
      return;
    }

    if (isUndefinedOrNull(options.timeoutMs)) {
      options.timeoutMs = DEFAULT_ALERT_HIDE_TIMEOUT_MS;
    }

    if (isUndefinedOrNull(options.type)) {
      options.type = AlertMessageTypes.Success;
    }

    clearTimeout(this.closeTimeout);
    this.alerts.value = [options];

    if (options.timeoutMs) {
      this.closeTimeout = window.setTimeout(() => {
        if (!this.alerts) {
          return;
        }

        this.alerts.value = [];
      }, options.timeoutMs);
    }
  }

  public closeAlert(index: number) {
    if (!this.alerts) {
      return;
    }

    if (index < 0 || index > this.alerts.value.length || !this.alerts.value.length) {
      return;
    }

    const alerts = this.alerts.value.slice();
    alerts.splice(index, 1);
    this.alerts.value = alerts;
  }
}

export const alertService = new AlertService();
