import type { TPluralArgs } from '@package/content-utils/src/code/content-media-button-texts';
import useLogger from '@package/logger/src/use-logger';
import { AppCurrency } from '@package/sdk/src/api';
import { isString, safeReplaceAll } from '@package/sdk/src/core';
import { AppLanguage } from '@package/sdk/src/core/variation/variation';
import type { EpisodeData } from '@PLAYER/player/modules/content/player-texts';

import en from '../../../../../../translations/player/en.json';
import hy from '../../../../../../translations/player/hy.json';
import ru from '../../../../../../translations/player/ru.json';

const logger = useLogger('translate', 'media-player');

export function createCommonLocalizeDictionary(lang: AppLanguage): typeof ru {
  if (lang === AppLanguage.RU) {
    return ru;
  }

  if (lang === AppLanguage.EN) {
    return en as typeof ru;
  }

  if (lang === AppLanguage.AM) {
    return hy as typeof ru;
  }

  return {} as typeof ru;
}

export const AppLanguageManager = new (class {
  private currentLang = AppLanguage.RU;
  private _currentCurrency = AppCurrency.Rub;

  public translations = createCommonLocalizeDictionary(this.currentLang);

  public get currency(): AppCurrency {
    return this._currentCurrency;
  }

  private setTranslations(lang: AppLanguage) {
    this.currentLang = lang;

    this.translations = createCommonLocalizeDictionary(this.currentLang);
  }

  public setCurrency(currency: AppCurrency) {
    this._currentCurrency = currency;
  }

  public setLang(lang: AppLanguage) {
    this.setTranslations(lang);
  }
})();

type TranslationKey = keyof (typeof AppLanguageManager)['translations'];

export function t(key: TranslationKey, params?: Record<string, string | number> | EpisodeData) {
  // @ts-ignore
  let translation = AppLanguageManager.translations[key];

  if (!isString(translation)) {
    logger.info('translation not found for key:', key);

    return key;
  }

  if (!params) {
    return translation;
  }

  const entries = Object.entries(params);

  entries.push(['appCurrency', AppLanguageManager.currency]);

  entries.forEach(([keyToReplace, value]) => {
    try {
      if (translation) {
        translation = safeReplaceAll(translation, `{{ ${keyToReplace} }}`, value?.toString());
      }

      if (translation) {
        translation = safeReplaceAll(translation, `{{${keyToReplace}}}`, value?.toString());
      }
    } catch (error) {
      return translation;
    }
  });

  return translation;
}

export const translate = t;

/**
 * Функция для получения правильного ключа для числительных
 *
 * @param key
 * @param count
 * @returns {string}
 */
function pluralize(key: TranslationKey, count: number): TranslationKey {
  let n = Math.abs(count);
  n = n % 100;

  if (n >= 5 && n <= 20) {
    return (key + '5') as TranslationKey;
  }

  n = n % 10;

  if (n === 1) {
    return (key + '1') as TranslationKey;
  }

  if (n >= 2 && n <= 4) {
    return (key + '2') as TranslationKey;
  }

  return (key + '5') as TranslationKey;
}

/**
 * Перевод ключей с числительными
 *
 * {
 *   away1: 'Вас не было {{ value }} день',
 *   away2: 'Вас не было {{ value }} дня',
 *   away5: 'Вас не было {{ value }} дней'
 * }
 * @example tPlural('away', 2, { value: 32 }) -> 'Вас не было 32 дня'
 *
 * @param key
 * @param count
 * @param params
 * @returns {string}
 */
export function tPlural(key: string, count: number, params?: Record<string, string | number>): string {
  const pluralKey = pluralize(key as TranslationKey, count);

  return t(pluralKey, params);
}

export function translateContentButtonText(data: string | undefined | TPluralArgs) {
  if (!data) {
    return undefined;
  }

  if (isString(data)) {
    return data;
  }

  const { key, count, params } = data;

  if (count) {
    const pluralKey = pluralize(key as TranslationKey, count as number);
    return t(pluralKey, params);
  }

  return t(key as TranslationKey, params);
}
