import { isUndefinedOrNull } from '@package/sdk/src/core';
import type { ConsolaInstance } from 'consola';

namespace TizenOS {
  export interface ApplicationInstance {
    exit(): void;
    appInfo: {
      id: string;
    };
  }

  export interface TVInputDeviceService {
    registerKey(key: string): void;
  }

  export interface ApplicationService {
    getCurrentApplication(): ApplicationInstance;
  }

  export interface NetworkService {
    isConnectedToGateway(): boolean;
  }

  export interface Instance {
    tvinputdevice: TVInputDeviceService;
    application: ApplicationService;
    network: NetworkService;
  }
}

declare global {
  interface Window {
    tizen: TizenOS.Instance;
  }
}

export function getTizenVersion(userAgent = navigator.userAgent) {
  try {
    const agents = userAgent.split(' ');

    for (let i = 0; i < agents.length; i++) {
      if (agents[i].substring(0, 5) === 'Tizen') {
        return parseFloat(agents[i + 1]);
      }
    }

    return 0;
  } catch (error) {
    return 0;
  }
}

export const TIZEN_CHROME_VERSION = {
  '8': 108,
  '7': 94,
  '6.5': 85,
  '6': 76,
  '5.5': 69,
  '5': 63,
  '4': 56,
  '3': 47,
};

export const VENDOR_NAME = 'Samsung';
export const DEFAULT_DEVICE_NAME = `${VENDOR_NAME}`;
export const DEFAULT_APP_ID = 'samsung.unknown.appId';

export const registerTizenKeys = [
  'MediaPlay',
  'MediaPause',
  'MediaStop',
  'MediaRewind',
  'MediaFastForward',
  'ChannelUp',
  'ChannelDown',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
];

export const getSafeHumanReadableModelName = (logger: ConsolaInstance) => {
  // @ts-ignore
  if (isUndefinedOrNull(window.webapis)) {
    return DEFAULT_DEVICE_NAME;
  }

  try {
    // @ts-ignore
    const realModelName = window.webapis.productinfo.getRealModel();
    // @ts-ignore
    const realModelCode = window.webapis.productinfo.getModelCode();

    if (realModelName) {
      return `${VENDOR_NAME} ${realModelName} ${realModelCode}`;
    }
  } catch (error) {
    logger.error(error);

    return DEFAULT_DEVICE_NAME;
  }

  return DEFAULT_DEVICE_NAME;
};

export const getSafeApplicationId = () => {
  try {
    // @ts-ignore
    const id = window.tizen.application.getCurrentApplication().appInfo.id;

    if (id) {
      return id;
    }

    return DEFAULT_APP_ID;
  } catch (error) {
    return DEFAULT_APP_ID;
  }
};

if (import.meta.vitest) {
  const userAgents = {
    '7.0':
      'Mozilla/5.0 (SMART-TV; LINUX; Tizen 7.0) AppleWebKit/537.36 (KHTML, like Gecko) 94.0.4606.31/7.0 TV Safari/537.36',
    '6.5':
      'Mozilla/5.0 (SMART-TV; LINUX; Tizen 6.5) AppleWebKit/537.36 (KHTML, like Gecko) 85.0.4183.93/6.5 TV Safari/537.36',
    '6.0':
      'Mozilla/5.0 (SMART-TV; LINUX; Tizen 6.0) AppleWebKit/537.36 (KHTML, like Gecko) 76.0.3809.146/6.0 TV Safari/537.36',
    '5.5':
      'Mozilla/5.0 (SMART-TV; LINUX; Tizen 5.5) AppleWebKit/537.36 (KHTML, like Gecko) 69.0.3497.106.1/5.5 TV Safari/537.36',
    '5.0':
      'Mozilla/5.0 (SMART-TV; LINUX; Tizen 5.0) AppleWebKit/537.36 (KHTML, like Gecko) Version/5.0 TV Safari/537.36',
    '4.0':
      'Mozilla/5.0 (SMART-TV; LINUX; Tizen 4.0) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 TV Safari/537.36',
    '3.0': 'Mozilla/5.0 (SMART-TV; LINUX; Tizen 3.0) AppleWebKit/538.1 (KHTML, like Gecko) Version/3.0 TV Safari/538.1',
    '2.4':
      'Mozilla/5.0 (SMART-TV; Linux; Tizen 2.4.0) AppleWebKit/538.1 (KHTML, like Gecko) Version/2.4.0 TV Safari/538.1',
    '2.3':
      'Mozilla/5.0 (SMART-TV; Linux; Tizen 2.2) AppleWebkit/538.1 (KHTML, like Gecko) SamsungBrowser/1.0 TV Safari/538.1',
  };

  const { it, expect } = import.meta.vitest;

  it('Tizen 7.0 return 7', () => {
    expect(getTizenVersion(userAgents['7.0'])).toBe(7);
  });

  it('Tizen 6.0 return 6', () => {
    expect(getTizenVersion(userAgents['6.0'])).toBe(6);
  });

  it('Tizen 6.5 return 6.5', () => {
    expect(getTizenVersion(userAgents['6.5'])).toBe(6.5);
  });

  it('Tizen 6.0 return 6', () => {
    expect(getTizenVersion(userAgents['6.0'])).toBe(6.0);
  });

  it('Tizen 5.5 return 5.5', () => {
    expect(getTizenVersion(userAgents['5.5'])).toBe(5.5);
  });

  it('Tizen 5.0 return 5', () => {
    expect(getTizenVersion(userAgents['5.0'])).toBe(5);
  });

  it('Tizen 4.0 return 4', () => {
    expect(getTizenVersion(userAgents['4.0'])).toBe(4);
  });

  it('Tizen 3.0 return 3', () => {
    expect(getTizenVersion(userAgents['3.0'])).toBe(3);
  });

  it('Tizen 2.4 return 2.4', () => {
    expect(getTizenVersion(userAgents['2.4'])).toBe(2.4);
  });

  it('Tizen 2.3 return 2.3', () => {
    expect(getTizenVersion(userAgents['2.3'])).toBe(2.2);
  });

  it('None Tizen platform return 0', () => {
    expect(getTizenVersion('broken user agent')).toBe(0);
  });
}
