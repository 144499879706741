import useLogger from '@package/logger/src/use-logger';

import { globalSettings } from '../services';

export type AuthTokenPayload = {
  user_id: string;
  profile_id: string;
  visitor_id: string;
};

const logger = useLogger('use-jwt-decoder');

export function useJwtDecoder() {
  const decode = (token: string): AuthTokenPayload | undefined => {
    try {
      return globalSettings.jwt(token);
    } catch (err) {
      logger.error('Unexpected situation: Bad JWT token.');
    }
  };

  return { decode };
}
