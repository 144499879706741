<template>
  <section :class="$style.filter">
    <div :class="$style.offset"></div>
    <NavigatableItem
      :tag="AppButton"
      autofocus
      :class="$style.filterHeader"
      :active-class="$style.active"
      :text="title"
      :prop-parent-focus-key="parentFocusKey"
      :text-class-name="$style.filterTitle"
      :on-click="() => $emit('back')"
    >
      <template v-slot:icon>
        <IconArrowLeft :class="$style.filterIcon" />
      </template>
    </NavigatableItem>

    <div :class="$style.filterScroll">
      <ScrollViewport tag="ul" :y="offsetTopPx" role="list">
        <li v-for="(item, index) in items" :key="getTitle(item)" ref="list">
          <NavigatableItem
            ref="wrapperRef"
            :tag="AppButton"
            :text="getTitle(item)"
            :prop-parent-focus-key="parentFocusKey"
            :text-class-name="$style.filterText"
            :class="{
              [$style.filterItem]: true,
              [$style.filterItemSelected]: checkIsSelectedFilterItem(item),
            }"
            :active-class="$style.active"
            :on-click="() => $emit('toggle', item)"
            @active="handleActive(list, index)"
          >
            <template v-if="checkIsSelectedFilterItem(item)" v-slot:icon>
              <IconCheck :class="$style.filterIcon" />
            </template>
          </NavigatableItem>
        </li>
      </ScrollViewport>
    </div>
  </section>
</template>

<script>
import IconCheck from '@SMART/assets/icons/33x33/check.svg';
import IconArrowLeft from '@SMART/assets/icons/51x51/arrow-left.svg';
import { ref } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';
import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';
export default {
  components: {
    IconCheck,
    IconArrowLeft,
    ScrollViewport,
  },
  props: {
    parentFocusKey: {
      type: String,
      required: false,
    },
    title: {
      type: String,
    },
    idKey: {
      type: String,
    },
    getTitle: {
      type: Function,
      default: (item) => item.title,
    },
    selectedItems: {
      type: Array,
    },
    items: {
      type: Array,
    },
  },
  setup(props) {
    const list = ref();
    const offsetTopPx = ref(0);

    const checkIsSelectedFilterItem = (item) => props.selectedItems.find((c) => c[props.idKey] === item[props.idKey]);

    const handleActive = (list, index) => {
      const element = list[index];

      offsetTopPx.value = element?.offsetTop || 0;
    };

    const wrapperRef = ref(null);

    return {
      wrapperRef,
      AppButton,
      checkIsSelectedFilterItem,
      handleActive,
      list,
      offsetTopPx,
    };
  },
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/fonts';
@import '@/styles/colors';

.offset {
  z-index: 1;
  height: adjustPx(16px);
  min-height: adjustPx(16px);
  background: var(--color-stroke-field-border);
}

.filter {
  position: fixed;
  top: adjustPx(40px);
  bottom: adjustPx(40px);
  right: adjustPx(40px);
  display: flex;
  flex-flow: column;
  width: adjustPx(567px);
  border-radius: adjustPx(41px);
  background: var(--color-stroke-field-border);
  opacity: 0.98;
  overflow: hidden;

  &Scroll {
    position: relative;
  }

  &Header {
    z-index: 1;
    display: flex;
    flex-flow: row;
    align-items: center;
    margin: 0 adjustPx(16px);
    padding: adjustPx(55px) adjustPx(31px);
    height: adjustPx(141px);
    min-height: adjustPx(141px);
    border-color: transparent;
    border-radius: adjustPx(24px);
    background: var(--color-stroke-field-border);

    @include f-headline-2;
  }

  &Item {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 adjustPx(16px);
    padding-left: adjustPx(60px);
    max-width: adjustPx(530px);
    height: adjustPx(86px);
    border-color: transparent;
    border-radius: adjustPx(24px);
    background-color: transparent;

    @include f-subtitle-2;
  }

  &ItemSelected {
    flex-flow: row-reverse;
  }

  &Icon {
    margin-bottom: adjustPx(6px);
    width: adjustPx(40px);
    height: adjustPx(40px);
  }

  &Text {
    @include f-subtitle-2;
  }

  &Title {
    @include f-headline-2;
    color: var(--color-text-primary);
  }

  .active {
    background-color: var(--color-bg-accent);
  }
}

.filter::-webkit-scrollbar {
  display: none;
}
</style>
