<template>
  <article ref="el" class="page-container">
    <h1 :class="$style.title">
      {{ $t('offers.title') }}
    </h1>

    <SkeletonPosters v-if="isLoading" :class="$style.offers" size="offer" />

    <ScrollViewport v-show="!isLoading" tag="ul" :class="$style.offers" :x="offsetLeftPx" role="list">
      <li v-for="(offer, index) in offers" :key="offer.id" :class="$style.item">
        <UserOffer
          :class="$style.offersItem"
          :title="offer.cardTitle"
          :benefits="offer.cardBenefits.slice(0, 3).reverse()"
          :price="offer.cardPrice"
          :marked-best="offer.markedBest"
          :badge="offer.badge"
          :on-click="() => onSelectOffer(offer)"
          @active="handleActiveItem(index)"
        />
      </li>
    </ScrollViewport>
    <section :class="$style.benefits">
      <p v-for="benefit in offersInfo.bullets" :key="benefit" :class="$style.benefitsItem">
        <CheckIcon :class="$style.benefitsIcon" />
        {{ benefit }}
      </p>
    </section>
    <NavigatableItem
      v-if="!isAuth"
      :tag="AppButton"
      :class="$style.loginButton"
      :text="$t('offers.loginButton')"
      :on-click="onLogin"
    />
  </article>
</template>

<script>
import ConstantsConfigInstanceSmartTV from '@package/constants/code/constants-config-smart-tv';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import CheckIcon from '@SMART/assets/icons/33x33/check.svg';
import {
  offersService,
  storeToRefs,
  useAuthActions,
  useAuthStore,
  useOfferActions,
  useSessionStore,
} from '@SMART/index';
import { onMounted, provide, ref } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';
import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';
import SkeletonPosters from '@/components/skeletons/SkeletonPosters.vue';
import UserOffer from '@/components/user-offer/UserOffer.vue';

export default {
  components: {
    CheckIcon,
    ScrollViewport,
    SkeletonPosters,
    UserOffer,
  },
  setup() {
    const sessionStore = useSessionStore();
    const authStore = useAuthStore();

    const { isAuth, offers } = storeToRefs(sessionStore);
    const { openSelectedOfferPage } = useOfferActions();
    const { openAuthPage } = useAuthActions();

    const { el, focusSelf, focusKey } = useNavigatable({ focusKey: 'OFFERS_PAGE' });
    provide('parentFocusKey', focusKey.value);

    const isLoading = ref(true);

    const offersInfo = ref({});
    const offsetLeftPx = ref(0);

    const handleActiveItem = (index) => {
      // fixed height for old browsers compatibility
      offsetLeftPx.value = index * ConstantsConfigInstanceSmartTV.getProperty('offerCardWidthPx');
    };

    const onSelectOffer = (offer) => {
      authStore.setSelectedOfferId(offer.id);

      if (!isAuth.value) {
        return openAuthPage();
      }

      return openSelectedOfferPage();
    };

    const onLogin = () => openAuthPage();

    onMounted(async () => {
      const [offersInfoData] = await Promise.all([offersService.fetchOffersInfo()]);

      offersInfo.value = offersInfoData;
      isLoading.value = false;

      focusSelf();
    });

    return {
      el,
      AppButton,
      isLoading,
      offsetLeftPx,
      offers,
      handleActiveItem,
      onSelectOffer,
      offersInfo,
      isAuth,
      onLogin,
    };
  },
};
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/mixins';

.title {
  margin-bottom: adjustPx(55px);

  @include f-title-2;
}

.offers {
  display: flex;
  flex-flow: row;
  padding-top: adjustPx(55px);
  width: max-content;
  overflow: auto;

  .item {
    margin-right: adjustPx(27px);
  }

  &Item {
    margin-right: adjustPx(27px);
    width: adjustPx(640px);
    min-width: adjustPx(640px);
    height: adjustPx(350px);
    min-height: adjustPx(321px);
  }
}

.offers::-webkit-scrollbar {
  display: none;
}

.benefits {
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin-top: adjustPx(30px);

  &Item {
    display: flex;
    align-items: center;
    margin-top: adjustPx(16px);

    @include f-body;
  }

  &Icon {
    margin-right: adjustPx(16px);
    width: adjustPx(33px);
    height: adjustPx(33px);
  }
}

.loginButton {
  margin-top: adjustPx(20px);
  @include f-subtitle;
}
</style>
