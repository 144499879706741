import useMediaContentAvailability from '@package/content-utils/src/code/use-media-content-availability';
import {
  type ContentAccessKind,
  ContentAccessTypes,
  type Media,
  type Offer,
  SubscriptionState,
  type TVChannelAccessKind,
} from '@package/sdk/src/api';
import { isDefined } from '@package/sdk/src/core';
import useContentAvailability from '@PLAYER/player/modules/content/use-availability';
import { tPlural, translate } from '@PLAYER/player/modules/localization/translate';

export interface EpisodeData {
  episode: number;
  season: number;
}

export interface SubscribePromoTexts {
  subscribeButtonText?: string;
  subscribeButtonSubtext?: string;
}

export const DEFAULT_TRIAL_PRICE = 1;
export const DEFAULT_TRIAL_DURATION = 14;

export enum OfferTextType {
  Try = 'try',
  WatchTrial = 'watchByTrial',
  WatchPrice = 'watchByPrice',
  ContinueWatchPrice = 'continueWatchByPrice',
  Partner = 'partner',
}

export const getOfferText = (
  offer: Offer | undefined,
  offerTextType: OfferTextType,
  subscribePromoTexts: SubscribePromoTexts = {},
): string => {
  const price = offer?.price || DEFAULT_TRIAL_PRICE;
  const duration = offer?.trialDuration || DEFAULT_TRIAL_DURATION;

  const { subscribeButtonText } = subscribePromoTexts;

  if (subscribeButtonText) {
    return subscribeButtonText;
  }

  switch (offerTextType) {
    case OfferTextType.Try:
      return tPlural('offer.common.tryForDaysAndPrice', duration, { count: duration, price: DEFAULT_TRIAL_PRICE });
    case OfferTextType.WatchTrial:
      return tPlural('offer.common.watchDaysByTrial', duration, { count: duration, price: DEFAULT_TRIAL_PRICE });
    case OfferTextType.WatchPrice:
      return translate('offer.common.watchByPrice', { price });
    case OfferTextType.ContinueWatchPrice:
      return translate('offer.common.continueWatchByPrice', { price });
    case OfferTextType.Partner:
      return translate('offer.partner.continueSubscription');
    default:
      return translate('button.watch');
  }
};

interface GetPlayButtonTextOptions {
  subscribePromoTexts?: SubscribePromoTexts;
  accessKind?: TVChannelAccessKind;
  isActiveSubscription?: boolean;
  isPartnerSubscription?: boolean;
  hasTrialOffer?: boolean;
  isVOD?: boolean;
  isAuth?: boolean;
  isLive?: boolean;
  canContinueWatch?: boolean;
  offer?: any;
  subscription?: any;
  content?: Media;
  episodeData?: EpisodeData;
  isWatchButtonWithContentTypeText?: boolean;
  isForceSimpleWatchButtonText?: boolean;
}

export const getPlayButtonText = (options: GetPlayButtonTextOptions): string => {
  const {
    isLive,
    isAuth,
    isVOD,
    canContinueWatch,
    isActiveSubscription,
    isPartnerSubscription,
    subscription,
    episodeData,
    hasTrialOffer,
    offer,
    accessKind,
    subscribePromoTexts = {},
    isWatchButtonWithContentTypeText,
    isForceSimpleWatchButtonText,
  } = options;

  if (isForceSimpleWatchButtonText) {
    return translate('button.watch');
  }

  // Если подписка активная
  if (isActiveSubscription) {
    // Если это плеер сериала или фильма
    if (isVOD && isWatchButtonWithContentTypeText) {
      // если есть watching item
      if (canContinueWatch) {
        return translate('button.continueWatch');
      }
      // если это сериал
      if (episodeData?.episode && episodeData?.season) {
        return translate('button.watchEpisode', episodeData);
      }
      // если фильм
      return translate('button.watchMovie');
    }

    // Если это плеер трансляции или тв-канала
    if (isLive && isDefined(accessKind)) {
      return translate('button.watchLive');
    }

    // Для остального просто "Смотреть"
    return translate('button.watch');
  }

  const hasFreeEpisode = episodeData?.episode && episodeData?.season && accessKind === ContentAccessTypes.AllUsers;

  if (hasFreeEpisode && !isActiveSubscription && !isPartnerSubscription) {
    return translate('button.watchForFree');
  }

  if (isAuth && !isActiveSubscription) {
    const canWatchContent =
      isDefined(accessKind) && (accessKind === 'register' || accessKind === ContentAccessTypes.AllUsers);

    if (canWatchContent || isPartnerSubscription) {
      if (isLive) {
        return translate('button.watchLive');
      }

      return translate('button.watch');
    }
  }

  if (!isAuth) {
    if (isLive) {
      if (isDefined(accessKind) && accessKind === 'register') {
        return translate('button.registerToWatchChannel');
      }

      if (isDefined(accessKind) && accessKind === ContentAccessTypes.AllUsers) {
        return translate('button.watchLive');
      }
    }

    return getOfferText(offer, OfferTextType.WatchTrial, subscribePromoTexts);
  }

  const textType =
    hasTrialOffer && subscription?.state !== SubscriptionState.Canceled
      ? OfferTextType.WatchTrial
      : OfferTextType.WatchPrice;

  return getOfferText(offer, textType, subscribePromoTexts);
};

export const getPlayButtonSubtitle = (options: GetPlayButtonTextOptions): string => {
  const { isActiveSubscription, isPartnerSubscription, content, subscribePromoTexts = {} } = options;

  const { isAvailable } = useMediaContentAvailability();
  const { getAvailabilityMessage } = useContentAvailability();

  if (isPartnerSubscription && !isActiveSubscription) {
    return translate('offer.partner.continueSubscription');
  }

  if (!isAvailable(content) || isActiveSubscription) {
    return getAvailabilityMessage(content, {
      isActiveSubscription,
    });
  }

  if (subscribePromoTexts.subscribeButtonSubtext) {
    return subscribePromoTexts.subscribeButtonSubtext;
  }

  return '';
};
