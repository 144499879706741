<template>
  <div :class="$style.card">
    <div :class="$style.poster">
      <app-image v-if="poster" :key="poster" :src="poster" :width="258" />
    </div>

    <div :class="$style.content">
      <p v-if="subtitle.length" :class="$style.subtitle">
        <span :class="{ [$style.subtitleText]: true, [$style.subtitleTextShadow]: subtitle.length > 50 }">
          {{ subtitle }}
        </span>

        <span v-if="limit" :class="$style.limit">
          {{ limit }}
        </span>
      </p>

      <h3 :class="$style.title">
        {{ title }}
      </h3>

      <p :class="$style.description">
        {{ description }}
      </p>

      <div :class="$style.controls">
        <NavigatableItem
          :tag="AppButton"
          :active-class="$style.active"
          :disabled="disabled"
          autofocus
          :text="contentTitle"
          :on-click="onSelectContent"
        />

        <NavigatableItem
          :tag="AppButton"
          :class="$style.save"
          :active-class="$style.active"
          :on-click="() => (isAddedToCollection ? onRemoveCollectionItem() : onSaveCollectionItem())"
          @active="onSaveActive(true)"
          @inactive="onSaveActive(false)"
        >
          <template v-if="isAddedToCollection" v-slot:icon>
            <IconCheck />
          </template>
          <template v-else v-slot:icon>
            <IconSave />
          </template>
        </NavigatableItem>

        <div :class="$style.tooltip">
          <UITooltip v-show="shouldShowHelp" :message="$t(saveToCollectionKey)" position="right" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MediaContentType } from '@package/sdk/src/api';
import IconCheck from '@SMART/assets/icons/40x40/check.svg';
import IconSave from '@SMART/assets/icons/40x40/save.svg';
import {
  AlertMessageTypes,
  alertService,
  collectionService,
  storeToRefs,
  translate,
  useCatalogStore,
  useContentStore,
  useMediaContentActions,
} from '@SMART/index';
import { computed, ref, watch } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';
import AppImage from '@/components/app-image/AppImage.vue';
import UITooltip from '@/components/tooltip/UITooltip.vue';

export default {
  components: {
    IconCheck,
    IconSave,
    AppImage,
    UITooltip,
  },
  props: {
    id: {
      type: String,
    },
    contentType: {
      type: String,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    description: {
      type: String,
    },
    limit: {
      type: String,
    },
    poster: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
  },
  setup(props) {
    const catalogStore = useCatalogStore();
    const contentStore = useContentStore();

    const { openContentPage } = useMediaContentActions();

    const { content } = storeToRefs(contentStore);

    const isAddedToCollection = ref(
      content.value?.inUserCollection || collectionService.savedFilmsItems.includes(props.id),
    );
    const shouldShowHelp = ref(false);

    const saveToCollectionKey = computed(() =>
      isAddedToCollection.value ? 'pages.mediaCard.removeFromCollection' : 'pages.mediaCard.saveToCollection',
    );

    const contentTitle = computed(() => {
      if (props.contentType === MediaContentType.Serial) {
        return translate('pages.catalog.goToSerial');
      }

      return translate('pages.catalog.goToMovie');
    });

    const onSelectContent = () => {
      return openContentPage({ contentType: props.contentType, id: props.id });
    };

    const onSaveCollectionItem = async () => {
      try {
        await collectionService.saveItems([props.id], props.contentType);
        const type = props.contentType === MediaContentType.Movie ? translate('movie') : translate('serial');

        isAddedToCollection.value = true;
        // update in order to reload user collection
        catalogStore.setUpdated(true);

        alertService.addAlert({
          message: translate('pages.mediaCard.addedToCollection', { type }),
          type: AlertMessageTypes.Success,
        });
      } catch (e) {
        alertService.addAlert({ message: translate(e.message), type: AlertMessageTypes.Warning });
      }
    };

    const onRemoveCollectionItem = async () => {
      // update in order to reload user collection
      catalogStore.setUpdated(true);
      await collectionService.removeItems([props.id]);
      isAddedToCollection.value = false;
    };

    const onSaveActive = (value) => {
      shouldShowHelp.value = value;
    };

    watch(
      () => props.id,
      () => {
        isAddedToCollection.value =
          content.value?.inUserCollection || collectionService.savedFilmsItems.includes(props.id);
      },
    );

    return {
      AppButton,
      onSaveActive,
      onRemoveCollectionItem,
      onSaveCollectionItem,
      onSelectContent,
      contentTitle,
      saveToCollectionKey,
      shouldShowHelp,
      isAddedToCollection,
    };
  },
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/fonts';
@import '@/styles/colors';

.card {
  display: flex;
  flex: 1;
  padding: adjustPx(64px);
  width: adjustPx(1271px);
  border-radius: adjustPx(36px);
  background-color: var(--color-states-bg-secondary-pressed);
}

.poster {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: adjustPx(258px);
  height: adjustPx(360px);
  border-radius: adjustPx(40px);
  overflow: hidden;
}

.content {
  display: flex;
  flex: 1;
  flex-flow: column;
  padding-left: adjustPx(64px);
}

.subtitle {
  display: flex;
  align-items: center;
  overflow: hidden;
  color: var(--color-notheme-white-50);
  white-space: nowrap;

  @include f-caption;

  &Text {
    position: relative;
    display: inline-flex;
    max-width: adjustPx(750px);
    overflow: hidden;
    white-space: nowrap;

    &Shadow {
      &:after {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: adjustPx(69px);
        content: '';
      }
    }
  }
}

.limit {
  display: inline-flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  margin-left: adjustPx(16px);
  width: adjustPx(47px);
  height: adjustPx(23px);
  border: adjustPx(2px) solid var(--color-text-secondary);
  border-radius: adjustPx(8px);
  font-size: adjustPx(18px);
}

.title {
  display: -webkit-box;
  margin-top: adjustPx(8px);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  @include f-title-3;
}

.description {
  display: -webkit-box;
  margin-top: adjustPx(24px);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  @include f-body-2;
}

.controls {
  position: relative;
  display: flex;
  margin-top: auto;
  padding-top: adjustPx(32px);
}

.tooltip {
  position: relative;
  padding-top: adjustPx(7px);
}

.active {
  background-color: var(--color-bg-accent);
}

.save {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: adjustPx(16px);
  padding: 0;
  width: adjustPx(83px);
  height: adjustPx(83px);
  border-radius: adjustPx(28px);

  svg {
    display: inline-flex;
    width: adjustPx(34px);
    height: adjustPx(34px);
  }
}
</style>
