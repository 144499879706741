<template>
  <div :class="$style.qr">
    <div :class="$style.instruction">
      <section>
        <h1 :class="$style.instructionHeader">{{ $t('pages.auth.qrLogin.title') }}</h1>
        <ul :class="$style.instructionList" role="list">
          <li :class="$style.instructionListItem">{{ $t('pages.auth.qrLogin.stepLogin') }}</li>
          <li :class="$style.instructionListItem">{{ $t('pages.auth.qrLogin.stepOpenApp') }}</li>
          <li :class="$style.instructionListItem">{{ $t('pages.auth.qrLogin.stepCode') }}</li>
        </ul>
        <div v-if="time">
          <h1 :class="$style.instructionCode">{{ code }}</h1>
          <div :class="$style.instructionCodeTime">
            {{ $t('pages.auth.qrLogin.codeUpdatedAt', { time: timeLeft }) }}
          </div>
        </div>
      </section>
      <section ref="el" :class="$style.instructionControl">
        <NavigatableItem
          :tag="AppButton"
          autofocus
          :class="$style.instructionButton"
          :active-class="$style.active"
          :text="$t('pages.auth.qrLogin.loginByEmail')"
          :on-click="onChangeLoginMethod"
        />
        <NavigatableItem
          :tag="AppButton"
          :class="$style.instructionButton"
          :active-class="$style.active"
          :text="$t('pages.auth.qrLogin.needHelp')"
          :on-click="() => onToggleHelp(true)"
        />
      </section>
    </div>
    <section :class="$style.code">
      <canvas ref="canvasRef" :class="$style.codeCanvas" @click="onQRCodeClick"></canvas>
    </section>
    <NeedHelpModal v-if="isHelpSectionShown" @on-toggle-help="onToggleHelp" />
  </div>
</template>

<script>
import ConstantsConfigInstanceSmartTV from '@package/constants/code/constants-config-smart-tv';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import {
  authService,
  customEventsService,
  deviceService,
  environmentService,
  onboardingService,
  OperationSystem,
  qrService,
  useAuthActions,
  WebAppRoute,
  WebRouteQuery,
} from '@SMART/index';
import { computed, onBeforeUnmount, onMounted, provide, ref, watch } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';
import UIModal from '@/components/modal/UIModal.vue';
import NeedHelpModal from '@/pages/auth/components/NeedHelpModal.vue';

export default {
  components: {
    NeedHelpModal,
    UIModal,
  },
  setup(props, { emit }) {
    const { getRedirectRouterAfterAuthorization, redirectUserWithBackUrl } = useAuthActions();

    const { el, focusSelf, focusKey } = useNavigatable({
      focusKey: 'QR_LOGIN',
      forceFocus: true,
      saveLastFocusedChild: false,
    });

    provide('parentFocusKey', focusKey.value);

    const canvasRef = ref();

    const time = ref(0);
    const code = ref('');
    const webURL = ref('');
    const isHelpSectionShown = ref(false);

    let checkTvTokenInterval;
    let updateTimeInterval;

    const onQRCodeClick = () => {
      if (deviceService.os !== OperationSystem.Desktop) {
        return;
      }

      const browserWindow = window.open(webURL.value, '_blank');

      window.setTimeout(() => browserWindow?.close(), 15000);
    };

    const generateQr = async () => {
      const { tvCode, tvToken } = await authService.createTvToken();
      const webAppURL = environmentService.getVariable('webAppURL');

      if (!canvasRef.value) {
        throw new UnexpectedComponentStateError('canvasRef');
      }

      code.value = tvCode;

      const url = webAppURL + WebAppRoute.SmartTvCode + '?' + WebRouteQuery.SmartTvCode + '=' + tvCode;

      webURL.value = url;

      await qrService.createQRCode({
        canvasEl: canvasRef.value,
        text: url,
      });

      time.value = ConstantsConfigInstanceSmartTV.getProperty('expirationTimeQrSeconds');

      const onTokenUpdated = async () => {
        try {
          await authService.checkTvToken(tvToken);

          const route = getRedirectRouterAfterAuthorization();

          onboardingService.resetParentalCode();

          await redirectUserWithBackUrl(route);
        } catch (error) {
          // it's ok
        }
      };

      const onTimeUpdated = () => {
        time.value -= 1;
      };

      updateTimeInterval = window.setInterval(
        onTimeUpdated,
        ConstantsConfigInstanceSmartTV.getProperty('updateTimeQrTimeoutMs'),
      );
      checkTvTokenInterval = window.setInterval(
        onTokenUpdated,
        ConstantsConfigInstanceSmartTV.getProperty('authCheckTvTokenTimeoutMs'),
      );
    };

    watch(time, () => {
      if (time.value === 0 && updateTimeInterval && checkTvTokenInterval) {
        window.clearInterval(updateTimeInterval);
        window.clearInterval(checkTvTokenInterval);
        generateQr();
      }
    });

    const timeLeft = computed(() => {
      const minutes = `${Math.floor(time.value / 60)}`.padStart(2, '0');
      const seconds = `${time.value % 60}`.padStart(2, '0');

      return `${minutes}:${seconds}`;
    });

    const onChangeLoginMethod = () => {
      emit('update:type', 'email');
      customEventsService.setOnPressBackCallback();
    };

    /**
     *
     * @param state {boolean}
     * @return {Promise<void>}
     */
    const onToggleHelp = async (state) => {
      isHelpSectionShown.value = state;

      focusSelf();

      if (state) {
        customEventsService.setOnPressBackCallback(async () => {
          isHelpSectionShown.value = false;
        }, true);
      }
    };

    onMounted(async () => {
      await generateQr();

      focusSelf();
    });

    onBeforeUnmount(() => {
      if (checkTvTokenInterval) {
        window.clearInterval(checkTvTokenInterval);
      }

      if (updateTimeInterval) {
        window.clearInterval(updateTimeInterval);
      }
    });

    return {
      el,
      canvasRef,
      code,
      isHelpSectionShown,
      generateQr,
      timeLeft,
      onChangeLoginMethod,
      onToggleHelp,
      AppButton,
      onQRCodeClick,
      time,
    };
  },
};
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/mixins';
@import '@/styles/colors';

.qr {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  width: 100%;
}

.instruction {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: adjustPx(1080px) - adjustPx(100px);

  @include f-body;

  &Header {
    @include f-title-1;
  }

  &List {
    margin-top: adjustPx(42px);
    @include f-body;
  }

  &ListItem {
    margin-top: adjustPx(16px);

    @include f-body;
  }

  &Code {
    display: flex;
    align-items: flex-end;
    width: adjustPx(289px);
    height: adjustPx(122px);
    color: var(--color-text-accent);

    @include f-title-0;
  }

  &CodeTime {
    margin-top: adjustPx(24px);
    color: var(--color-notheme-white-50);
  }

  &Control {
    display: flex;
    flex-wrap: wrap;
    margin-top: adjustPx(24px);
    width: 100%;
  }

  &Button {
    margin: adjustPx(8px) adjustPx(24px);
  }
}

.code {
  margin: adjustPx(90px);

  &Canvas {
    width: adjustPx(510px) !important;
    height: adjustPx(510px) !important;
    border-radius: var(--g-border-round-16);
  }
}

.active {
  border-color: var(--color-bg-accent);
}
</style>
