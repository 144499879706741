import { ProcessingMapper } from '../base/processing-mapper';
import { getPersonName } from '../content/get-person-name';
import type { ApiStarring } from './content-types/starring';
import type { Starring } from './types/starring';

export class StarringMapper {
  static mapMany(writers: ApiStarring[]): Starring[] {
    return ProcessingMapper.process(writers.map(StarringMapper.map));
  }

  static map({ first_name, last_name, ...rest }: ApiStarring): Starring {
    return ProcessingMapper.process({
      name: getPersonName(first_name, last_name),
      ...rest,
    });
  }
}
