import { render, staticRenderFns } from "./MomentsPage.vue?vue&type=template&id=5d1ef6d8"
import script from "./MomentsPage.vue?vue&type=script&lang=js"
export * from "./MomentsPage.vue?vue&type=script&lang=js"
import style0 from "./MomentsPage.vue?vue&type=style&index=0&id=5d1ef6d8&prod&lang=css"
import style1 from "./MomentsPage.vue?vue&type=style&index=1&id=5d1ef6d8&prod&module=true&lang=scss"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_babel-core@7.0.0-bridge.0_@babel+core@7.25.2__css_vx6ncni44wzuzfygcpf3ynbwqu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports