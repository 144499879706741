<template>
  <NavigatableItem
    focus-key="MOMENTS_DROPDOWN"
    :class="$style.button"
    :active-class="$style.active"
    has-global-access
    :tag="AppButton"
    :on-click="() => $emit('toggle')"
    @active="$emit('active')"
  >
    <template v-slot:icon>
      <Episodes :class="$style.playlist" />
    </template>
  </NavigatableItem>
</template>

<script>
import Episodes from '@SMART/assets/icons/51x51/episodes.svg';

import AppButton from '@/components/app-button/AppButton.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';

export default {
  components: {
    NavigatableItem,
    Episodes,
  },
  props: {
    isOpen: Boolean,
  },
  data: () => ({ AppButton }),
};
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: adjustPx(16px) !important;
  padding: 0;
  width: adjustPx(96px);
  height: adjustPx(96px);
  border-color: transparent;
  border-radius: adjustPx(24px);
  background-color: var(--color-bg-ghost);
  @include f-label-2;

  &:hover {
    background: var(--color-notheme-bg-accent);
    color: var(--color-notheme-text-accent);
  }
}

.active {
  background: var(--color-notheme-bg-accent);
  color: var(--color-notheme-text-accent);
}

.playlist {
  width: adjustPx(48px);
  height: adjustPx(48px);
}
</style>
