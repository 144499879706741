// @ts-nocheck

import type { InternalRef } from '@package/smarttv-base/src/services/global-settings';
import noop from 'lodash/noop';
import uniqueId from 'lodash/uniqueId';

import type { Direction, FocusableComponentLayout, FocusDetails, KeyPressDetails } from './SpatialNavigation';
import { ROOT_FOCUS_KEY, SpatialNavigation } from './SpatialNavigation';

export type useNavigatable = typeof useNavigatable;

export type EnterPressHandler<P = object> = (props: P, details: KeyPressDetails) => void;

export type EnterReleaseHandler<P = object> = (props: P) => void;

export type ArrowPressHandler<P = object> = (direction: string, props: P, details: KeyPressDetails) => boolean;

export type FocusHandler<P = object> = (layout: FocusableComponentLayout, props: P, details: FocusDetails) => void;

export type BlurHandler<P = object> = (layout: FocusableComponentLayout, props: P, details: FocusDetails) => void;

export type LongPressHandler<P = object> = (layout: FocusableComponentLayout, props: P, details: FocusDetails) => void;

export interface UseFocusableConfig<P = object> {
  focusable?: boolean;
  /**
   * @type {boolean}
   *
   * @description
   *  Флаг, который говорит о том, что это элемент всегда учавствует в навигации (независимо от местоположения)
   */
  ref?: InternalRef<HTMLElement | undefined>;
  hasGlobalAccess?: boolean;
  saveLastFocusedChild?: boolean;
  parentFocusKey?: string;
  trackChildren?: boolean;
  autoRestoreFocus?: boolean;
  forceFocus?: boolean;
  isFocusBoundary?: boolean;
  focusBoundaryDirections?: Direction[];
  focusKey?: string;
  updateLayoutOnFocus?: boolean;
  preferredChildFocusKey?: string | null;
  onEnterPress?: EnterPressHandler<P>;
  onEnterRelease?: EnterReleaseHandler<P>;
  onArrowPress?: ArrowPressHandler<P>;
  onFocus?: FocusHandler<P>;
  onBlur?: BlurHandler<P>;
  onLongPress?: LongPressHandler<P>;
  extraProps?: P;
}

export interface UseFocusableResult {
  el: InternalRef<HTMLElement>; // <any> since we don't know which HTML tag is passed here
  focusSelf: (focusDetails?: FocusDetails) => void;
  focused: InternalRef<boolean>;
  hasFocusedChild: InternalRef<boolean>;
  focusKey: InternalRef<boolean>;
  addFocusable: VoidFunction;
  updateFocusable: VoidFunction;
  removeFocusable: VoidFunction;
}

const useNavigatable = <P>({
  focusable = true,
  ref = null,
  saveLastFocusedChild = true,
  trackChildren = false,
  parentFocusKey: propParentFocusKey,
  autoRestoreFocus = true,
  forceFocus = false,
  hasGlobalAccess,
  updateLayoutOnFocus = true,
  isFocusBoundary = false,
  focusBoundaryDirections,
  focusKey: propFocusKey,
  preferredChildFocusKey,
  onEnterPress = noop,
  onEnterRelease = noop,
  onArrowPress = () => true,
  onFocus = noop,
  onBlur = noop,
  onLongPress = noop,
  extraProps,
}: UseFocusableConfig<P> = {}): UseFocusableResult => {
  const vueApi = (() => {
    return window.globalApp;
  })();

  const el = ref || vueApi.ref<HTMLElement>();

  const onEnterPressHandler = (details: KeyPressDetails) => {
    onEnterPress(extraProps, details);
  };

  const onEnterReleaseHandler = () => {
    onEnterRelease(extraProps);
  };

  const onArrowPressHandler = (direction: string, details: KeyPressDetails) => {
    onArrowPress(direction, extraProps, details);
  };

  const onFocusHandler = (layout: FocusableComponentLayout, details: FocusDetails) => {
    onFocus(layout, extraProps, details);
  };

  const onBlurHandler = (layout: FocusableComponentLayout, details: FocusDetails) => {
    onBlur(layout, extraProps, details);
  };

  const hasLongPressHandler = onLongPress !== noop;
  const onLongPressHandler = (layout: FocusableComponentLayout, details: FocusDetails) => {
    onLongPress(layout, extraProps, details);
  };

  const focused = vueApi.ref(false);
  const hasFocusedChild = vueApi.ref(false);

  const normalizedParentFocusKey = propParentFocusKey || vueApi.inject('parentFocusKey', ROOT_FOCUS_KEY);

  /**
   * Either using the propFocusKey passed in, or generating a random one
   */
  const focusKey = vueApi.computed(() => propFocusKey || uniqueId('sn:focusable-item-'));

  const focusSelf = (focusDetails: FocusDetails = {}) => {
    SpatialNavigation.setFocus(focusKey.value, focusDetails);
  };

  const addFocusable = () => {
    const node = el.value instanceof HTMLElement ? el.value : el.value?.$el;

    if (!node) {
      return;
    }

    node.addEventListener('click', onEnterPress);
    node.setAttribute('data-focus-key', focusKey.value);
    node.setAttribute('data-parent-focus-key', normalizedParentFocusKey);

    SpatialNavigation.addFocusable({
      focusKey: focusKey.value,
      node,
      parentFocusKey: normalizedParentFocusKey,
      preferredChildFocusKey,
      hasGlobalAccess,
      onEnterPress: onEnterPressHandler,
      onEnterRelease: onEnterReleaseHandler,
      onArrowPress: onArrowPressHandler,
      onFocus: onFocusHandler,
      onBlur: onBlurHandler,
      onLongPress: onLongPressHandler,
      hasLongPressHandler,
      onUpdateFocus: (isFocused = false) => {
        focused.value = isFocused;
      },
      onUpdateHasFocusedChild: (isFocused = false) => {
        hasFocusedChild.value = isFocused;
      },
      updateLayoutOnFocus,
      saveLastFocusedChild,
      trackChildren,
      isFocusBoundary,
      focusBoundaryDirections,
      autoRestoreFocus,
      forceFocus,
      focusable,
      extraProps,
    });
  };

  const updateFocusable = () => {
    const node = el.value instanceof HTMLElement ? el.value : el.value?.$el;

    if (!node) {
      return;
    }

    SpatialNavigation.updateFocusable(focusKey.value, {
      node,
      preferredChildFocusKey,
      focusable,
      isFocusBoundary,
      focusBoundaryDirections,
      onEnterPress: onEnterPressHandler,
      onEnterRelease: onEnterReleaseHandler,
      onArrowPress: onArrowPressHandler,
      onFocus: onFocusHandler,
      onBlur: onBlurHandler,
      onLongPress: onLongPressHandler,
      hasLongPressHandler,
    });
  };

  const removeFocusable = () => {
    SpatialNavigation.removeFocusable({ focusKey: focusKey.value });
  };

  const removeListeners = () => {
    const node = el.value instanceof HTMLElement ? el.value : el.value?.$el;

    if (node) {
      node.removeEventListener('click', onEnterPress);
    }
  };

  vueApi.onActivated(() => {
    addFocusable();
  });

  vueApi.onMounted(() => {
    addFocusable();
    updateFocusable();
  });

  vueApi.onDeactivated(() => {
    removeFocusable();
    removeListeners();
  });

  vueApi.onBeforeUnmount(removeListeners);
  vueApi.onUnmounted(removeFocusable);

  return {
    el,
    focusSelf,
    focused,
    hasFocusedChild,
    focusKey,
    addFocusable,
    updateFocusable,
    removeFocusable,
  };
};

export default useNavigatable;
