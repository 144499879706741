<template>
  <nav ref="el" :class="{ [$style.menu]: true, [$style.active]: hasFocusedChild }">
    <ul :class="{ [$style.list]: true, [$style.active]: hasFocusedChild }" role="list">
      <li v-if="isBackMenuShown && false">
        <NavigatableItem
          :class="$style.item"
          :active-class="$style.active"
          :tag="AppSlotButton"
          data-testid="nav-0"
          :on-click="onRouterBack"
        >
          <span :class="[$style.icon, $style.itemIconBack]">
            <ArrowUpIcon />
          </span>

          <span :class="$style.text">{{ $t('appMenu.back') }}</span>
        </NavigatableItem>
      </li>
      <li v-if="user && profile && isActiveSubscription" :class="$style.profile">
        <NavigatableItem
          :class="[$style.item, $style.profile]"
          :active-class="$style.active"
          :tag="AppSlotButton"
          data-testid="nav-1"
          :on-click="onClickProfileSection"
        >
          <span v-if="profile.kind !== ProfileType.CHILD" :class="[$style.icon, $style.iconProfile]">
            {{ profile.name[0] }}
          </span>
          <span v-else :class="[$style.icon, $style.iconChild]">
            <ChildIcon />
          </span>

          <span :class="$style.text">{{ profile.name }}</span>
        </NavigatableItem>
      </li>
      <li>
        <NavigatableItem
          :class="{ [$style.item]: true, [$style.located]: $route.name === RouterPage.MainPage }"
          :active-class="$style.active"
          :tag="AppSlotButton"
          data-testid="nav-2"
          :on-click="() => onClickMenuSection(RouterPage.MainPage)"
          @focus="setMenuState(true)"
          @blur="setMenuState(false)"
        >
          <span :class="$style.icon">
            <IconMain />
          </span>

          <span :class="$style.text">{{ $t('appMenu.main') }}</span>
        </NavigatableItem>
      </li>
      <li>
        <NavigatableItem
          :class="{ [$style.item]: true, [$style.located]: $route.name === RouterPage.SearchPage }"
          :active-class="$style.active"
          :tag="AppSlotButton"
          data-testid="nav-3"
          :on-click="() => onClickMenuSection(RouterPage.SearchPage)"
          @focus="setMenuState(true)"
          @blur="setMenuState(false)"
        >
          <span :class="$style.icon">
            <IconSearch />
          </span>

          <span :class="$style.text">{{ $t('appMenu.search') }}</span>
        </NavigatableItem>
      </li>
      <li>
        <NavigatableItem
          :class="{ [$style.item]: true, [$style.located]: $route.name === RouterPage.CatalogPage }"
          :active-class="$style.active"
          :tag="AppSlotButton"
          data-testid="nav-4"
          :on-click="() => onClickMenuSection(RouterPage.CatalogPage)"
          @focus="setMenuState(true)"
          @blur="setMenuState(false)"
        >
          <span :class="$style.icon">
            <IconCatalog />
          </span>

          <span :class="$style.text">{{ $t('appMenu.catalog') }}</span>
        </NavigatableItem>
      </li>
      <li>
        <NavigatableItem
          :class="{ [$style.item]: true, [$style.located]: $route.name === RouterPage.CollectionPage }"
          :active-class="$style.active"
          :tag="AppSlotButton"
          data-testid="nav-5"
          :on-click="() => onClickMenuSection($RouterPage.CollectionPage)"
          @focus="setMenuState(true)"
          @blur="setMenuState(false)"
        >
          <span :class="$style.icon">
            <IconSave />
          </span>

          <span :class="$style.text">{{ $t('appMenu.collection') }}</span>
        </NavigatableItem>
      </li>
      <li>
        <NavigatableItem
          :class="{ [$style.item]: true, [$style.located]: $route.name === RouterPage.ChannelsPage }"
          :active-class="$style.active"
          :tag="AppSlotButton"
          data-testid="nav-6"
          :on-click="() => onClickMenuSection($RouterPage.ChannelsPage)"
          @focus="setMenuState(true)"
          @blur="setMenuState(false)"
        >
          <span :class="$style.icon">
            <IconTv />
          </span>

          <span :class="$style.text">{{ $t('appMenu.tv') }}</span>
        </NavigatableItem>
      </li>
      <li>
        <NavigatableItem
          :class="{ [$style.item]: true, [$style.located]: $route.name === RouterPage.MyChannelPage }"
          :active-class="$style.active"
          :tag="AppSlotButton"
          data-testid="nav-7"
          :on-click="() => onClickMenuSection($RouterPage.MyChannelPage)"
          @focus="setMenuState(true)"
          @blur="setMenuState(false)"
        >
          <span :class="$style.icon">
            <IconKinom />
          </span>

          <span :class="$style.text">{{ $t('appMenu.myChannel') }}</span>
        </NavigatableItem>
      </li>
      <li>
        <NavigatableItem
          :class="{ [$style.item]: true, [$style.active]: $route.name === RouterPage.AuthPage }"
          :active-class="$style.active"
          :tag="AppSlotButton"
          data-testid="nav-8"
          :on-click="() => onClickSettingsSection"
          @focus="setMenuState(true)"
          @blur="setMenuState(false)"
        >
          <span :class="$style.icon">
            <IconSettings />
          </span>

          <span :class="$style.text">{{ $t('appMenu.settings') }}</span>
        </NavigatableItem>
      </li>
      <li v-if="!isRelease">
        <NavigatableItem
          :class="$style.item"
          :active-class="$style.active"
          :tag="AppSlotButton"
          data-testid="nav-9"
          :on-click="() => onClickMenuSection(RouterPage.LogInfo)"
          @focus="setMenuState(true)"
          @blur="setMenuState(false)"
        >
          <span :class="$style.icon">
            <InfoIcon />
          </span>

          <span :class="$style.text">{{ $t('appMenu.log') }}</span>
        </NavigatableItem>
      </li>
    </ul>
  </nav>
</template>

<script>
import { ProfileType } from '@package/sdk/src/api';
import { TvKeyCode } from '@package/sdk/src/core';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import InfoIcon from '@SMART/assets/icons/33x33/info.svg';
import IconCatalog from '@SMART/assets/icons/40x40/catalog.svg';
import IconKinom from '@SMART/assets/icons/40x40/kinom.svg';
import IconMain from '@SMART/assets/icons/40x40/main.svg';
import IconSave from '@SMART/assets/icons/40x40/save.svg';
import IconSearch from '@SMART/assets/icons/40x40/search.svg';
import IconSettings from '@SMART/assets/icons/40x40/settings.svg';
import IconTv from '@SMART/assets/icons/40x40/tv.svg';
import ArrowUpIcon from '@SMART/assets/icons/51x51/arrow-up.svg';
import ChildIcon from '@SMART/assets/icons/51x51/child.svg';
import {
  AppKeyboardEvent,
  environmentService,
  RouterPage,
  routerService,
  storeToRefs,
  useAuthActions,
  useAuthStore,
  useMouseDetect,
  useSessionStore,
} from '@SMART/index';
import { computed, provide, ref } from '@vue/composition-api';

import AppSlotButton from '@/components/app-slot-button/AppSlotButton.vue';

export default {
  components: {
    InfoIcon,
    IconCatalog,
    IconKinom,
    IconMain,
    IconSave,
    IconSearch,
    IconSettings,
    IconTv,
    ArrowUpIcon,
    ChildIcon,
    AppSlotButton,
  },
  setup(props, { root: { $route: route } }) {
    const sessionStore = useSessionStore();
    const authStore = useAuthStore();

    const { isAuth, profile, user, isActiveSubscription } = storeToRefs(sessionStore);
    const { isMouseDetected } = useMouseDetect();

    const { openAuthPage } = useAuthActions();

    const isRelease = environmentService.getVariable('isRelease');
    const isMenuActive = ref(false);

    const { el, hasFocusedChild, focusKey } = useNavigatable({
      focusKey: 'APP_MENU',
      trackChildren: true,
      updateLayoutOnFocus: false,
      focusable: true,
      autoRestoreFocus: false,
      saveLastFocusedChild: false,
      preferredChildFocusKey: null,
      isFocusBoundary: false,
    });
    provide('parentFocusKey', focusKey.value);
    provide('updateLayoutOnFocus', true);

    const setMenuState = (isOpen) => {
      isMenuActive.value = isOpen;
    };

    const onRouterBack = (event) => routerService.onBackspace(new AppKeyboardEvent(event));

    const onClickMenuSection = (name) => {
      isMenuActive.value = false;
      return routerService.push({ name });
    };

    const onClickProfileSection = () => {
      if (
        ![
          RouterPage.ProfilesPage,
          RouterPage.ParentalPage,
          RouterPage.ParentalCodePage,
          RouterPage.ParentalCodeRecoverPage,
        ].includes(route.name)
      ) {
        authStore.setBackUrl(route.fullPath);
      }

      return routerService.push({ name: RouterPage.ProfilesPage });
    };

    const onClickSettingsSection = () => {
      if (!isAuth.value) {
        return openAuthPage();
      }

      return routerService.push({ name: RouterPage.SettingsPage });
    };

    const isBackMenuShown = computed(() => !isRelease && isMouseDetected.value);

    return {
      hasFocusedChild,
      el,
      TvKeyCode,
      isMenuActive,
      isBackMenuShown,
      onRouterBack,
      setMenuState,
      onClickMenuSection,
      onClickSettingsSection,
      onClickProfileSection,
      profile,
      user,
      isRelease,
      isAuth,
      RouterPage,
      AppSlotButton,
      ProfileType,
      isActiveSubscription,
    };
  },
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/fonts' as webFonts;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;
@use '@package/ui/src/styles/adjust-smart-px' as adjust;

@import '@/styles/mixins';
@import '@/styles/fonts';
@import '@/styles/colors';
@import '@/styles/layers';

.menu {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: map-get($map: $layers, $key: --z-index-nav);
  display: flex;
  flex-direction: column;
  align-items: center;

  &.active {
    background: var(--color-bg-primary);

    .item.profile:after {
      visibility: visible;
    }
  }
}

.list {
  display: flex;
  flex-direction: column;
  margin: adjustPx(54px) adjustPx(18px) 0;
  width: adjustPx(80px);
  overflow: hidden;

  li {
    margin-bottom: adjustPx(23px);

    &.profile {
      margin-bottom: adjustPx(20px);
    }
  }

  &.active {
    min-width: adjustPx(337px);
  }
}

.item {
  display: flex;
  align-items: center;
  min-height: adjustPx(80px);
  border-radius: adjustPx(24px);
  outline: none;
  text-decoration: none;
  color: var(--color-text-primary);
  white-space: nowrap;

  &.located {
    background-color: var(--color-stroke-field-border);
  }

  &.active {
    background-color: var(--color-notheme-bg-accent);
    color: var(--color-notheme-text-accent);
  }

  &.profile {
    margin-bottom: adjustPx(15px);
    background-color: unset;

    &:after {
      position: absolute;
      top: adjustPx(95px);
      height: adjustPx(2px);
      background-color: var(--color-stroke-field-border);
      visibility: hidden;
      content: '';
    }
  }

  &:hover,
  &:focus {
    background-color: var(--color-bg-accent);
    text-decoration: none;
    color: var(--color-notheme-text-accent);
  }

  svg {
    width: adjustPx(40px);
    height: adjustPx(40px);
  }
}

.itemIconBack {
  transform: rotate(-90deg);
}

.icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: adjustPx(81px);

  &Profile {
    background-color: var(--color-stroke-field-border);
  }

  &Child {
    background: var(--color-non-standart-smart-tv-profile-icon);
  }

  &Profile,
  &Child {
    margin-left: adjustPx(7px);
    min-width: adjustPx(67px);
    min-height: adjustPx(67px);
    border-radius: 50%;
  }
}

.text {
  flex: 1;
  margin-left: adjustPx(16px);

  @include f-body-2;
}
</style>
