<template>
  <section role="textbox" :class="$style.textbox">
    <input
      v-for="index in fields"
      :key="index"
      :class="{
        [$style.input]: true,
        [$style.inputDisabled]: disabled,
        [$style.bordered]: variant === 'bordered',
      }"
      :disabled="disabled"
      :type="type"
      :value="text && text[index - 1]"
      :max-length="1"
    />
  </section>
</template>

<script>
export default {
  props: {
    fields: { default: 4 },
    variant: { default: 'borderless' },
    type: { default: 'text' },
    disabled: { default: false },
    input: { default: '' },
  },
  computed: {
    text: {
      get() {
        return this.input;
      },
      set(value) {
        this.$emit('update:input', value);
      },
    },
  },
};
</script>

<style module lang="scss">
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '@/styles/colors';

.textbox {
  display: flex;
  margin-top: adjustPx(100px);
}

.input {
  margin-left: adjustPx(24px);
  width: adjustPx(75px);
  min-height: adjustPx(83px);
  border: 0 solid;
  border-width: 0 0 adjustPx(4px) 0;
  border-color: var(--color-states-bg-secondary-disabled);
  outline: none;
  background-color: transparent;
  color: var(--color-text-primary);
  text-align: center;

  @include f-headline-2;

  &.bordered {
    width: adjustPx(115px);
    min-height: adjustPx(115px);
    border-width: adjustPx(4px);
    border-color: var(--color-bg-primary);
    border-radius: 27.385px;
    background: var(--color-bg-primary);
  }
}

.input:active,
.input:hover,
.input:focus {
  border-color: var(--color-bg-accent);
}
</style>
