<template>
  <UIButton
    :variation="variation"
    :disabled="disabled"
    role="tab"
    :class="{
      [$style.tab]: true,
      [$style.disabled]: true,
      [$style.disabledSelected]: selected,
      [$style.tabSecondary]: variant === 'secondary',
      [$style.tabSecondarySelected]: variant === 'secondary' && selected,
    }"
  >
    <template v-slot:icon>
      <slot name="icon" />
    </template>
    <slot />
  </UIButton>
</template>

<script>
import { computed } from '@vue/composition-api';

import UIButton from '../button/UIButton.vue';

export default {
  components: { UIButton },
  props: {
    selected: { default: false },
    isActive: { default: false },
    disabled: { default: false },
    variant: { type: String, default: 'primary' },
  },
  setup(props, { emit }) {
    const variation = computed(() => {
      if (props.isActive) {
        return 'primary';
      }

      if (props.variant === 'primary') {
        return props.selected ? 'primary' : 'secondary';
      }

      return 'primary';
    });
    return { variation };
  },
};
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/mixins';
@import '@/styles/colors';

.tab {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 adjustPx(25px);
  width: max-content;
  height: adjustPx(59px);
  min-height: adjustPx(59px);
  border: none;
  border-radius: adjustPx(23px);
  color: var(--color-text-primary);
  cursor: pointer;

  @include f-subtitle-2;

  &:hover,
  &:active {
    background-color: var(--color-bg-accent);
  }

  &Secondary {
    height: adjustPx(80px);
    min-height: adjustPx(80px);
    border-radius: adjustPx(16px);
  }

  &Secondary:hover,
  &Secondary:active {
    background-color: var(--color-bg-ghost);
    color: var(--color-text-primary);
  }

  &SecondarySelected {
    background-color: var(--color-bg-ghost);
    color: var(--color-bg-accent);
  }
}

.disabled {
  &:disabled {
    background-color: transparent;
    opacity: 1;
    color: var(--color-text-primary);
    cursor: not-allowed;
  }
}

.disabledSelected {
  &:disabled {
    background-color: var(--color-bg-ghost);
    opacity: 1;
    color: var(--color-text-primary);
    cursor: not-allowed;
  }
}
</style>
