import type { CollectionContentType, Media, Moment } from '@package/sdk/src/api';
import { MediaContentType } from '@package/sdk/src/api';
import { contentCacheManager } from '@SMART/index';
import { computed, ref } from '@vue/composition-api';

import { type LoadFuncProps } from '../virtual-scroll/useVirtualScroller';

interface Props {
  onSelect: (content: Media | Moment, type: CollectionContentType | MediaContentType) => void;
  isContentChanging: () => boolean;
  fetchItems: (
    contentType: MediaContentType | CollectionContentType,
    filter: { page: number; size: number },
  ) => Promise<Media[] | Moment[]>;
  onLoaded: () => void;
  type?: MediaContentType | CollectionContentType;
}

/**
 * Helper functions and variables for using UIContent
 * - update cache
 * - update loader state
 * - reset page number for pagination
 * - load chunks logic with pagination
 * @param param0
 * @returns
 */
export const useUiContent = ({ onSelect, isContentChanging, fetchItems, onLoaded, type }: Props) => {
  const contentType = ref<MediaContentType | CollectionContentType>(type || MediaContentType.MOVIE);
  const isLoading = ref(true);

  const itemsPerRow = computed(() => 3);

  const itemsPerScroll = computed(() => 3);

  const firstLoadSize = computed(() => 27);

  const onSelectItem = (content: Media | Moment) => {
    contentCacheManager.addToCache(content);

    onSelect(content, contentType.value);
  };

  const onLoadCollectionChunk = async (props: LoadFuncProps) => {
    try {
      const {
        boundaries: { firstId, lastId },
        direction,
        size: loadSize,
        page: chunkPage,
      } = props;

      let page: number, size: number;

      page = chunkPage ?? (direction > 0 ? lastId + 1 : firstId - 1);
      size = itemsPerRow.value * itemsPerScroll.value;

      if (isContentChanging()) {
        page = chunkPage ?? 1;
        size = loadSize ?? firstLoadSize.value;
      }

      isLoading.value = true;
      const data = await fetchItems(contentType.value, {
        page,
        size,
      });

      return { data, lineIndex: (page - 1) * itemsPerScroll.value };
    } finally {
      isLoading.value = false;

      onLoaded();
    }
  };

  return {
    contentType,
    itemsPerRow,
    itemsPerScroll,
    firstLoadSize,
    isLoading,
    onSelectItem,
    onLoadCollectionChunk,
  };
};
