<template>
  <NavigatableItem
    :class="$style.link"
    :active-class="$style.active"
    :focus-key="focusKey"
    :tag="AppSlotButton"
    :on-click="() => openTvChannelPage(channel)"
    :on-focus="onFocus"
    @active="(element) => $emit('active', element)"
  >
    <div :class="$style.tv">
      <app-image
        :src="(channel.currentProgram && channel.currentProgram.background) || channel.background"
        :class="$style.preview"
        :width="600"
      />

      <div :class="$style.header"></div>
      <div :class="$style.progress"></div>
      <div
        :class="$style.done"
        :style="{
          width: channelProgress,
        }"
      ></div>
      <div :class="$style.footer">
        <span :class="$style.time">
          {{ startTime }}
        </span>
        <span :class="$style.text">{{ channel.currentProgram && channel.currentProgram.title }}</span>
      </div>

      <div
        :class="{
          [$style.badge]: true,
          [$style.badgeNow]: isForAllUsersChannel,
          [$style.badgeSubs]: isSubscriptionChannel,
          [$style.badgeRegistered]: isAfterRegistryChannel,
          [$style.badgeHide]: shouldHideBanner,
        }"
      >
        <span>{{ getTvChannelStickerText(channel.accessKind) }}</span>
      </div>

      <app-image v-show="channelLogo" :src="channelLogo" :class="$style.logo" :alt="channel.title" :width="420" />
      <span v-show="!channelLogo" :class="$style.title">
        {{ channel.title }}
      </span>
    </div>
  </NavigatableItem>
</template>

<script>
import { storeToRefs, useSessionStore, useTvChannelActions } from '@SMART/index';
import { computed } from '@vue/composition-api';
import { differenceInSeconds, format, isValid, parseISO } from 'date-fns';

import AppImage from '@/components/app-image/AppImage.vue';
import AppSlotButton from '@/components/app-slot-button/AppSlotButton.vue';

export default {
  components: {
    AppImage,
  },
  props: {
    /**
     * @type {Channel}
     */
    focusKey: { type: String },
    channel: { default: () => ({}) },
    onFocus: { type: Function },
  },
  setup(props) {
    const { user } = storeToRefs(useSessionStore());
    const { getTvChannelStickerText, openTvChannelPage } = useTvChannelActions();

    const channelProgress = computed(() => {
      const time = parseISO(props.channel.currentProgram?.startTime);
      const duration = props.channel.currentProgram?.duration;

      if (!duration || !isValid(time)) {
        return 0;
      }

      return `${(differenceInSeconds(new Date(), time) / duration) * 100}%`;
    });

    const startTime = computed(() => {
      const time = parseISO(props.channel.currentProgram?.startTime);

      if (isValid(time)) {
        return format(time, 'HH:mm');
      }

      return '';
    });

    const channelLogo = computed(() => props.channel.logoWhite || props.channel.logoBlack || props.channel.logo);

    const isForAllUsersChannel = computed(() => {
      if (!user.value?.id && props.channel?.accessKind === 'all_users') {
        return true;
      }

      if (user.value?.id && !user.value?.subscription?.active && props.channel?.accessKind !== 'subscription') {
        return true;
      }

      return false;
    });

    const isSubscriptionChannel = computed(() => {
      if (user.value?.id && !user.value?.subscription?.active && props.channel?.accessKind === 'subscription') {
        return true;
      }

      return false;
    });

    const isAfterRegistryChannel = computed(() => {
      if (!user.value?.id && props.channel?.accessKind === 'register') {
        return true;
      }

      return false;
    });

    const shouldHideBanner = computed(
      () => !isForAllUsersChannel.value && !isAfterRegistryChannel.value && !isSubscriptionChannel.value,
    );
    return {
      channelProgress,
      startTime,
      channelLogo,
      isForAllUsersChannel,
      isSubscriptionChannel,
      isAfterRegistryChannel,
      shouldHideBanner,
      getTvChannelStickerText,
      openTvChannelPage,
      AppSlotButton,
    };
  },
};
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/colors';

.link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: adjustPx(36px);
    content: '';
  }
}

.preview {
  max-height: calc(100% - adjustPx(70px));
}

.tv {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .header {
    position: absolute;
    bottom: adjustPx(70px);
    width: 100%;
    height: adjustPx(108px);
  }

  .progress {
    position: absolute;
    bottom: adjustPx(70px);
    width: 100%;
    height: adjustPx(1px);
    border: adjustPx(4px) solid;
    border-color: var(--color-bg-ghost);
    background-color: var(--color-bg-ghost);
    color: var(--color-bg-ghost);
  }

  .done {
    position: absolute;
    bottom: adjustPx(70px);
    width: 50px;
    height: adjustPx(1px);
    border: adjustPx(4px) solid;
    border-color: var(--color-bg-accent);
    color: var(--color-bg-accent);
  }

  .footer {
    position: absolute;
    bottom: 0;
    padding: adjustPx(16px) adjustPx(24px);
    width: 100%;
    height: adjustPx(70px);
    background: var(--color-bg-primary);
    overflow: hidden;
    color: var(--color-text-primary);
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .badge {
    position: absolute;
    top: adjustPx(12px);
    right: adjustPx(12px);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: adjustPx(5px) adjustPx(16px);
    border-radius: adjustPx(30px);
    color: var(--color-text-primary);

    @include f-caption;

    &Now {
      background: rgba(41, 56, 60, 0.5);
    }

    &Subs {
      background: rgba(41, 56, 60, 0.5);
    }

    &Registered {
      right: adjustPx(10px);
      border-radius: adjustPx(27px);
      background: var(--color-stroke-accent-action);

      @include f-caption-2;
    }

    &Hide {
      display: none;
    }
  }

  .logo {
    position: absolute;
    bottom: adjustPx(85px);
    left: adjustPx(24px);
    width: adjustPx(202px) !important;
    height: adjustPx(68px) !important;
    object-fit: contain;
  }

  .title {
    position: absolute;
    bottom: adjustPx(85px);
    left: adjustPx(24px);
    color: var(--color-text-primary);

    @include f-caption;
  }

  .time {
    color: var(--color-bg-ghost);
    white-space: nowrap;
    text-overflow: ellipsis;

    @include f-caption;
  }

  .text {
    margin-left: adjustPx(12px);
    color: var(--color-text-primary);
    white-space: nowrap;
    text-overflow: ellipsis;

    @include f-caption;
  }
}
</style>
