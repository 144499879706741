<template>
  <NavigatableItem
    :active-class="$style.active"
    :class="{
      [$style.item]: true,
      [$style.selected]: isSelected,
    }"
    :tag="AppSlotButton"
    :on-click="() => $emit('selected')"
    @active="$emit('active')"
  >
    <template>
      <div :class="$style.title">
        <div :class="$style.titleText">
          <slot name="icon"></slot>
          <span><slot name="text"></slot></span>
        </div>
        <CheckIcon v-if="isSelected" :class="$style.titleState" />
      </div>
    </template>
  </NavigatableItem>
</template>

<script>
import CheckIcon from '@SMART/assets/icons/51x51/check.svg';

import AppSlotButton from '@/components/app-slot-button/AppSlotButton.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';

export default {
  components: {
    NavigatableItem,
    CheckIcon,
  },
  props: {
    isSelected: Boolean,
  },
  data: () => ({ AppSlotButton }),
};
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: adjustPx(24px) !important;
  padding: adjustPx(24px);
  width: adjustPx(604px);
  height: adjustPx(96px);
  border: none !important;
  border-radius: adjustPx(24px);
  background: var(--color-notheme-bg-secondary-80);
  @include f-subtitle;

  &:hover {
    background: var(--color-notheme-bg-accent);
    color: var(--color-notheme-text-accent);

    img {
      filter: brightness(0%);
    }
  }
}

.selected {
  border-radius: adjustPx(32px);
  background: var(--color-notheme-bg-hover-70);
}
.active {
  background: var(--color-notheme-bg-accent);
  color: var(--color-notheme-text-accent);

  img {
    filter: brightness(0%);
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &Text {
    display: flex;
    align-items: center;
  }

  &State {
    margin-right: adjustPx(16px);
    width: adjustPx(48px);
    height: adjustPx(48px);
  }
}
</style>
