import type { LoggerReporter, LogObject } from './logger/logger';
import { Logger } from './logger/logger';

const isClient = typeof window !== 'undefined';
const MAX_MESSAGES_QUOTA_LOGGER = 1000;

type ProjectName = 'dsml-js' | 'media-player' | 'web' | 'smarttv' | 'media-player-analyzer';

interface LoggerEventMap {
  log: LogObject;
}

export type Listener = (...args: any[]) => void;

export class Emitter<TEventMap> {
  private readonly events: Record<any, Listener[]> = {};

  constructor() {}

  public messages: LogObject[] = [];

  public on<T extends keyof TEventMap>(event: T, listener: (arg: TEventMap[T]) => void) {
    if (!this.events[event]) {
      this.events[event] = [];
    }

    this.events[event].unshift(listener);

    return () => this.removeEventListener<T>(event, listener);
  }

  public removeEventListener<T extends keyof TEventMap>(event: T, listener: Listener): void {
    if (!this.events[event]) {
      return;
    }

    const idx: number = this.events[event].indexOf(listener);
    if (idx > -1) {
      this.events[event].splice(idx, 1);
    }
  }

  public emit<T extends keyof TEventMap>(event: T, arg?: TEventMap[T]): void {
    if (typeof this.events[event] !== 'object') {
      return;
    }

    this.events[event].forEach((listener) => listener.apply(this, [arg]));
  }
}

const logger = new Logger();

export const LoggerEmitter = new Emitter<LoggerEventMap>();

const reporter: LoggerReporter = {
  log: (obj) => {
    if (LoggerEmitter.messages.length >= MAX_MESSAGES_QUOTA_LOGGER) {
      LoggerEmitter.messages.splice(0, 1);
    }

    LoggerEmitter.emit('log', obj);
    LoggerEmitter.messages.unshift(obj);
  },
};

if (isClient) {
  logger.addReporter(reporter);
}

if (!isClient) {
  logger.level = 6;
}

export default function useLogger(scope?: string, projectName?: ProjectName) {
  if (scope && projectName) {
    return logger.withTag(`${projectName}:${scope}`);
  }

  if (!scope && projectName) {
    return logger.withTag(projectName);
  }

  if (scope) {
    return logger.withTag(scope);
  }

  return logger;
}
