import type { QualityLevel } from '../modules/quality';
import VideoPlayerEvent from './video-player-event';

export interface MediaSourceTechEventManifestParsed {
  qualityLevels: QualityLevel[];
}

export type MediaSourceEventErrorType = 'buffer-error' | 'manifest-network-error' | 'manifest-parsing-error' | '';

export interface MediaSourceTechEventError {
  errorType: MediaSourceEventErrorType;
  fatal: boolean;
}

export interface MediaSourceTechEventFragmentChanged {
  currentTime: number;
  startFragmentTime: number;
  levelTotalDuration: number;
}

export interface MediaSourceTechEventFragmentLoaded {
  levelTotalDuration: number;
  startFragmentTime: number;
  endFragmentTime: number;
}

export interface MediaSourceTechAbrLevelQualityChanged {
  oldLevelHeight: number;
  newLevelHeight: number;
}

export interface MediaSourceTechEventQualityLevelSwitched {
  level: number;
}

export type MediaSourceTechEventType = 'hls.js' | 'dash.js' | 'html5' | 'avplay' | 'tataudio' | 'shaka';

interface MediaSourceTechEvenConstructorOptions<T> {
  tech: MediaSourceTechEventType;
  originalEvent: any;
  data: T;
}

export class MediaSourceTechEvent<T> extends VideoPlayerEvent<T> {
  public readonly tech: MediaSourceTechEventType;
  public readonly originalEvent: any;

  constructor(options: MediaSourceTechEvenConstructorOptions<T>) {
    super(options.data);

    this.tech = options.tech;
    this.originalEvent = options.originalEvent;
  }
}
