<template>
  <div ref="timeline" :class="$style.timeline">
    <div ref="el" :class="$style.progress" :style="progressStyle">
      <NavigatableItem
        :class="$style.handle"
        :active-class="$style.handleActive"
        :style="handleStyle"
        :tag="AppSlotButton"
        :focus-boundary-directions="['left', 'right']"
        :is-focus-boundary="true"
      >
        <div ref="time" :class="$style.currentPlayTime" :style="getCurrentPlayTimeStyle(currentPlayTime)">
          {{ currentPlayTime }}
        </div>
      </NavigatableItem>
    </div>

    <div v-if="!isDvr" :class="$style.remainPlayTime">
      {{ remainPlayTime }}
    </div>
  </div>
</template>

<script>
import { DisposableStore, TvKeyCode } from '@package/sdk/src/core';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { keyboardEventHandler } from '@SMART/index';
import { onMounted, provide } from '@vue/composition-api';

import AppSlotButton from '@/components/app-slot-button/AppSlotButton.vue';

const speedMap = {
  0: 1,
  1: 5,
  2: 15,
  3: 30,
  4: 60,
  5: 90,
  6: 120,
};

export default {
  props: {
    currentTime: {
      type: Number,
      default: 0,
    },
    displayedCurrentTime: {
      type: Number,
      default: 0,
    },
    duration: {
      type: Number,
      default: 0,
    },
    bufferLength: {
      type: Number,
      default: 0,
    },
    isDvr: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const disposableStore = new DisposableStore();

    const { el, focusSelf, focusKey } = useNavigatable({
      focusKey: 'VIDEO_TIMELINE',
      isFocusBoundary: true,
      forceFocus: true,
      focusBoundaryDirections: ['left', 'right'],
    });
    provide('parentFocusKey', focusKey.value);

    onMounted(() => focusSelf());

    return {
      el,
      AppSlotButton,
      disposableStore,
    };
  },
  data() {
    return {
      timeOffset: 0,
      currentSpeed: 1,
      timeoutId: null,
      startDragging: null,
    };
  },
  computed: {
    currentPlayTime: function () {
      return this.formatTime(Math.round(this.displayedCurrentTime + this.timeOffset));
    },
    remainPlayTime: function () {
      return this.formatTime(Math.round(this.duration - this.currentTime - this.timeOffset));
    },
    handleStyle: function () {
      if (this.currentTime > this.duration) {
        return 'left: 100%';
      }

      if (this.currentTime <= 0) {
        return 'left: 0';
      }

      return `left: ${((this.currentTime + this.timeOffset) / this.duration) * 100}%`;
    },
    progressStyle: function () {
      return '';
    },
  },
  watch: {
    timeoutId: function () {
      if (this.startDragging) {
        const second = Math.floor((+new Date() - this.startDragging) / 1000);

        this.currentSpeed = second > 6 ? speedMap[6] : speedMap[second];
      }
    },
    currentTime: function (val) {
      if (val === this.currentTime) {
        this.timeOffset = 0;
      }
    },
  },
  mounted() {
    window.addEventListener('keyup', this.onKeyup);
    this.disposableStore.add(keyboardEventHandler.on(TvKeyCode.LEFT, () => this.onSlideLeft()));
    this.disposableStore.add(keyboardEventHandler.on(TvKeyCode.RIGHT, () => this.onSlideRight()));
  },
  beforeUnmount() {
    window.removeEventListener('keyup', this.onKeyup);

    this.disposableStore.dispose();

    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }
  },
  methods: {
    updateCurrentTime: function () {
      this.$emit('updateCurrentTime', this.currentTime + this.timeOffset);
    },
    formatTime: function (seconds) {
      const displayedHours = isNaN(Math.floor(Math.abs(seconds) / (60 * 60)))
        ? 0
        : Math.floor(Math.abs(seconds) / (60 * 60));
      const displayedMinutes = isNaN(Math.floor(Math.abs(seconds) / 60) % 60)
        ? 0
        : Math.floor(Math.abs(seconds) / 60) % 60;
      const displayedSeconds = isNaN(Math.floor(Math.abs(seconds) % 60)) ? 0 : Math.floor(Math.abs(seconds) % 60);

      const sign = seconds < 0 && Math.floor(Math.abs(seconds)) ? '-' : '';

      return `${sign}${displayedHours}:${displayedMinutes.toString().padStart(2, '0')}:${displayedSeconds.toString().padStart(2, '0')}`;
    },
    getCurrentPlayTimeStyle: function () {
      const time = this.$refs.time;
      const timeline = this.$refs.timeline;

      const rect = time?.getBoundingClientRect();
      const parent = time?.parentElement?.getBoundingClientRect();
      const wrapper = timeline?.parentElement?.getBoundingClientRect();

      if (!rect || !parent || !wrapper) {
        return '';
      }

      if (Math.round(parent.left) < Math.round(rect.width / 2)) {
        return `left: -${parent.left}px`;
      }

      if (Math.round(parent.right + rect.width / 2) > wrapper.width) {
        return `right: -${wrapper.width - parent.right}px`;
      }

      return '';
    },
    onKeyup: function () {
      this.startDragging = null;
      this.currentSpeed = 1;
    },
    onSlideLeft: function () {
      if (!this.startDragging) {
        this.startDragging = +new Date();
      }

      if (this.timeoutId) {
        window.clearTimeout(this.timeoutId);
      }

      this.timeoutId = window.setTimeout(() => {
        this.$emit('updateCurrentTime', this.currentTime + this.timeOffset);
      }, 800);

      this.$emit('updateTimelineDragging', true);

      if (Math.abs(this.timeOffset) + this.currentSpeed > Math.round(this.currentTime)) {
        this.timeOffset = -Math.round(this.currentTime);
        return;
      }

      this.timeOffset -= this.currentSpeed;
    },
    onSlideRight: function () {
      if (!this.startDragging) {
        this.startDragging = +new Date();
      }

      if (this.timeoutId) {
        window.clearTimeout(this.timeoutId);
      }

      this.timeoutId = window.setTimeout(() => {
        this.$emit('updateCurrentTime', this.currentTime + this.timeOffset);
      }, 800);

      this.$emit('updateTimelineDragging', true);

      if (this.currentTime + this.timeOffset + this.currentSpeed > this.duration) {
        this.timeOffset = this.duration - this.currentTime;
        return;
      }

      this.timeOffset += this.currentSpeed;
    },
  },
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/fonts';
@import '@/styles/layers';

.timeline {
  position: relative;
  display: flex;
  align-items: center;
  touch-action: none;
  cursor: pointer;
  margin: adjustPx(16px) 0;
}

.progress {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: adjustPx(8px);
  background-color: var(--color-notheme-white-20);
}

.handle {
  position: absolute;
  top: adjustPx(4px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  width: 0;
  height: 0;
  outline: none;

  &::before {
    transform: translateX(adjustPx(-20px));
    display: block;
    width: adjustPx(40px);
    min-width: adjustPx(40px);
    height: adjustPx(40px);
    min-height: adjustPx(40px);
    border: adjustPx(8px) solid var(--color-notheme-bg-tertiary-80);
    border-radius: 50%;
    background-color: var(--color-notheme-bg-secondary);
    content: '';
  }

  &Active {
    &::before {
      border: adjustPx(8px) solid var(--color-notheme-icon-secondary);
      background-color: var(--color-notheme-icon-primary);
    }

    .currentPlayTime {
      opacity: 1;
    }
  }

  &::after {
    content: none;
  }
}

.currentPlayTime {
  position: absolute;
  bottom: adjustPx(52px);
  right: adjustPx(-81px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: adjustPx(162px);
  height: adjustPx(88px);
  border: adjustPx(8px) solid var(--color-notheme-bg-accent);
  border-radius: adjustPx(18px);
  background: var(--color-notheme-bg-secondary-80);
  opacity: 0;
  overflow: hidden;
  color: var(--color-notheme-text-secondary);

  @include f-body-3;
}

.remainPlayTime {
  margin-left: adjustPx(22px);
  width: adjustPx(100px);
  color: var(--color-notheme-text-primary);

  @include f-caption-2;
}
</style>
