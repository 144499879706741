import { ENDPOINTS } from '@package/sdk/src/api/endpoints';
import type { ApiOffer } from '@package/sdk/src/api/offers/offer-types';
import { OffersMapperV2 } from '@package/sdk/src/api/offers/offer-v2-mapper';
import type { OffersV2 } from '@package/sdk/src/api/offers/offers-v2';
import { SubscriptionInfoMapperV2, SubscriptionMapper } from '@package/sdk/src/api/subscriptions/subscription';
import type { ApiSubscription, ApiSubscriptionInfoV2 } from '@package/sdk/src/api/subscriptions/subscription-types';
import type { Subscription, SubscriptionInfoV2 } from '@package/sdk/src/api/subscriptions/types/subscription';
import type { ApiOffersV2 } from '@SDK/api';

import type { RequestService } from '../request-service';
import { HTTPRequestMethod } from '../request-service';

export interface OfferInfoResponse {
  frontend_text_lists: {
    offers_payment_screen_bullets?: string[];
    offers_general_bullets?: string[];
  };
  frontend_texts: {
    offers_bottom_text?: string;
    offers_payment_screen_title?: string;
    offers_screen_title?: string;
  };
  platform: string;
  slug: string;
}

export interface OffersInfo {
  bullets: string[];
  screenTitle?: string;
  paymentTitle?: string;
  bottomText?: string;
  slug: string;
}

type OffersParams = {
  lang?: string;
  limit?: number;
  offerId?: string;
};

type SubscriptionParams = {
  id: string;
  lang?: string;
};

const MAX_OFFERS_LIMIT_COUNT = 3;

export class OffersService {
  constructor(private readonly $http: RequestService) {}

  public abort(message = 'Cancelled by user'): void {
    this.$http.abort(message);
  }

  public async fetchOffers(params?: OffersParams): Promise<OffersV2> {
    const { data } = await this.$http.request<ApiOffersV2>(
      {
        method: HTTPRequestMethod.Get,
        url: ENDPOINTS.OFFERS,
        query: {
          platform: 'SmartTV',
          lang: params?.lang ?? 'ru',
          limit: params?.limit ?? MAX_OFFERS_LIMIT_COUNT,
          ...(params?.offerId && { offer_id: params?.offerId }),
        },
      },
      { canAbort: false, withToken: true, skipTokenValidation: true },
    );

    return OffersMapperV2.map(data);
  }

  public async fetchSubscription(params: SubscriptionParams): Promise<SubscriptionInfoV2> {
    const { data } = await this.$http.request<ApiSubscriptionInfoV2>(
      {
        method: HTTPRequestMethod.Get,
        url: ENDPOINTS.SUBSCRIPTION_INFO,
        params: {
          id: params.id,
        },
        query: {
          lang: params?.lang ?? 'ru',
        },
      },
      { canAbort: false, withToken: true, skipTokenValidation: true },
    );

    return SubscriptionInfoMapperV2.map(data);
  }

  public async fetchOffersInfo(): Promise<OffersInfo> {
    try {
      const { data } = await this.$http.request<OfferInfoResponse>({
        method: HTTPRequestMethod.Get,
        url: ENDPOINTS.PAGES_FRONTEND_GENERAL_INFO_LK,
      });

      return {
        bullets:
          data.frontend_text_lists.offers_payment_screen_bullets ||
          data.frontend_text_lists.offers_general_bullets ||
          [],
        screenTitle: data.frontend_texts.offers_payment_screen_title || data.frontend_texts.offers_screen_title,
        slug: data.slug,
      };
    } catch (e) {
      return {
        bullets: [],
        screenTitle: '',
        slug: '',
      };
    }
  }

  public async fetchOfferInfo(): Promise<OffersInfo> {
    try {
      const { data } = await this.$http.request<OfferInfoResponse>(
        {
          method: HTTPRequestMethod.Get,
          url: ENDPOINTS.PAGES_FRONTEND_PAYMENT_SCREEN,
        },
        { withToken: true },
      );

      return {
        bullets:
          data.frontend_text_lists.offers_payment_screen_bullets ||
          data.frontend_text_lists.offers_general_bullets ||
          [],
        paymentTitle: data.frontend_texts.offers_payment_screen_title,
        bottomText: data.frontend_texts.offers_bottom_text,
        slug: data.slug,
      };
    } catch (e) {
      return {
        bullets: [],
        screenTitle: '',
        slug: '',
      };
    }
  }

  public async toggleAutoRenew(subId: string): Promise<Subscription> {
    const { data } = await this.$http.request<ApiSubscription>(
      {
        method: HTTPRequestMethod.Patch,
        params: {
          id: subId,
        },
        url: ENDPOINTS.SUBSCRIPTION_TOGGLE_AUTO_RENEW,
      },
      { withToken: true },
    );

    return SubscriptionMapper.map(data);
  }
}
