import { ProcessingMapper } from '../base/processing-mapper';
import { getPersonName } from '../content/get-person-name';
import type { ApiActor } from './content-types/actor';
import type { Actor } from './types/actor';

export class ActorMapper {
  static mapMany(items: ApiActor[] = []): Actor[] {
    return ProcessingMapper.process(items.map(ActorMapper.map));
  }

  static map({ first_name, last_name, ...rest }: ApiActor): Actor {
    return ProcessingMapper.process({
      name: getPersonName(first_name, last_name),
      ...rest,
    });
  }
}
