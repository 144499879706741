<template>
  <div :class="$style.wrapper">
    <div>
      <IconLogo :class="$style.icon" />
    </div>
  </div>
</template>

<script>
import IconLogo from '@/assets/icons/logo.svg';

export default {
  components: {
    IconLogo,
  },
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/colors';

.wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-notheme-dim-black-40);
}

.icon {
  width: adjustPx(200px);
  height: adjustPx(192px);
}
</style>
