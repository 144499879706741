import { AvailableContentType, type Channel, type Media } from '@package/sdk/src/api';
import { getTimeInSeconds } from '@PLAYER/player/base/date';
import { AppDateManager } from '@PLAYER/player/modules/date/date-manager';
import { tPlural, translate } from '@PLAYER/player/modules/localization/translate';
import { differenceInDays, getHours, getMinutes, parseISO } from 'date-fns';

const MIN_LICENSE_DURATION_DAYS = 14;

interface GetAvailabilityMessageOptions {
  isActiveSubscription?: boolean;
}

export default function useContentAvailability() {
  const daysLeft = (date: string): number => {
    const currentTimeInSeconds = getTimeInSeconds(new Date(date));
    const dayInSeconds = 60 * 60 * 24;

    return Math.round(currentTimeInSeconds / dayInSeconds);
  };

  const getUnavailableSoonText = (daysCount: number): string => {
    return tPlural('availability.unavailableSoon', daysCount, { days: daysCount });
  };

  const getAvailableSoonText = (date: string, time: string, withHourOrMinute: boolean): string => {
    if (withHourOrMinute) {
      return translate('availability.availableSoonDateAndTime', { date, time });
    }

    return translate('availability.availableSoonDate', { date });
  };

  const getAvailabilityMessage = (content?: Media | Channel, options: GetAvailabilityMessageOptions = {}): string => {
    const { isActiveSubscription } = options;

    const media = content as Media;
    const channel = content as Channel;

    const availability = content?.availability;
    switch (availability) {
      case AvailableContentType.UNAVAILABLE:
      case AvailableContentType.TimeshiftAvailable:
        return media.licenseLockedInfo || channel.lockedInfo || translate('availability.unavailable');
      case AvailableContentType.UNAVAILABLESOON: {
        const licenseDateStart = parseISO(media.licenseDateStart as string);
        const licenseDateEnd = parseISO(media.licenseDateEnd as string);
        const diff = differenceInDays(licenseDateEnd, licenseDateStart);

        // если контент скоро недоступен и длина лицензии меньше двух недель, то не показываем сообщение о заканчивающейся лицензии
        if (diff <= MIN_LICENSE_DURATION_DAYS) {
          return '';
        }

        return isActiveSubscription ? getUnavailableSoonText(daysLeft(media.licenseDateEnd as string)) : '';
      }
      case AvailableContentType.AVAILABLESOON: {
        const licenseDateStart = parseISO(media.licenseDateStart as string);

        const hour = getHours(licenseDateStart);
        const minute = getMinutes(licenseDateStart);
        const hasHourOrMinute = Boolean(hour || minute);

        const date = AppDateManager.format(licenseDateStart);
        const time = AppDateManager.formatDateWithCustomFormat(licenseDateStart, 'H:mm');

        return getAvailableSoonText(date, time, hasHourOrMinute);
      }
      default:
        return '';
    }
  };

  return {
    getAvailabilityMessage,
  };
}
