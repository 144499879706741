import AppEvent, { type AppEventType } from '@package/sdk/src/core/event/event';
import { EventEmitter } from '@package/sdk/src/core/event/event-emitter';
import type { TvKeyCode } from '@package/sdk/src/core/keyboard/tv-keys';
import { DesktopKeyCode, TizenKeyCode, VidaaOSKeyCode, WebOSKeyCode } from '@package/sdk/src/core/keyboard/tv-keys';
import type { IDisposable } from '@package/sdk/src/core/lifecycle/disposable';
import { Disposable } from '@package/sdk/src/core/lifecycle/disposable';
import { isUndefinedOrNull } from '@package/sdk/src/core/std/types';

import { deviceService, telemetryService } from '../services';
import { OperationSystem } from '../services/device/device-types';

export class AppKeyboardEvent extends AppEvent {
  constructor(public readonly domEvent: KeyboardEvent) {
    super();
  }

  public get type(): AppEventType {
    return 'keyboard';
  }

  public preventDefault(): void {
    this.domEvent.preventDefault();
    super.preventDefault();
  }

  public toString() {
    const { type, key, keyCode } = this.domEvent;

    return `
      KEYBOARD
      type: ${type},
      key: ${key},
      keyCode: ${keyCode}
    `;
  }
}

type KeyboardEventMap = Record<TvKeyCode, AppKeyboardEvent>;

const isClient = typeof window !== 'undefined';

class KeyboardEventHandler extends Disposable {
  private readonly emitter = new EventEmitter<KeyboardEventMap>();

  constructor() {
    super();

    if (isClient) {
      this.init();
    }
  }

  private get keyCodes() {
    if (deviceService.os === OperationSystem.WebOs) {
      return WebOSKeyCode;
    }

    if (deviceService.os === OperationSystem.Tizen) {
      return TizenKeyCode;
    }

    if (deviceService.os === OperationSystem.Vidaa) {
      return VidaaOSKeyCode;
    }

    return DesktopKeyCode;
  }

  public getTvKeyCode(keyCode: number): TvKeyCode | undefined {
    for (const [key, value] of this.keyCodes) {
      if (value === keyCode) {
        return key;
      }
    }

    return undefined;
  }

  public trigger<T extends keyof KeyboardEventMap>(event: T) {
    this.emitter.emit(event, new AppKeyboardEvent(new KeyboardEvent('keydown')));
  }

  public on<T extends keyof KeyboardEventMap>(event: T, listener: (arg: KeyboardEventMap[T]) => void): IDisposable {
    return this.emitter.on(event, listener);
  }

  private onKeydown = (event: KeyboardEvent): void => {
    const eventCode = this.getTvKeyCode(event.keyCode);

    if (isUndefinedOrNull(eventCode)) {
      return;
    }

    const appEvent = new AppKeyboardEvent(event);

    // трекаем событие
    telemetryService.trackUserEvent(appEvent);

    this.emitter.emit(eventCode, appEvent);
  };

  private init(): void {
    window.addEventListener('keydown', this.onKeydown);
  }

  public dispose() {
    window.removeEventListener('keydown', this.onKeydown);
    this.emitter.dispose();
  }
}

export const keyboardEventHandler = new KeyboardEventHandler();
