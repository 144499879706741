import { ProcessingMapper } from '../base/processing-mapper';
import { getPersonName } from '../content/get-person-name';
import type { ApiProducer } from './content-types/producer';
import type { Producer } from './types/producer';

export class ProducerMapper {
  static mapMany(producers: ApiProducer[]): Producer[] {
    return ProcessingMapper.process(producers.map(ProducerMapper.map));
  }

  static map({ first_name, last_name, ...rest }: ApiProducer): Producer {
    return ProcessingMapper.process({
      name: getPersonName(first_name, last_name),
      ...rest,
    });
  }
}
