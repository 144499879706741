<template>
  <div>
    <header :class="$style.header">
      <h3 :class="$style.title">
        {{ $t('pages.catalog.contentShuffleTitle') }}
      </h3>

      <NavigatableItem
        :tag="AppButton"
        :active-class="$style.active"
        :text="$t('pages.catalog.next')"
        :on-click="loadContent"
      />
    </header>

    <section :class="$style.content">
      <CatalogShuffleCard v-bind="cardContent" :disabled="isLoading" />
    </section>
  </div>
</template>

<script>
import { MediaContentType } from '@package/sdk/src/api';
import { catalogService, playlistService, useCatalogStore } from '@SMART/index';
import { onMounted, ref, watch } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';
import CatalogShuffleCard from '@/pages/catalog/components/CatalogShuffleCard.vue';

export default {
  components: {
    CatalogShuffleCard,
  },
  props: {
    contentType: {
      type: String,
      default: MediaContentType.MOVIE,
    },
    genres: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
    },
  },
  setup(props) {
    const catalogStore = useCatalogStore();

    const cardContent = ref({
      id: '',
      contentType: MediaContentType.All,
      title: '',
      subtitle: '',
      description: '',
      limit: '',
      poster: '',
    });

    const loadContent = async () => {
      const data = await catalogService.fetchContentRecommendationsRandom(props.contentType);

      cardContent.value = {
        id: data.id,
        contentType: data.contentType,
        title: data.title,
        subtitle: playlistService.getSubtitle(props.genres, data),
        description: data.description,
        limit: playlistService.getLimit(data.ageLimit),
        poster: data.poster,
      };
    };

    watch(
      () => props.contentType,
      async () => await loadContent(),
    );

    onMounted(loadContent);

    return {
      cardContent,
      loadContent,
      AppButton,
    };
  },
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/fonts';
@import '@/styles/colors';

.header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: adjustPx(1271px);
}

.title {
  @include f-title-3;
}

.content {
  margin: adjustPx(42px) 0;
}

.active {
  background-color: var(--color-bg-accent);
}
</style>
