<template>
  <section role="tablist" :class="$style.tabs">
    <UITabs>
      <template v-slot:tabs="{ onTabChanged }">
        <NavigatableItem
          :tag="UITab"
          :disabled="disabled"
          :selected="moviesSelected"
          :active-class="$style.active"
          :on-click="
            () => {
              onTabChanged(0);
              onSelect(MediaContentType.Movie);
            }
          "
        >
          <span :class="$style.text">{{ $t('pages.catalog.filmsTab') }}</span>
        </NavigatableItem>
        <NavigatableItem
          :tag="UITab"
          :disabled="disabled"
          :selected="serialsSelected"
          :active-class="$style.active"
          :class="$style.tab"
          :on-click="
            () => {
              onTabChanged(1);
              onSelect(MediaContentType.Serial);
            }
          "
        >
          <span :class="$style.text">{{ $t('pages.catalog.serialsTab') }}</span>
        </NavigatableItem>
      </template>
    </UITabs>
  </section>
</template>

<script>
import { MediaContentType } from '@package/sdk/src/api';
import IconShuffle from '@SMART/assets/icons/40x40/shuffle.svg';
import { computed } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';
import UITab from '@/components/tabs/UITab.vue';
import UITabs from '@/components/tabs/UITabs.vue';

export default {
  components: {
    IconShuffle,
    UITabs,
  },
  props: {
    showShuffle: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    contentType: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const contentType = computed({
      get: () => props.contentType,
      set: (value) => value && emit('update:type', value),
    });

    const showShuffle = computed({
      get: () => props.showShuffle,
      set: (value) => value && emit('update:shuffle', value),
    });

    const shuffleSelected = computed(() => contentType.value === MediaContentType.Shuffle);
    const moviesSelected = computed(() => contentType.value === MediaContentType.Movie);
    const serialsSelected = computed(() => contentType.value === MediaContentType.Serial);

    const onSelect = (type) => {
      contentType.value = type;
    };

    const onSelectShuffle = () => {
      showShuffle.value = !showShuffle.value;
    };

    return {
      AppButton,
      UITab,
      shuffleSelected,
      moviesSelected,
      serialsSelected,
      onSelect,
      onSelectShuffle,
      MediaContentType,
    };
  },
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/fonts';
@import '@/styles/colors';

.tabs {
  display: flex;
  flex-flow: row nowrap;

  .tab {
    margin-left: adjustPx(10px);
  }

  .text {
    @include f-subtitle-2;
  }
}

.shuffle {
  display: flex;
  align-items: center;
  margin-left: adjustPx(16px);
}

.icon {
  margin-right: adjustPx(10px);
  width: adjustPx(33px);
  height: adjustPx(33px);
}

.iconDisabled {
  color: var(--color-states-text-disabled);
}

.active {
  background-color: var(--color-bg-accent);
}
</style>
