/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum MediaContentType {
  MOVIE = 'movie',
  Movie = 'movie',
  SERIAL = 'serial',
  Serial = 'serial',
  SEASON = 'season',
  Season = 'season',
  EPISODE = 'episode',
  Episode = 'episode',
  ALL = 'all',
  All = 'all',
}

export enum AvailableContentType {
  AVAILABLE = 'available',
  AVAILABLESOON = 'available_soon',
  UNAVAILABLE = 'unavailable',
  UNAVAILABLESOON = 'unavailable_soon',
  TimeshiftAvailable = 'timeshift_available',
}
