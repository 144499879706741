/** @see https://webostv.developer.lge.com/develop/references/tv-device-information */

import { WebOsSystemError } from '@package/sdk/src/core';
import { onUnexpectedError } from '@package/sdk/src/core/errors/on-unexpected-error';

export namespace WebOS {
  export enum SystemParameter {
    BoardType = 'boardType',
    ModelName = 'modelName',
    FirmwareVersion = 'firmwareVersion',
    UHD = 'UHD',
    SDKVersion = 'sdkVersion',
    Supports3D = '_3d',
  }

  export interface DeviceInfo {
    returnValue: boolean;
    errorCode?: string;
    errorText?: string;
    boardType?: string;
    modelName?: string;
    firmwareVersion?: string;
    sdkVersion?: string;
    UHD?: boolean;
    _3d?: boolean;
  }

  export interface SystemInfo {
    identifier: string;
    isKeyboardVisible: boolean;
    deviceInfo: string;
    timeZone: string;
    launchParams: string;
    launchReason: string;
    activityId: string;
    locale: string;
    localeRegion: string;
    tvSystemInfo: {
      countryGroup: string;
      tvSystemName: string;
    };
    highContrast: string;
    voiceControl: string;
    screenOrientation: string;
    currentOrientation: string;
    isActivated: boolean;
    phoneRegion: string;
    timeFormat: string;
    isMinimal: boolean;
  }

  export interface RequestParams {
    method: string;
    parameters?: {
      keys: SystemParameter[];
    };
    onComplete: (response: DeviceInfo) => void;
    onFailure: (error: Error) => void;
  }

  export interface SystemInfoTransformed {
    appId?: string;
    deviceInfo?: string;
    modelName?: string;
    platformVersion?: string;
    sdkVersion?: string;
    firmwareVersion?: string;
    boardType?: string;
    screen: {
      screenOrientation?: string;
      screenWidth?: 1920;
      screenHeight?: 1080;
    };
    features: {
      UHD?: boolean;
      _3d?: boolean;
    };
  }

  export interface InternalLunaService {
    request: (service: string, params: WebOS.RequestParams) => void;
  }

  export interface KeyboardService {
    isShowing(): boolean;
  }

  export interface SystemInfoService {
    country: string;
  }

  export interface MagicRemoteEvent extends Event {
    detail: {
      type: 'Enter' | 'Leave';
    };
  }

  export interface KeyboardStateChangeEvent extends Event {
    detail: {
      visibility: boolean;
    };
  }

  export interface MagicRemoteCursorStateChangeEvent extends Event {
    detail: {
      visibility: boolean;
    };
  }
}

declare global {
  interface DocumentEventMap {
    webOSLaunch: Event;
    webOSRelaunch: Event;
    webOSLocaleChange: Event;
    webOSMouse: WebOS.MagicRemoteEvent;
    keyboardStateChange: WebOS.KeyboardStateChangeEvent;
    cursorStateChange: WebOS.MagicRemoteCursorStateChangeEvent;
  }
}

declare let webOS: {
  systemInfo: WebOS.SystemInfoService;
  service: WebOS.InternalLunaService;
  fetchAppId(): string;
  keyboard: WebOS.KeyboardService;
  platformBack(): void;
};

declare let webOSSystem: WebOS.SystemInfo;
declare let PalmSystem: WebOS.SystemInfo;

export function getWebOSLaunchParams(): string | undefined {
  try {
    if (webOSSystem) {
      return webOSSystem.launchParams;
    } else if (PalmSystem) {
      return PalmSystem.launchParams;
    }
  } catch (error) {
    onUnexpectedError(error);
    return undefined;
  }

  return undefined;
}

export const transformSystemWebOSInformation = (
  webOSSystem: WebOS.SystemInfo,
  deviceInfo: WebOS.DeviceInfo,
): WebOS.SystemInfoTransformed => {
  const info = JSON.parse(webOSSystem?.deviceInfo || 'null');

  return {
    appId: webOSSystem.identifier,
    modelName: deviceInfo.modelName,
    platformVersion: info.platformVersion,
    sdkVersion: deviceInfo.sdkVersion,
    firmwareVersion: deviceInfo.firmwareVersion,
    boardType: deviceInfo.boardType,
    deviceInfo: webOSSystem.deviceInfo,
    screen: {
      screenOrientation: webOSSystem.screenOrientation,
      screenWidth: info?.screenWidth,
      screenHeight: info?.screenHeight,
    },
    features: {
      UHD: deviceInfo.UHD,
      _3d: deviceInfo._3d,
    },
  };
};

const params = Object.values(WebOS.SystemParameter);
export const getSystemWebOSInformation = (): Promise<WebOS.SystemInfoTransformed | undefined> => {
  return new Promise((resolve, reject) => {
    if (!('webOS' in window)) {
      return resolve(undefined);
    }

    const onComplete = (response: WebOS.DeviceInfo) => {
      const isSucceeded = response.returnValue;

      if (isSucceeded) {
        const transformed = transformSystemWebOSInformation(webOSSystem, response);

        return resolve(transformed);
      }

      reject(new WebOsSystemError('Expect WebOSDeviceInfo.returnValue to be defined'));
    };

    try {
      webOS.service.request('luna://com.webos.service.tv.systemproperty', {
        method: 'getSystemInfo',
        parameters: { keys: params },
        onComplete,
        onFailure: (error) => reject(error),
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const VENDOR_NAME = 'LG';
export const DEFAULT_DEVICE_NAME = `${VENDOR_NAME}`;
