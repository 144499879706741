<template>
  <div>
    <NavigatableItem
      :class="[{ [$style.play]: true }, className]"
      :active-class="activeClass ? activeClass : $style.playActive"
      :tag="AppButton"
      :text="primaryButtonText"
      :focus-key="focusKey"
      :autofocus="!isDisabled"
      :disabled="isDisabled"
      :on-click="onPlayContent"
      @active="$emit('active')"
    />

    <UIModal modalTarget="modals">
      <section v-if="isUnavailableContentModalShown" :class="$style.modalUnavailable">
        <h1 :class="$style.modalUnavailableTitle">{{ modalTitle }}</h1>

        <nav :class="$style.modalUnavailableNavigation">
          <NavigatableItem :tag="AppButton" :text="$t('pages.mediaCard.watch')" :on-click="onPlayContent" />

          <NavigatableItem :tag="AppButton" :text="t('pages.mediaCard.cancel')" :on-click="onCloseModal" />
        </nav>
      </section>
    </UIModal>
  </div>
</template>

<script>
import useMediaContentAvailability from '@package/content-utils/src/code/use-media-content-availability';
import { AvailableContentType, MediaContentType } from '@package/sdk/src/api';
import { isDefined, UnexpectedComponentStateError } from '@package/sdk/src/core';
import * as playerHelpers from '@PLAYER/player/modules/content/player-texts';
import {
  storeToRefs,
  translate,
  useContentStore,
  useMediaContentActions,
  useOfferActions,
  useSessionStore,
} from '@SMART/index';
import { computed, ref } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';
import UIModal from '@/components/modal/UIModal.vue';

export default {
  components: {
    UIModal,
  },
  props: {
    moment: Object,
    focusKey: String,
    className: String,
    activeClass: String,
    contentType: String,
    contentId: String,
    skipModal: Boolean,
    shouldDisableContentWhenUnavailable: Boolean,
  },
  setup(props, { emit, root: { $route: route } }) {
    const contentStore = useContentStore();

    const { openOffersPage } = useOfferActions();
    const { openPlayerPage } = useMediaContentActions();
    const { isAvailable, isUnavailableSoon } = useMediaContentAvailability();

    const { isAuth, isActiveSubscription, isPartnerSubscription, subscription, currentOffer, hasTrialOffer } =
      storeToRefs(useSessionStore());

    const { content } = storeToRefs(contentStore);

    const isUnavailableContentModalShown = ref(false);
    const modalTitle = ref('');
    const availableEpisodeId = ref('');

    const isContentAvailable = computed(() => isAvailable(content.value) || isUnavailableSoon(content.value));

    const primaryButtonText = computed(() =>
      playerHelpers.getPlayButtonText({
        isAuth: isAuth.value,
        isActiveSubscription: isActiveSubscription.value,
        isPartnerSubscription: isPartnerSubscription.value,
        canContinueWatch: isDefined(content.value?.watchingItem),
        isWatchButtonWithContentTypeText: false,
        isVOD: true,
        isLive: false,
        offer: currentOffer.value,
        hasTrialOffer: hasTrialOffer.value,
        subscription: subscription.value,
        episodeData: undefined,
      }),
    );

    const getAvailableEpisodeId = () => {
      const serial = content.value;

      if (!serial?.seasons) {
        return;
      }

      const seasons = serial.seasons;
      const count = seasons.length;

      for (let i = 0; i < count; i++) {
        const availableEpisodeIndex = seasons[i].episodes.findIndex(
          (episode) => episode.availability === AvailableContentType.AVAILABLE,
        );

        if (availableEpisodeIndex !== -1) {
          return seasons[i].episodes[availableEpisodeIndex].id;
        }
      }
    };

    const onShowModal = async () => {
      if (props.contentType !== MediaContentType.Serial) {
        return;
      }

      const serial = content.value;

      if (!serial?.seasons) {
        return;
      }

      const seasons = serial.seasons;

      const count = seasons.length || 0;
      let availableSeasonNumber = 0;
      let availableEpisodeNumber = 0;

      for (let i = 0; i < count; i++) {
        const availableEpisodeIndex = seasons[i].episodes.findIndex(
          (episode) => episode.availability === AvailableContentType.AVAILABLE,
        );

        if (availableEpisodeIndex !== -1) {
          availableSeasonNumber = i + 1;
          availableEpisodeNumber = availableEpisodeIndex + 1;
          availableEpisodeId.value = seasons[i].episodes[availableEpisodeIndex].id;
          break;
        }
      }

      if (!(availableSeasonNumber || availableEpisodeNumber)) {
        return;
      }

      isUnavailableContentModalShown.value = true;

      modalTitle.value = translate('pages.mediaCard.availabilityTitle', {
        unavailableSeasonNumber: 1,
        unavailableEpisodeNumber: 1,
        availableSeasonNumber,
        availableEpisodeNumber,
      });
    };

    const onCloseModal = async () => {
      isUnavailableContentModalShown.value = false;
      availableEpisodeId.value = '';
      emit('close-modal');
    };

    const onPlayContent = async () => {
      if (!content.value) {
        throw new UnexpectedComponentStateError('content');
      }

      const isSerial = props.contentType
        ? route.params.type === MediaContentType.Serial
        : content.value.contentType !== MediaContentType.Movie;

      const serial = content.value;

      const firstSeason = serial?.seasons && serial.seasons[0];
      const firstEpisode = firstSeason?.episodes && firstSeason.episodes[0];

      let episodeId = isSerial
        ? content.value.watchingItem?.contentId || availableEpisodeId.value || firstEpisode?.id || props.contentId
        : undefined;

      let seasonIndex = props.moment?.seasonNumber ? String(props.moment.seasonNumber) : undefined;
      let episodeIndex = props.moment?.episodeNumber ? String(props.moment.episodeNumber) : undefined;
      if (!isActiveSubscription.value) {
        return openOffersPage();
      }

      if (isSerial && !serial?.watchingItem && serial && !availableEpisodeId.value) {
        const seasons = serial?.seasons;

        if (seasons && !isAvailable(firstEpisode)) {
          if (!props.skipModal) {
            return onShowModal();
          }

          const result = getAvailableEpisodeId();
          episodeId = result?.id;
        }
      }

      if (isSerial && serial?.watchingItem) {
        const isEpisodeAvailable = serial.seasons.find((season) =>
          // eslint-disable-next-line array-callback-return
          season.episodes.find((episode) => {
            if (episode.id === serial.watchingItem?.contentId) {
              return episode.availability === AvailableContentType.AVAILABLE;
            }
          }),
        );

        if (!isEpisodeAvailable) {
          const result = getAvailableEpisodeId();
          episodeId = result?.id;
          seasonIndex = String(result?.availableSeasonIndex);
          episodeIndex = String(result?.availableEpisodeIndex);
        }
      }

      if (!isContentAvailable.value) {
        return openOffersPage();
      }

      isUnavailableContentModalShown.value = false;

      emit('on-play-content');

      return openPlayerPage({
        id: content.value.id,
        kinomId: props.moment?.id,
        kinomTitle: props.moment?.title,
        seasonIndex,
        episodeId,
        episodeIndex,
        title: content.value.title,
        contentType: content.value.contentType,
      });
    };

    const isDisabled = computed(() => !isContentAvailable.value && props.shouldDisableContentWhenUnavailable);

    return {
      AppButton,
      isDisabled,
      onPlayContent,
      modalTitle,
      primaryButtonText,
      isUnavailableContentModalShown,
      onCloseModal,
      isContentAvailable,
    };
  },
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/fonts';

.play {
  display: flex;
  align-items: center;
  padding: 0 adjustPx(50px);
  height: adjustPx(96px);
  border: none !important;
  border-radius: adjustPx(24px);
  background-color: var(--color-bg-ghost);

  @include f-subtitle;

  &Active {
    background-color: var(--color-bg-accent);
  }

  svg {
    display: inline-flex;
    margin-right: adjustPx(15px);
    width: adjustPx(34px);
    height: adjustPx(34px);
  }
}

.modalUnavailable {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: var(--color-bg-secondary-60);

  &Title {
    width: adjustPx(800px);
    font-weight: 500;
    font-size: adjustPx(48px);
    text-align: center;
  }

  &Navigation {
    display: flex;
    flex-direction: column;
    margin-top: adjustPx(119px);

    :first-child {
      margin-bottom: adjustPx(20px);
    }
  }
}
</style>
