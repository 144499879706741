import { Disposable, isUndefinedOrNull } from '@package/sdk/src/core';

import { getChromeVersion } from '../../utils/get-chrome-version';
import type { PerformanceFeatureItem, PerformanceFeatureMap, PerformanceFeatureType } from './performance-features';

export abstract class AbstractManufacturerService extends Disposable {
  private readonly vendorBrowserVersion: number;

  protected constructor(
    protected performanceMap: PerformanceFeatureMap,
    platformVersion?: number,
  ) {
    super();

    this.vendorBrowserVersion = platformVersion || getChromeVersion();
  }

  public abstract getDeviceInfo(): unknown;

  public abstract getManufacturerName(): string;

  public abstract getHumanReadableDeviceName(): string;

  public abstract resolveStartupDeeplink(): Promise<void>;

  public abstract init(): Promise<void>;

  public abstract exit(): boolean;

  public abstract setVolume(volume: number | 'up' | 'down'): void;

  public abstract getVolume(): number;

  private checkFeatureAvailability(value: PerformanceFeatureItem): boolean {
    return !isUndefinedOrNull(value) && value.fromVersion >= this.vendorBrowserVersion;
  }

  public getAvailableFeatures(): PerformanceFeatureType[] {
    if (!this.vendorBrowserVersion) {
      return [];
    }

    return Object.entries(this.performanceMap.features).reduce((acc, [key, value]) => {
      if (this.checkFeatureAvailability(value)) {
        acc.push(key as PerformanceFeatureType);
      }

      return acc;
    }, [] as PerformanceFeatureType[]);
  }

  public getFeatureState(feature: PerformanceFeatureType): boolean {
    return this.checkFeatureAvailability(this.performanceMap.features[feature]);
  }
}
