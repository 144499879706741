export enum TvKeyCode {
  UP,
  DOWN,
  LEFT,
  RIGHT,
  ENTER,
  RETURN,
  TOOLS,
  EXIT,
  INFO,
  RED,
  GREEN,
  YELLOW,
  BLUE,
  VOLUME_UP,
  VOLUME_DOWN,
  MUTE,
  CHANNEL_UP,
  CHANNEL_DOWN,
  N0,
  N1,
  N2,
  N3,
  N4,
  N5,
  N6,
  N7,
  N8,
  N9,
  K0,
  K1,
  K2,
  K3,
  K4,
  K5,
  K6,
  K7,
  K8,
  K9,
  PREVIOUS_CH,
  REWIND,
  PAUSE,
  FORWARD,
  REC,
  PLAY,
  STOP,
  IME_DONE,
  IME_RETURN,
  PRECH,
}

export const DesktopKeyCode = new Map<TvKeyCode, number>([
  [TvKeyCode.UP, 38],
  [TvKeyCode.DOWN, 40],
  [TvKeyCode.LEFT, 37],
  [TvKeyCode.RIGHT, 39],
  [TvKeyCode.ENTER, 13], // enter
  [TvKeyCode.RETURN, 8], // backspace
  [TvKeyCode.TOOLS, 84], // t
  [TvKeyCode.EXIT, 27], // esc
  [TvKeyCode.INFO, 55], // 7
  [TvKeyCode.RED, 65], // a
  [TvKeyCode.GREEN, 66], // b
  [TvKeyCode.YELLOW, 67], // c
  [TvKeyCode.BLUE, 68], // d
  [TvKeyCode.VOLUME_UP, 107], // +
  [TvKeyCode.VOLUME_DOWN, 109], // -
  [TvKeyCode.MUTE, 77], // m
  [TvKeyCode.CHANNEL_UP, 33], // Page Up
  [TvKeyCode.CHANNEL_DOWN, 34], // Page Down
  [TvKeyCode.N0, 96],
  [TvKeyCode.N1, 97],
  [TvKeyCode.N2, 98],
  [TvKeyCode.N3, 99],
  [TvKeyCode.N4, 100],
  [TvKeyCode.N5, 101],
  [TvKeyCode.N6, 102],
  [TvKeyCode.N7, 103],
  [TvKeyCode.N8, 104],
  [TvKeyCode.N9, 105],
  [TvKeyCode.PREVIOUS_CH, 80], // p
  [TvKeyCode.REWIND, 219], // [
  [TvKeyCode.PAUSE, 19], // Pause/Break
  [TvKeyCode.FORWARD, 221], // ]
  [TvKeyCode.REC, 82], // r
  [TvKeyCode.PLAY, 32], // space
  [TvKeyCode.STOP, 83], // s
]);

export const TizenKeyCode = new Map<TvKeyCode, number>([
  [TvKeyCode.UP, 38],
  [TvKeyCode.DOWN, 40],
  [TvKeyCode.LEFT, 37],
  [TvKeyCode.RIGHT, 39],
  [TvKeyCode.ENTER, 13], // enter
  [TvKeyCode.RETURN, 10009], // backspace
  [TvKeyCode.TOOLS, 10135], // t
  [TvKeyCode.EXIT, 10182], // esc
  [TvKeyCode.INFO, 457], // 7
  [TvKeyCode.RED, 403], // a
  [TvKeyCode.GREEN, 404], // b
  [TvKeyCode.YELLOW, 405], // c
  [TvKeyCode.BLUE, 406], // d
  [TvKeyCode.VOLUME_UP, 447], // +
  [TvKeyCode.VOLUME_DOWN, 448], // -
  [TvKeyCode.MUTE, 449], // m
  [TvKeyCode.CHANNEL_UP, 427], // Page Up
  [TvKeyCode.CHANNEL_DOWN, 428], // Page Down
  [TvKeyCode.N0, 48],
  [TvKeyCode.N1, 49],
  [TvKeyCode.N2, 50],
  [TvKeyCode.N3, 51],
  [TvKeyCode.N4, 52],
  [TvKeyCode.N5, 53],
  [TvKeyCode.N6, 54],
  [TvKeyCode.N7, 55],
  [TvKeyCode.N8, 56],
  [TvKeyCode.N9, 57],
  [TvKeyCode.PREVIOUS_CH, 10190], // p
  [TvKeyCode.REWIND, 412], // [
  [TvKeyCode.PAUSE, 19], // Pause/Break
  [TvKeyCode.FORWARD, 417], // ]
  [TvKeyCode.REC, 416], // r
  [TvKeyCode.PLAY, 415], // space
  [TvKeyCode.STOP, 413], // s
  [TvKeyCode.IME_DONE, 65376], // virtual keyboard 'done'
  [TvKeyCode.IME_RETURN, 65385], // ime cancel/return
]);

export const WebOSKeyCode = new Map<TvKeyCode, number>([
  [TvKeyCode.UP, 38],
  [TvKeyCode.DOWN, 40],
  [TvKeyCode.LEFT, 37],
  [TvKeyCode.RIGHT, 39],
  [TvKeyCode.ENTER, 13],
  [TvKeyCode.RETURN, 461],
  [TvKeyCode.TOOLS, -1],
  [TvKeyCode.EXIT, -1],
  [TvKeyCode.INFO, 457],
  [TvKeyCode.RED, 403],
  [TvKeyCode.GREEN, 404],
  [TvKeyCode.YELLOW, 405],
  [TvKeyCode.BLUE, 406],
  [TvKeyCode.VOLUME_UP, -1],
  [TvKeyCode.VOLUME_DOWN, -1],
  [TvKeyCode.MUTE, 77],
  [TvKeyCode.CHANNEL_UP, 33],
  [TvKeyCode.CHANNEL_DOWN, 34],
  [TvKeyCode.N0, 48],
  [TvKeyCode.N1, 49],
  [TvKeyCode.N2, 50],
  [TvKeyCode.N3, 51],
  [TvKeyCode.N4, 52],
  [TvKeyCode.N5, 53],
  [TvKeyCode.N6, 54],
  [TvKeyCode.N7, 55],
  [TvKeyCode.N8, 56],
  [TvKeyCode.N9, 57],
  [TvKeyCode.K0, 96], // keyCode of numeric TvKeyCode on External USB keyboard
  [TvKeyCode.K1, 97],
  [TvKeyCode.K2, 98],
  [TvKeyCode.K3, 99],
  [TvKeyCode.K4, 100],
  [TvKeyCode.K5, 101],
  [TvKeyCode.K6, 102],
  [TvKeyCode.K7, 103],
  [TvKeyCode.K8, 104],
  [TvKeyCode.K9, 105],
  [TvKeyCode.PREVIOUS_CH, -1],
  [TvKeyCode.REWIND, 412],
  [TvKeyCode.PAUSE, 19],
  [TvKeyCode.FORWARD, 417],
  [TvKeyCode.REC, 416],
  [TvKeyCode.PLAY, 415],
  [TvKeyCode.STOP, 413],
]);

export const VidaaOSKeyCode = new Map<TvKeyCode, number>([
  [TvKeyCode.UP, 38],
  [TvKeyCode.DOWN, 40],
  [TvKeyCode.LEFT, 37],
  [TvKeyCode.RIGHT, 39],
  [TvKeyCode.ENTER, 13],
  [TvKeyCode.RETURN, 8],
  [TvKeyCode.TOOLS, -1],
  [TvKeyCode.EXIT, -1],
  [TvKeyCode.INFO, 457],
  [TvKeyCode.RED, 403],
  [TvKeyCode.GREEN, 404],
  [TvKeyCode.YELLOW, 405],
  [TvKeyCode.BLUE, 406],
  [TvKeyCode.VOLUME_UP, -1],
  [TvKeyCode.VOLUME_DOWN, -1],
  [TvKeyCode.MUTE, 77],
  [TvKeyCode.CHANNEL_UP, 427],
  [TvKeyCode.CHANNEL_DOWN, 428],
  [TvKeyCode.N0, 48],
  [TvKeyCode.N1, 49],
  [TvKeyCode.N2, 50],
  [TvKeyCode.N3, 51],
  [TvKeyCode.N4, 52],
  [TvKeyCode.N5, 53],
  [TvKeyCode.N6, 54],
  [TvKeyCode.N7, 55],
  [TvKeyCode.N8, 56],
  [TvKeyCode.N9, 57],
  [TvKeyCode.K0, 96], // keyCode of numeric TvKeyCode on External USB keyboard
  [TvKeyCode.K1, 97],
  [TvKeyCode.K2, 98],
  [TvKeyCode.K3, 99],
  [TvKeyCode.K4, 100],
  [TvKeyCode.K5, 101],
  [TvKeyCode.K6, 102],
  [TvKeyCode.K7, 103],
  [TvKeyCode.K8, 104],
  [TvKeyCode.K9, 105],
  [TvKeyCode.PREVIOUS_CH, -1],
  [TvKeyCode.REWIND, 412],
  [TvKeyCode.PAUSE, 19],
  [TvKeyCode.FORWARD, 417],
  [TvKeyCode.REC, 416],
  [TvKeyCode.PLAY, 415],
  [TvKeyCode.STOP, 413],
]);
