function _allSettled<T extends readonly unknown[] | []>(
  values: T,
): Promise<{ -readonly [P in keyof T]: PromiseSettledResult<Awaited<T[P]>> }>;
function _allSettled<T>(values: Iterable<T | PromiseLike<T>>): Promise<PromiseSettledResult<Awaited<T>>[]> {
  if (Array.isArray(values)) {
    const mappedPromises = values.map((p) => {
      return p
        .then((value: PromiseSettledResult<T>) => {
          return {
            status: 'fulfilled',
            value,
          };
        })
        .catch((reason: 'rejected') => {
          return {
            status: 'rejected',
            reason,
          };
        });
    });

    return Promise.all(mappedPromises);
  }

  return Promise.all([]);
}

export const allSettled = _allSettled;
