<template>
  <div :class="$style.wrapper">
    <div v-if="previewSrc" :class="$style.preview">
      <app-image :key="previewSrc" :src="previewSrc" :width="1280" />
    </div>

    <TopHeader
      :title="currentActiveBeltItem.title"
      :subtitle="currentActiveBeltItem.subtitle"
      :limit="currentActiveBeltItem.limit"
      :rest-of-time="currentActiveBeltItem.restOfTime"
      :class="$style.header"
    />

    <ScrollViewport ref="el" tag="div" orientation="vertical" :class="$style.content">
      <PlaylistSlider
        v-for="(block, index) in normalizedBlocks"
        :key="block.id"
        :block="block"
        :recommendations="recommendations"
        :watching-items="watchingItemsV2"
        :channels="channels"
        :focus-key-prefix="`PLAYLIST-${index + 1}`"
        @hook:mounted="onPlaylistMounted(index)"
        @activated="(item, value, element) => activateItem(item, value, index, element)"
        @update:watching-items="onUpdateWatchingItems"
      />
      <section :class="$style.stub"></section>
    </ScrollViewport>
    <MyChannelModal v-if="shouldShowMyChannel && false" @finish="finishMyChannel" />
  </div>
</template>

<script>
import ConstantsConfigInstanceSmartTV from '@package/constants/code/constants-config-smart-tv';
import { DisplayType, MediaContentType } from '@package/sdk/src/api';
import { onResetIdle } from '@package/sdk/src/core';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import {
  catalogService,
  channelsService,
  logger,
  onboardingService,
  playlistService,
  RouterPage,
  routerService,
  scrollToElement,
  storeToRefs,
  translate,
  useContentStore,
  useMainPageStore,
  useSessionStore,
  useTvChannelsStore,
} from '@SMART/index';
import {
  computed,
  nextTick,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  provide,
  reactive,
  ref,
} from '@vue/composition-api';

import AppImage from '@/components/app-image/AppImage.vue';
import MyChannelModal from '@/components/my-channel-modal/MyChannelModal.vue';
import PlaylistSlider from '@/components/playlist-slider/PlaylistSlider.vue';
import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';
import TopHeader from '@/components/top-header/TopHeader.vue';

export default {
  components: {
    AppImage,
    PlaylistSlider,
    TopHeader,
    MyChannelModal,
    ScrollViewport,
  },
  setup(_, { refs }) {
    const mainPageStore = useMainPageStore();
    const contentStore = useContentStore();

    const { recommendations } = storeToRefs(mainPageStore);
    const { genres } = storeToRefs(contentStore);
    const { watchingItemsV2, selectedBeltItem, _blocks, profileType } = storeToRefs(mainPageStore);
    const { isAuth, profile, isChildProfileSet } = storeToRefs(useSessionStore());
    const { channels } = storeToRefs(useTvChannelsStore());

    let page = 2;

    const isContentLoaded = ref(false);

    const previewSrc = ref();

    const { el, focusSelf, focusKey } = useNavigatable({
      focusKey: 'MAIN_PAGE',
      forceFocus: true,
      trackChildren: true,
    });
    provide('parentFocusKey', focusKey.value);

    const currentActiveBeltItem = reactive({
      title: '',
      hls: '',
      restOfTime: 0,
      subtitle: '',
      limit: '',
    });

    const shouldShowMyChannel = ref(false);
    const activeItem = ref();

    const isFirstMounted = ref(false);

    const normalizedBlocks = computed(() => (isFirstMounted.value ? _blocks.value : _blocks.value.slice(0, 1)));

    const onPlaylistMounted = () => {
      if (isFirstMounted.value) {
        return;
      }

      window.setTimeout(() => {
        isFirstMounted.value = true;
      }, 500);
    };

    const activateItem = async (item, _, index, element) => {
      let previewLink = '';
      activeItem.value = item;

      scrollToElement(el.value.$el, { top: element.offsetTop - 50 });

      currentActiveBeltItem.restOfTime = 0;
      currentActiveBeltItem.title = '';
      currentActiveBeltItem.subtitle = playlistService.getSubtitle(genres.value, item);

      if ('title' in item) {
        currentActiveBeltItem.title = item.title;
      }

      if ('contentTitle' in item) {
        currentActiveBeltItem.title = item.contentTitle;
      }

      if (item.contentType === MediaContentType.Episode && item.serialTitle) {
        currentActiveBeltItem.title = item.serialTitle;
      }

      currentActiveBeltItem.hls = 'hls' in item ? item.hls : '';

      if ('background' in item) {
        previewLink = item.background ?? '';
      }

      const channel = item;

      if (channel?.currentProgram) {
        previewLink = channel.currentProgram.background ?? previewLink ?? '';
        currentActiveBeltItem.title = channel.currentProgram.title || '';
        currentActiveBeltItem.subtitle = playlistService.getSubtitle(genres.value, {
          duration: channel.currentProgram.duration,
          countries: channel.currentProgram.countries,
          genre: channel.currentProgram.genre,
          contentType: channel.currentProgram.contentType,
          seasonNumber: channel.currentProgram?.seasonNumber,
        });

        if (!channel.currentProgram.genre?.length) {
          currentActiveBeltItem.subtitle = '';
        }
      }

      if ('poster' in item && item.poster) {
        previewLink = item.poster ?? '';
      }

      if ('smartTvBackground' in item) {
        previewLink = item.smartTvBackground ?? '';
      }

      if ('primaryContent' in item) {
        previewLink = item.contentPoster;
        currentActiveBeltItem.subtitle = playlistService.getSubtitle(genres.value, item.primaryContent);
        currentActiveBeltItem.limit = playlistService.getLimit(item?.primaryContent?.ageLimit ?? 0);
      }

      if ('name' in item) {
        currentActiveBeltItem.title = item.name;
        currentActiveBeltItem.subtitle = '';
        currentActiveBeltItem.limit = '';
      }

      const duration = parseInt(item?.duration);
      const offset = parseInt(item?.watchingItem?.offset);

      if (!isNaN(duration) && !isNaN(offset)) {
        currentActiveBeltItem.restOfTime = Math.floor((duration - offset) / 60);
      }

      if ('content' in item) {
        previewLink = item.content.poster;
        currentActiveBeltItem.subtitle = playlistService.getSubtitle(genres.value, item.content);
        currentActiveBeltItem.limit = playlistService.getLimit(item.content.ageLimit ?? 0);
      }

      previewSrc.value = previewLink;

      if (index >= ConstantsConfigInstanceSmartTV.getProperty('loadNextPageOffset') && !isContentLoaded.value && page) {
        const blocksData = await playlistService.fetchBlocks({ page });

        const updatedBlocks = [..._blocks.value, ...blocksData];

        mainPageStore.setBlocks(updatedBlocks);

        isContentLoaded.value = Boolean(
          blocksData.length < ConstantsConfigInstanceSmartTV.getProperty('mainBlockSize'),
        );
        page++;
      }

      if (!page) {
        page =
          Math.ceil(
            (_blocks.length - (watchingItemsV2.value.length ? 1 : 0)) /
              ConstantsConfigInstanceSmartTV.getProperty('mainBlockSize'),
          ) + 1;
      }
    };

    let idleListener;

    const finishMyChannel = async () => {
      shouldShowMyChannel.value = false;
      await nextTick();
    };

    const fetchRecommendations = async () => {
      try {
        return isAuth.value ? catalogService.fetchPersonalRecommendations() : catalogService.fetchColdRecommendations();
      } catch (error) {
        return [];
      }
    };

    const loadMainPageData = async () => {
      mainPageStore.setWatchingItemsV2([]);
      mainPageStore.setBlocks([]);
      await channelsService.fetchChannels();

      const [watchingData, blocksData, recommendationsData] = await Promise.all([
        isAuth.value ? catalogService.fetchContinueWatchItemsV2() : [],
        playlistService.fetchBlocks({ page: 1 }),
        fetchRecommendations(),
      ]);

      mainPageStore.setWatchingItemsV2(watchingData);
      mainPageStore.setRecommendations(recommendationsData);

      const watchingBlock = {
        displayType: DisplayType.ContinueWatch,
        id: 'ContinueWatch',
        beltItems: [],
        contentMomentsList: [],
        offerId: '',
        playlistId: '',
        playlistSlug: '',
        position: 0,
        texts: {},
        title: translate('pages.main.continueWatch'),
      };

      const oldProfile = profileType?.value;
      const newProfile = profile?.value?.kind;

      if (_blocks.value.length && oldProfile === newProfile) {
        let filteredBlocks = _blocks.value.filter((block) => block.displayType !== DisplayType.ContinueWatch);

        filteredBlocks = watchingData.length ? [watchingBlock, ...filteredBlocks] : filteredBlocks;

        mainPageStore.setBlocks(filteredBlocks);

        page = 0;
        return;
      }

      mainPageStore.setProfileType(newProfile);
      const filteredBlocks = watchingData.length ? [watchingBlock, ...blocksData] : blocksData;
      mainPageStore.setBlocks(filteredBlocks);
    };

    const onUpdateWatchingItems = async () => {
      const watchingItemsIndex = watchingItemsV2.value.findIndex((x) => x.id === id);
      if (watchingItemsIndex >= 0) {
        const newItems = [...watchingItemsV2.value];
        newItems.splice(watchingItemsIndex, 1);
        mainPageStore.setWatchingItemsV2(newItems);
      }

      await nextTick();
    };

    onBeforeMount(() => {
      if (isAuth.value && !isChildProfileSet.value && !onboardingService.isParentalCodeFinished()) {
        routerService.push({ name: RouterPage.ParentalPage });
      }
    });

    onMounted(async () => {
      try {
        await loadMainPageData();
      } catch (error) {
        //
      }

      await nextTick();

      const watchingItemIndex = watchingItemsV2.value.findIndex((x) => x.id === selectedBeltItem.value.id);

      if (watchingItemIndex < 0) {
        SpatialNavigation.setFocus(selectedBeltItem.value.focusKey);
      } else {
        SpatialNavigation.setFocus(`PLAYLIST-1-${watchingItemIndex}`);
      }

      const isMyChannelOnboardingShown = !onboardingService.isMyChannelFinished();

      if (isMyChannelOnboardingShown) {
        shouldShowMyChannel.value = true;
      }

      focusSelf();
    });

    onBeforeUnmount(() => {
      if (idleListener) {
        onResetIdle(idleListener);
      }
    });

    return {
      el,
      onPlaylistMounted,
      currentActiveBeltItem,
      previewSrc,
      normalizedBlocks,
      recommendations,
      watchingItemsV2,
      channels,
      activateItem,
      onUpdateWatchingItems,
      finishMyChannel,
      shouldShowMyChannel,
      DisplayType,
    };
  },
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/fonts';
@import '@/styles/layers';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}

.preview {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.preview:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('@/assets/background-gradient-bottom-to-top.webp');
  content: '';
}

.header {
  margin-bottom: adjustPx(50px);
  padding-left: adjustPx(140px);
}

.content {
  position: relative;
  z-index: map-get($map: $layers, $key: --z-index-content);
  margin-left: adjustPx(140px);
  width: 100%;
  height: adjustPx(427px);
  scroll-padding-top: adjustPx(60px);
}
</style>
