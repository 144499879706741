import { ProcessingMapper } from '../base/processing-mapper';
import type { ApiSubscription, ApiSubscriptionInfoV2 } from './subscription-types';
import type { Subscription, SubscriptionInfoV2 } from './types/subscription';

export class SubscriptionMapper {
  static map({
    offer_id,
    canceled_at,
    duration_unit,
    trial_duration,
    trial_duration_unit,
    ends_at,
    price_currency,
    final_price_of_next_period,
    discount_of_next_period,
    ...rest
  }: ApiSubscription): Subscription {
    return ProcessingMapper.process({
      offerId: offer_id,
      canceledAt: canceled_at,
      durationUnit: duration_unit,
      trialDuration: trial_duration,
      trialDurationUnit: trial_duration_unit,
      endsAt: ends_at,
      priceCurrency: price_currency,
      finalPrice: final_price_of_next_period,
      discount: discount_of_next_period,
      ...rest,
    });
  }
}

export class SubscriptionInfoMapperV2 {
  static map(api: ApiSubscriptionInfoV2): SubscriptionInfoV2 {
    return ProcessingMapper.process({
      baseAmount: api.base_amount,
      currency: api.currency,
      endsAt: api.ends_at,
      gracePeriod: api.grace_period,
      id: api.id,
      isActive: api.is_active,
      isCardLinked: api.is_card_linked,
      isRenew: api.is_renew,
      isTrialUsed: api.is_trial_used,
      nextPaymentAmount: api.next_payment_amount,
      offerId: api.offer_id,
      period: api.period,
      saleAmount: api.sale_amount,
      salePeriodsCount: api.sale_periods_count,
      startsAt: api.starts_at,
      status: api.status,
      store: api.store,
      subscriptionImage:
        api.offer.keys?.subscription_image_url ||
        'https://viasat-small.cdnvideo.ru/viasat/store/marketing/offers/offer_bg_1.jpg',
      subscriptionTitle: api.offer.keys?.subscription_title || api.offer.keys?.offer_name,
      trialAmount: api.trial_amount,
      trialPeriod: api.trial_period,
      userId: api.user_id,
    });
  }
}
