<template>
  <Transition>
    <section :class="$style.navigation">
      <NavigatableItem
        v-if="!isBackButtonDisabled"
        :tag="AppButton"
        :class="$style.button"
        :active-class="$style.active"
        :on-click="() => onNextClick(-1)"
        @active="$emit('active')"
      >
        <template v-slot:icon>
          <PrevIcon :class="$style.icon" />
        </template>
      </NavigatableItem>
      <div v-else :class="$style.stub"></div>
      <NavigatableItem
        :tag="AppButton"
        :class="$style.button"
        :active-class="$style.active"
        :on-click="() => $emit('play', !isPlaying)"
        @active="$emit('active')"
      >
        <template v-slot:icon>
          <PauseIcon v-if="isPlaying" :class="$style.icon" />
          <PlayIcon v-else :class="$style.icon" />
        </template>
      </NavigatableItem>
      <NavigatableItem
        v-if="!isNextButtonDisabled"
        :tag="AppButton"
        :class="$style.button"
        :active-class="$style.active"
        :on-click="() => onNextClick(+1)"
        @active="$emit('active')"
      >
        <template v-slot:icon>
          <NextIcon :class="$style.icon" />
        </template>
      </NavigatableItem>
      <div v-else :class="$style.stub"></div>
    </section>
  </Transition>
</template>

<script>
import { throttleWithImmediate } from '@package/sdk/src/core';
import NextIcon from '@SMART/assets/icons/51x51/audio-next.svg';
import PrevIcon from '@SMART/assets/icons/51x51/audio-prev.svg';
import PauseIcon from '@SMART/assets/icons/51x51/pause.svg';
import PlayIcon from '@SMART/assets/icons/51x51/play.svg';

import AppButton from '@/components/app-button/AppButton.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';

const CLICK_TIMEOUT_MS = 1_500;

export default {
  components: {
    NavigatableItem,
    PlayIcon,
    PauseIcon,
    PrevIcon,
    NextIcon,
  },
  props: {
    isBackButtonDisabled: Boolean,
    isNextButtonDisabled: Boolean,
    isPlaying: Boolean,
  },
  setup(props, { emit }) {
    const onNextClick = throttleWithImmediate(
      (step) => {
        emit('next', step);
      },
      { timeout: CLICK_TIMEOUT_MS, immediate: true },
    );
    return { onNextClick, AppButton };
  },
};
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.navigation {
  position: fixed;
  top: adjustPx(490px);
  left: adjustPx(161px);
  right: adjustPx(48px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stub {
  width: adjustPx(96px);
  height: adjustPx(96px);
  background: transparent;
}

.button {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: adjustPx(24px) !important;
  width: adjustPx(96px) !important;
  height: adjustPx(96px) !important;
  border: none !important;
  border-radius: adjustPx(24px) !important;
}

.active {
  background: var(--color-bg-accent) !important;
  color: var(--color-notheme-text-accent) !important;
}

.icon {
  width: adjustPx(51px);
  height: adjustPx(51px);
}
</style>
