import { translate } from '../services/localization/translate';

export function formatDuration(valueS: number, format: string, type: 'text' | 'number' = 'text'): string {
  let hoursText = '';
  let minutesText = '';
  let secondsText = '';
  let minutes = Math.floor(valueS / 60);

  if (format.includes('hh')) {
    minutes %= 60;
  }

  const hours = Math.floor(valueS / 3600);
  const seconds = Math.floor((valueS % 3600) % 60);

  switch (type) {
    case 'text':
      if (hours) {
        hoursText = `${hours} ч`;
      }

      if (minutes) {
        minutesText = `${minutes} мин`;
      }

      if (seconds) {
        secondsText = `${seconds} сек`;
      }
      break;

    case 'number':
      if (hours) {
        hoursText = `${hours}`;
      }

      if (minutes) {
        minutesText = `${minutes}`;
      }

      if (seconds) {
        secondsText = `${seconds}`;
      }
      break;

    default:
      break;
  }

  const h = format.includes('hh') ? hoursText : '';
  const m = format.includes('mm') && minutes > 0 ? minutesText : '';
  const s = format.includes('ss') && seconds > 0 ? secondsText : '';

  return [h, m, s]
    .filter((x) => Boolean(x))
    .join(type === 'number' ? ' : ' : ' ')
    .trim();
}

export function pluralize(count: number, key: string): string {
  const lastDigit = count > 20 ? count % 10 : count;

  if (lastDigit === 1) {
    return translate(`${key}.0`);
  }

  if (lastDigit > 1 && lastDigit < 5) {
    return translate(`${key}.1`);
  }

  return translate(`${key}.2`);
}

export function textOverflow(text: string, textLength: number): string {
  return (
    text
      ?.split(' ')
      ?.filter((x) => Boolean(x))
      ?.slice(0, textLength)
      .join(' ') ?? ''
  );
}
