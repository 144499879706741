import { ProcessingMapper } from '../base/processing-mapper';
import { getPersonName } from '../content/get-person-name';
import type { ApiWriter } from './content-types/writer';
import type { Writer } from './types/writer';

export class WriterMapper {
  static mapMany(writers: ApiWriter[]): Writer[] {
    return ProcessingMapper.process(writers.map(WriterMapper.map));
  }

  static map({ first_name, last_name, ...rest }: ApiWriter): Writer {
    return ProcessingMapper.process({
      name: getPersonName(first_name, last_name),
      ...rest,
    });
  }
}
