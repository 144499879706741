<template>
  <NavigatableItem
    focus-key="PLAYLIST_DROPDOWN"
    has-global-access
    :class="$style.dropDown"
    :active-class="$style.active"
    :tag="AppSlotButton"
    :on-click="() => $emit('toggle')"
    @active="$emit('active')"
  >
    <template>
      <div :class="$style.title">
        <div :class="$style.titleText">
          <slot name="icon"></slot>
          <span><slot name="text"></slot></span>
        </div>
        <ArrowDownIcon
          :class="{
            [$style.titleIcon]: true,
            [$style.titleIconRotated]: isOpen,
          }"
        />
      </div>
    </template>
  </NavigatableItem>
</template>

<script>
import ArrowDownIcon from '@SMART/assets/icons/51x51/arrow-down.svg';

import AppSlotButton from '@/components/app-slot-button/AppSlotButton.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';

export default {
  components: {
    NavigatableItem,
    ArrowDownIcon,
  },
  props: {
    isOpen: Boolean,
  },
  data: () => ({ AppSlotButton }),
};
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.dropDown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: adjustPx(16px) !important;
  padding: 0 adjustPx(24px);
  width: adjustPx(604px);
  height: adjustPx(96px);
  border-color: transparent;
  border-radius: adjustPx(24px);
  background-color: var(--color-bg-ghost);
  @include f-label-2;

  &:hover {
    background: var(--color-notheme-bg-accent);
    color: var(--color-notheme-text-accent);

    img {
      filter: brightness(0%);
    }
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &Text {
    display: flex;
    align-items: center;
  }

  &Icon {
    margin-right: adjustPx(16px);
    width: adjustPx(32px);
  }

  &IconRotated {
    transform: rotate(180deg);
    transition-duration: 0.3s;
    transition-property: transform;
  }
}

.active {
  background: var(--color-notheme-bg-accent);
  color: var(--color-notheme-text-accent);

  img {
    filter: brightness(0%);
  }
}

.selected {
  border-radius: adjustPx(32px);
  background: var(--color-notheme-bg-hover-70);
}
</style>
