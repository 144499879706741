import { EventEmitter } from '../event/event-emitter';
import { Disposable, type IDisposable } from '../lifecycle/disposable';

export enum KeyCode {
  ArrowUp = 'ArrowUp',
  ArrowRight = 'ArrowRight',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  Backspace = 'Backspace',
  Space = 'Space',
  KeyD = 'KeyD',
  KeyK = 'KeyK',
  KeyM = 'KeyM',
  KeyI = 'KeyI',
  KeyF = 'KeyF',
  KeyS = 'KeyS',
  KeyB = 'KeyB',
  KeyN = 'KeyN',
  KeyL = 'KeyL',
  Escape = 'Escape',
  Digit1 = 'Digit1',
  Digit2 = 'Digit2',
  Digit3 = 'Digit3',
  Digit4 = 'Digit4',
  Digit5 = 'Digit5',
  Digit6 = 'Digit6',
  Digit7 = 'Digit7',
  Digit8 = 'Digit8',
  Digit9 = 'Digit9',
  Digit0 = 'Digit0',
}

export interface AppKeyboardEvent {
  type: 'keydown' | 'keyup';
  event: KeyboardEvent;
}

type KeyboardEventMap = Record<number | string, AppKeyboardEvent>;

type KeyboardEventPropertyName = 'keyCode' | 'code';

interface IKeyboardHandlerOptions {
  eventPropertyName: KeyboardEventPropertyName;
}

export class KeyboardHandler extends Disposable {
  readonly #emitter = new EventEmitter<KeyboardEventMap>();
  readonly #eventPropertyName: KeyboardEventPropertyName = 'code';

  constructor(options: IKeyboardHandlerOptions = { eventPropertyName: 'code' }) {
    super();

    this.#eventPropertyName = options.eventPropertyName;
  }

  public on<T extends keyof KeyboardEventMap>(event: T, listener: (arg: KeyboardEventMap[T]) => void): IDisposable {
    return this.#emitter.on(event, listener);
  }

  #onKeydown = (event: KeyboardEvent): void => {
    const eventName = event[this.#eventPropertyName];

    this.#emitter.emit(eventName, {
      type: 'keydown',
      event,
    });
  };

  #onKeyup = (event: KeyboardEvent): void => {
    const eventName = event[this.#eventPropertyName];

    this.#emitter.emit(eventName, {
      type: 'keyup',
      event,
    });
  };

  public init(options = { keydown: true, keyup: true }): void {
    if (options.keydown) {
      window.addEventListener('keydown', this.#onKeydown);
    }

    if (options.keyup) {
      window.addEventListener('keyup', this.#onKeyup);
    }
  }

  public override dispose() {
    window.removeEventListener('keydown', this.#onKeydown);
    window.removeEventListener('keyup', this.#onKeyup);
    this.#emitter.dispose();
  }
}
