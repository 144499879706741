export const MOMENT_LIKED_STORAGE_KEY = 'moments:liked';
export const MOMENT_DISLIKED_STORAGE_KEY = 'moments:disliked';
export const MOMENT_CANCELLED_STORAGE_KEY = 'moments:cancelled';
export const NUMBER_OF_ITEMS_TO_SAVE_LIMIT = 5;
export const MOMENTS_SAVED_STORAGE_KEY = 'moments:saved';
export const MOMENTS_REMOVED_STORAGE_KEY = 'moments:removed';

export const FILM_SAVED_STORAGE_KEY = 'collection:saved';
export const FILM_REMOVED_STORAGE_KEY = 'collection:removed';
export const SERIAL_SAVED_STORAGE_KEY = 'serial:saved';
export const SERIAL_REMOVED_STORAGE_KEY = 'serial:removed';
