<template>
  <NavigatableItem
    :tag="AppSlotButton"
    :class="$style.offer"
    :active-class="$style.active"
    :autofocus="markedBest"
    :on-click="onClick"
    @active="$emit('active')"
  >
    <template>
      <p :class="$style.offerTitle">{{ title }}</p>
      <p :class="$style.offerPrice">
        {{ price }}
      </p>
      <div :class="$style.benefits">
        <p v-for="benefit in benefits" :key="benefit" :class="$style.offerText">
          <CheckIcon :class="$style.icon" />
          {{ benefit }}
        </p>
      </div>
      <p v-if="badge" :class="$style.offerBadge">
        {{ badge }}
      </p>
    </template>
  </NavigatableItem>
</template>

<script>
import CheckIcon from '@SMART/assets/icons/33x33/check.svg';

import AppSlotButton from '@/components/app-slot-button/AppSlotButton.vue';

export default {
  components: {
    CheckIcon,
  },
  props: {
    onClick: { type: Function, required: true },
    title: { default: '', type: String },
    benefits: { default: () => [], type: Array },
    price: { default: '', type: String },
    badge: { default: '', type: String },
    markedBest: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({ AppSlotButton }),
};
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/mixins';
@import '@/styles/colors';

.offer {
  position: relative;
  display: flex;
  flex-flow: column;
  padding: adjustPx(30px) adjustPx(42px);
  width: adjustPx(800px);
  border-radius: adjustPx(36px);
  background: var(--color-stroke-field-border);
  text-align: start;

  @include f-body-2;

  &Price {
    margin-top: adjustPx(10px);
    color: var(--color-notheme-white-50);
    @include f-body;
  }

  &Text {
    @include f-body;
  }

  &Title {
    max-width: adjustPx(450px);

    @include f-headline-2;
  }

  &Badge {
    position: absolute;
    top: adjustPx(-10px);
    right: adjustPx(-10px);
    transform: rotate(7deg);
    display: flex;
    justify-content: center;
    align-items: center;
    width: adjustPx(159px);
    height: adjustPx(66px);
    border-radius: adjustPx(26px);
    background: var(--color-text-accent);
    overflow: hidden;
    text-align: center;

    @include f-headline-2;
  }
}

.benefits {
  display: flex;
  flex-flow: column-reverse;
}

.offer:hover .offerBadge {
  border: adjustPx(4px) solid var(--color-bg-ghost);
}

.active {
  background-color: var(--color-bg-accent);
}

.active .offerBadge {
  border: adjustPx(4px) solid var(--color-bg-ghost);
}

.icon {
  margin-right: adjustPx(16px);
  width: adjustPx(33px);
  height: adjustPx(33px);
}
</style>
