import { Disposable, DisposableStore, EventEmitter } from '@package/sdk/src/core';

import type { MediaSourceTechEventMap } from './events/interfaces';
import type { ExternalQualityLevel } from './modules/quality';

type VideoElement = HTMLVideoElement | HTMLObjectElement;

export interface MediaSourceTechBufferInfo {
  length: number;
  start: number;
}

export interface MediaSourceLoadOptions {
  src: string;
  offset?: number;
  initialQualityLevel: ExternalQualityLevel;
  isLive?: boolean;
}

export default abstract class MediaSourceTech<
  TVideoElement extends VideoElement = HTMLVideoElement,
> extends Disposable {
  protected emitter: EventEmitter<MediaSourceTechEventMap> = new EventEmitter<MediaSourceTechEventMap>();
  protected disposableStore = new DisposableStore();

  protected videoEl: TVideoElement | undefined;

  protected constructor() {
    super();

    this.disposableStore.add(this.emitter);
  }

  public get mediaEl() {
    return this.videoEl;
  }

  public abstract get bandwidth(): number;

  public abstract get videoCodec(): string;

  public abstract get audioCodec(): string;

  public abstract get currentQualityLevelHeight(): number;

  public abstract get latency(): number;

  public async attachMedia(element: TVideoElement): Promise<void> {
    this.videoEl = element;
  }

  public async detachMedia(): Promise<void> {
    this.videoEl = undefined;
  }

  public on<T extends keyof MediaSourceTechEventMap>(event: T, handler: (event: MediaSourceTechEventMap[T]) => void) {
    return this.emitter.on(event, handler);
  }

  public once<T extends keyof MediaSourceTechEventMap>(
    event: T,
    handler: (event?: MediaSourceTechEventMap[T]) => void,
  ) {
    return this.emitter.once(event, handler);
  }

  public off<T extends keyof MediaSourceTechEventMap>(event: T, handler: (event: MediaSourceTechEventMap[T]) => void) {
    return this.emitter.removeEventListener(event, handler);
  }

  protected abstract registerListeners(): void;

  public abstract play(): Promise<void>;

  public abstract pause(): void;

  public abstract seekTo(offset: number): void;

  public abstract get buffer(): MediaSourceTechBufferInfo;

  public abstract init(): Promise<void>;

  public abstract recoverMediaError(): Promise<void>;

  public abstract loadSource(options: MediaSourceLoadOptions): Promise<void>;

  public abstract setNextLevel(level: number): void;

  public abstract getNextLevel(): number;

  public abstract stopLoad(): Promise<void>;

  public abstract startLoad(offset?: number): void;

  public abstract requestSaveMediaOffline(manifestUrl: string): Promise<void>;

  public abstract clearOfflineCache(): Promise<void>;

  public dispose() {
    this.disposableStore.dispose();
  }
}
