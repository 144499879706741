import type { AxiosError } from 'axios';

import { type AuthApiError } from './auth-types';

export class AuthError extends Error {
  constructor(error: unknown) {
    super(AuthError.getErrorMessage(error as AxiosError<AuthApiError>));
  }

  private static getErrorMessage(e: AxiosError<AuthApiError>): string {
    // @ts-ignore
    return (
      e.response?.data?.error?.message ??
      e.response?.data?.error?.user_parameters ??
      e.response?.data?.message ??
      e.message ??
      e
    );
  }
}
