import { ENDPOINTS } from '@package/sdk/src/api/endpoints';
import { type ApiProfile, type Profile, ProfilesMapper } from '@package/sdk/src/api/profiles/profiles';
import { type User } from '@package/sdk/src/api/users/types/session-info';
import { UserMapper } from '@package/sdk/src/api/users/user';
import { type ApiUser } from '@package/sdk/src/api/users/user-types';
import { EventEmitter } from '@package/sdk/src/core';

import { getStoreProperty, initStore, VuexStoreNamespace } from '../../stores';
import type { DeviceService } from '../device/device-service';
import type { EnvironmentService } from '../environment/environment-service';
import type { RequestService } from '../request-service';
import { HTTPRequestMethod } from '../request-service';
import type { IStorageService } from '../storage/storage-service';
import { StorageKeys } from '../storage/storage-types';

export interface UserPresenceParams {
  phoneNumber?: string;
  email?: string;
}

interface UserServiceEventMap {
  'profile.change': undefined;
}

export class UserService {
  public readonly emitter: EventEmitter<UserServiceEventMap> = new EventEmitter<UserServiceEventMap>();

  constructor(
    private readonly $http: RequestService,
    private readonly $storage: IStorageService,
    private readonly $device: DeviceService,
    private readonly $environment: EnvironmentService,
  ) {}

  public abort(message = 'Cancelled by user'): void {
    this.$http.abort(message);
  }

  public getUserInformationForEmailSupport(): string {
    const store = initStore();
    const user = store.getters[getStoreProperty(VuexStoreNamespace.Session, 'user')];

    const mailto = 'help@viju.ru';
    const subject = 'Вопрос по SmartTV приложению viju';

    const login = user?.phoneNumber || user?.unconfirmedPhoneNumber || user?.email || user?.unconfirmedEmail;

    const body = `
      Логин    : ${login}
      Версия   : ${this.$environment.getVariable<() => string>('appVersion')()}
      Платформа: ${this.$device.os}
    `;

    return `mailto:${mailto}?subject=${subject}&body=${body}`;
  }

  public async fetchUser(): Promise<User | undefined> {
    const accessToken = this.$storage.getItem(StorageKeys.AccessToken);

    if (!accessToken) {
      return undefined;
    }

    const { data } = await this.$http.request<ApiUser>(
      {
        method: HTTPRequestMethod.Get,
        url: ENDPOINTS.SESSIONS_USER_CURRENT,
      },
      { withToken: true },
    );

    const user = UserMapper.map(data);

    this.$storage.setItem(StorageKeys.User, user);

    return user;
  }

  public async updateUser(user: Partial<User> & Partial<{ otp: string; password: string }>): Promise<User> {
    const { data } = await this.$http.request<ApiUser>(
      {
        method: HTTPRequestMethod.Put,
        url: ENDPOINTS.SESSIONS_USERS,
        data: {
          email: user.email,
          phone_number: user.phoneNumber,
          otp: user?.otp,
          password: user?.password,
        },
      },
      { withToken: true },
    );

    return UserMapper.map(data);
  }

  public async updateUserProfile(profile: Partial<Profile>): Promise<Profile> {
    this.$storage.setItem(StorageKeys.Profile, profile);

    const { data } = await this.$http.request<ApiProfile>(
      {
        method: HTTPRequestMethod.Patch,
        url: ENDPOINTS.SESSIONS_CURRENT_CHANGE_PROFILE,
        data: {
          profile_id: profile.id,
        },
      },
      { withToken: true },
    );

    this.emitter.emit('profile.change');

    return ProfilesMapper.map(data);
  }

  public async fetchUserPresence(params: UserPresenceParams): Promise<boolean> {
    try {
      await this.$http.request(
        {
          method: HTTPRequestMethod.Post,
          url: ENDPOINTS.SESSIONS_USER_PRESENCE,
          data: {
            phone_number: params.phoneNumber,
            email: params.email,
          },
        },
        { withSignature: true },
      );

      return true;
    } catch (e) {
      return false;
    }
  }
}
