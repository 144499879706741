// @ts-nocheck

import { AppCurrency } from '@package/sdk/src/api';
import { AppLanguage } from '@package/sdk/src/core/variation/variation';
import type { EpisodeData } from '@PLAYER/player/modules/content/player-texts';

import ru from '../../../../../translations/smarttv/ru.json';

export function createCommonLocalizeDictionary(lang: AppLanguage) {
  if (lang === AppLanguage.RU) {
    return ru;
  }

  if (lang === AppLanguage.EN) {
    return {} as unknown as typeof ru;
  }

  return {};
}

type TranslationKey = any;

export const AppLanguageManager = new (class {
  public currentLang = AppLanguage.RU;
  public _currentCurrency = AppCurrency.Rub;

  public translations = createCommonLocalizeDictionary(this.currentLang);

  public get currency(): AppCurrency {
    return this._currentCurrency;
  }

  public setTranslations(translations: Record<string, any>) {
    this.translations = translations;
  }

  public setCurrency(currency: AppCurrency) {
    this._currentCurrency = currency;
  }

  public setLang(lang: AppLanguage) {
    this.currentLang = lang;
    this.setTranslations(createCommonLocalizeDictionary(this.currentLang));
  }
})();

export function t(key: TranslationKey, params?: Record<string, string | number> | EpisodeData): string {
  let translation = AppLanguageManager.translations[key];

  if (!translation) {
    return key;
  }

  if (!params) {
    return translation;
  }

  Object.entries({ ...params, appCurrency: AppLanguageManager.currency }).forEach(([keyToReplace, value = '']) => {
    translation = translation
      .replace(`{{ ${keyToReplace} }}`, value.toString())
      .replace(`{{${keyToReplace}}}`, value.toString());
  });

  return translation;
}

export const translate = t;

/**
 * Функция для получения правильного ключа для числительных
 *
 * @param key
 * @param count
 * @returns {string}
 */
function pluralize(key: TranslationKey, count: number): TranslationKey {
  let n = Math.abs(count);

  n = n % 100;
  if (n >= 5 && n <= 20) {
    return (key + '5') as TranslationKey;
  }

  n = n % 10;
  if (n === 1) {
    return (key + '1') as TranslationKey;
  }

  if (n >= 2 && n <= 4) {
    return (key + '2') as TranslationKey;
  }

  return (key + '5') as TranslationKey;
}

/**
 * Перевод ключей с числительными
 *
 * {
 *   away1: 'Вас не было {{ value }} день',
 *   away2: 'Вас не было {{ value }} дня',
 *   away5: 'Вас не было {{ value }} дней'
 * }
 * @example tPlural('away', 2, { value: 32 }) -> 'Вас не было 32 дня'
 *
 * @param key
 * @param count
 * @param params
 * @returns {string}
 */
export function tPlural(key: string, count: number, params?: Record<string, string | number>): string | string[] {
  const pluralKey = pluralize(key as TranslationKey, count);

  return t(pluralKey, params);
}

export const i18n = {
  install: (app: any) => {
    if (app.config?.globalProperties) {
      app.config.globalProperties.$t = t;
      app.config.globalProperties.$tp = tPlural;
    } else {
      app.prototype.$t = t;
      app.prototype.$tp = tPlural;
    }
  },
};

export function translateContentButtonText(
  data: string | { key: TranslationKey; count?: number; params?: Record<string, string | number> },
) {
  if (typeof data === 'string') {
    return data;
  }

  const { key, count, params } = data;

  if (count) {
    const pluralKey = pluralize(key, count);
    return translate(pluralKey, params);
  }

  return translate(key, params);
}
