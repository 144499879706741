import useLogger from '@package/logger/src/use-logger';
import {
  MediaSourceTechEvent,
  type MediaSourceTechEventError,
  type MediaSourceTechEventFragmentChanged,
  type MediaSourceTechEventManifestParsed,
} from '@package/media-player-tech/src/events/media-source-tech-event';
import AbstractMediaTech, {
  type MediaSourceLoadOptions,
  type MediaSourceTechBufferInfo,
} from '@package/media-player-tech/src/media-source-tech';
import { type M3U8MasterPlaylist, M3U8Parser } from '@package/media-player-tech/src/tech/hls/m3u8-parser';
import { Disposable, isNumber, toDisposable, UnexpectedComponentStateError } from '@package/sdk/src/core';

const logger = useLogger('html5-instance', 'media-player');

class Html5VideoElementData extends Disposable {
  public bufferInfo: MediaSourceTechBufferInfo = { length: 0, start: 0 };

  constructor() {
    super();
  }

  public updateBuffer(buffered: TimeRanges, currentTime: number) {
    let range = 0;
    const time = currentTime;
    const bf = buffered;

    while (!(bf.start(range) <= time && time <= bf.end(range))) {
      range += 1;
    }

    const bufferStart = bf.start(range);
    const bufferEnd = bf.end(range);

    const start = bufferStart;
    const length = bufferEnd - bufferStart;

    this.bufferInfo = { length, start };
  }
}

const ONE_SECOND_WEIGHT_1080_KBYTES = 700;

const isBandwidthEnabled = false;
const isBufferEnabled = false;
const isQualityLevelEnabled = false;

interface Html5MediaTechOptions {
  isDurationInfinity?: boolean;
}

export default class Html5MediaTech extends AbstractMediaTech<HTMLVideoElement> {
  private readonly tech = new Html5VideoElementData();
  private manifest?: M3U8MasterPlaylist;
  private currentQualityLevelId = -1;

  constructor(private readonly options: Html5MediaTechOptions) {
    super();
  }

  public get bandwidth(): number {
    if (!isBandwidthEnabled) {
      return 0;
    }

    return this.callWithSafeVideoContext((videoEl) => {
      if (!videoEl.buffered.length) {
        return 0;
      }

      const { buffered, duration } = videoEl;

      const size = videoEl.duration * ONE_SECOND_WEIGHT_1080_KBYTES;
      const end = buffered.end(0);
      const percentDownloaded = (end / duration) * 100;

      return (size / 100) * percentDownloaded;
    });
  }

  public get videoCodec(): string {
    return '';
  }

  public get audioCodec(): string {
    return '';
  }

  public get currentQualityLevelHeight(): number {
    const { manifest, currentQualityLevelId } = this;

    if (!manifest) {
      return 0;
    }

    const currentLevel = manifest.levels.find((level) => currentQualityLevelId === level.id);

    if (!currentLevel) {
      return 0;
    }

    return currentLevel.height;
  }

  public get latency(): number {
    return 0;
  }

  public get buffer(): MediaSourceTechBufferInfo {
    return this.tech.bufferInfo;
  }

  private initManifest() {
    const { manifest } = this;

    if (!manifest) {
      return logger.warn(new UnexpectedComponentStateError('manifest'));
    }

    const { levels } = manifest;

    if (!isQualityLevelEnabled) {
      return;
    }

    const mediaEvent = new MediaSourceTechEvent<MediaSourceTechEventManifestParsed>({
      tech: 'html5',
      originalEvent: undefined,
      data: {
        qualityLevels: levels,
      },
    });

    this.emitter.emit('manifest-parsed', mediaEvent);
  }

  /**
   * @description
   * Парсим манифест самостоятельно, чтобы получить нужное кол-во качеств.
   * (Распаршеный манифест с бека явно будет не раньше чем в 2026 году)
   *
   * @param {string} manifestUrl
   * @private
   */
  private fetchAndParseManifest(manifestUrl: string): void {
    // TODO: пока запрос отсюда не делаем
    // fetchHlsManifest(manifestUrl)
    //   .then((result) => {
    //     try {
    //       this.manifest = M3U8Parser.parseMasterPlaylist(manifestUrl, result);
    //
    //       this.initManifest();
    //     } catch (error) {
    //       logger.error('fetchAndParseManifest', error);
    //     }
    //   })
    //   .catch((error) => logger.error('fetchHlsManifest', error));
  }

  private loadLevel(src: string) {
    this.callWithSafeVideoContext((videoEl) => {
      const currentTime = videoEl.currentTime;
      videoEl.src = src;
      videoEl.currentTime = currentTime;
    });
  }

  private callWithSafeVideoContext = <T = void>(callback: (videoEl: HTMLVideoElement) => T) => {
    if (!this.videoEl) {
      throw new UnexpectedComponentStateError('videoEl');
    }

    return Reflect.apply(callback, undefined, [this.videoEl]);
  };

  protected registerListeners(): void {
    if (!this.videoEl) {
      throw new UnexpectedComponentStateError('videoEl');
    }

    const onError = () =>
      this.callWithSafeVideoContext((videoEl) => {
        const mediaError = videoEl.error;

        const mediaEvent = new MediaSourceTechEvent<MediaSourceTechEventError>({
          tech: 'html5',
          originalEvent: mediaError,
          data: { errorType: '', fatal: false },
        });

        this.emitter.emit('error', mediaEvent);
      });

    const onTimeupdate = () =>
      this.callWithSafeVideoContext((videoEl) => {
        try {
          // Иногда в буфере может быть пусто, поэтому надо сделать перепроверку на всякий
          if (videoEl.buffered.length > 0) {
            if (isBufferEnabled) {
              this.tech.updateBuffer(videoEl.buffered, videoEl.currentTime);
            }

            const mediaEvent = new MediaSourceTechEvent<MediaSourceTechEventFragmentChanged>({
              tech: 'html5',
              originalEvent: undefined,
              data: {
                startFragmentTime: videoEl.seekable.start(0),
                currentTime: videoEl.currentTime,
                levelTotalDuration: videoEl.duration,
              },
            });

            this.emitter.emit('fragment-changed', mediaEvent);
          }
        } catch (error) {
          logger.error(error);
        }
      });

    this.videoEl.addEventListener('error', onError);
    this.videoEl.addEventListener('timeupdate', onTimeupdate);

    this.disposableStore.add(toDisposable(() => this.videoEl?.removeEventListener('timeupdate', onTimeupdate)));
    this.disposableStore.add(toDisposable(() => this.videoEl?.removeEventListener('error', onError)));
  }

  public init() {
    return Promise.resolve(undefined);
  }

  public async loadSource(options: MediaSourceLoadOptions): Promise<void> {
    const { src, offset } = options;

    if (M3U8Parser.isM3U8ManifestUrl(src)) {
      this.fetchAndParseManifest(src);
    }

    return this.callWithSafeVideoContext((videoEl) => {
      videoEl.src = src;

      if (isNumber(offset)) {
        videoEl.currentTime = offset;
      }
    });
  }

  public async attachMedia(element: HTMLVideoElement) {
    await super.attachMedia(element);

    this.registerListeners();
  }

  public async detachMedia() {
    await super.detachMedia();
  }

  public setNextLevel(id: number) {
    if (!this.manifest) {
      return;
    }

    if (id === -1) {
      this.currentQualityLevelId = -1;
      return this.loadLevel(this.manifest.masterSrc);
    }

    const newLevel = this.manifest.levels.find((level) => level.id === id);
    if (!newLevel) {
      return;
    }

    this.currentQualityLevelId = newLevel.id;
    this.loadLevel(newLevel.url);
  }

  public getNextLevel() {
    return this.currentQualityLevelId;
  }

  public recoverMediaError(): Promise<void> {
    this.callWithSafeVideoContext((videoEl) => {
      // По каким-то причинам, проигрывание стопнулось, экран зафризился
      const src = videoEl.src;
      const currentTime = videoEl.currentTime;

      // А вдруг такое, а что
      if (!src) {
        return logger.error('recoverMediaError', 'Try to recover, but not src found');
      }

      // паузим видео, на всякий СЛУЧАЙ. Мы не можем быть в чем-то уверенны в этой жизни
      videoEl.pause();
      // load перезапускает видео с начала, заново инициализирует видео
      videoEl.load();

      if (!this.options.isDurationInfinity) {
        // возвращем к тому времени, что было раньше
        videoEl.currentTime = currentTime;
      }
    });

    return Promise.resolve(undefined);
  }

  public startLoad(offset: number): void {
    return this.callWithSafeVideoContext((videoEl) => {
      if (!this.options.isDurationInfinity) {
        videoEl.currentTime = offset;
      }
    });
  }

  public async stopLoad(): Promise<void> {
    this.callWithSafeVideoContext((videoEl) => {
      videoEl.src = '';
    });
  }

  public dispose() {
    this.tech.dispose();

    super.dispose();
  }

  public requestSaveMediaOffline(_: string): Promise<void> {
    return Promise.resolve(undefined);
  }

  public clearOfflineCache(): Promise<void> {
    return Promise.resolve(undefined);
  }

  public pause(): void {
    this.callWithSafeVideoContext((videoEl) => {
      videoEl.pause();
    });
  }

  public play(): Promise<void> {
    return this.callWithSafeVideoContext((videoEl) => videoEl.play());
  }

  public seekTo(offset: number): void {
    this.callWithSafeVideoContext((videoEl) => {
      videoEl.currentTime = offset;
    });
  }
}
