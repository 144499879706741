import type { AppLanguage, AppVariation } from './../variation/variation';
import { mailingRulesDoc } from './mailing-rules-info';
import { privacyPolicyDoc } from './privacy-policy-info';
import { userAgreementDoc } from './user-agreement-info';

export type Doc = Record<AppVariation, Record<AppLanguage, DocText | null>>;

export interface DocText {
  title: string;
  points: string[] | { title: string; items: string[] }[];
  edition: string;
  texts?: string[];
  text?: string;
}

export enum DocumentType {
  MailingRules = 'mailing-rules',
  PrivacyPolicy = 'privacy-policy',
  UserAgreement = 'user-agreement',
}

const docsMap = {
  [DocumentType.MailingRules]: mailingRulesDoc,
  [DocumentType.UserAgreement]: userAgreementDoc,
  [DocumentType.PrivacyPolicy]: privacyPolicyDoc,
};

export function getDocumentText(
  doc: DocumentType,
  appVariation: AppVariation,
  appLanguage: AppLanguage,
): DocText | null {
  const targetDoc = docsMap[doc];
  const variationDocs = targetDoc[appVariation];

  if (!variationDocs) {
    return null;
  }

  return variationDocs[appLanguage] || null;
}
