export interface BaseEndpoint {
  id: keyof typeof BASE_ENDPOINTS;
  path: string;
  version?: 'v1' | 'v2';
  cacheStrategy: 'default' | 'no-store' | 'max-age';
  cacheTimeMilliseconds?: number;
}

const time = {
  oneHourMs: 3600000,
  oneDayMs: 86400000,
};

const { oneDayMs, oneHourMs } = time;

export interface EndpointQueryMap {
  TOGGLES: undefined;
  TOGGLE_BY_NAME: object;
  CONTENT_MOVIES: {
    years?: { from: number; to: number }[];
    genres_slugs?: string[];
    countries_slugs?: string[];
    order?: string;
    page?: number;
    per_page?: number;
    with_locked?: boolean;
    with_seasons?: boolean;
  };
  CONTENTS_SEO_DATA: {
    years?: { from: number; to: number }[];
    genres_slugs?: string[];
    countries_slugs?: string[];
    order?: string;
    page?: number;
    per_page?: number;
    with_locked?: boolean;
  };
  CONTENT_SERIALS: {
    years?: { from: number; to: number }[];
    genres_slugs?: string[];
    countries_slugs?: string[];
    order?: string;
    page?: number;
    per_page?: number;
    with_locked?: boolean;
    with_seasons?: boolean;
  };
  PROFILES_WATCHING_ITEM_CONTENTS: undefined;
  PAYMENTS_QR_SIGNATURE: undefined;
  PAYMENTS_QR_GENERATE: undefined;
  HEALTH: undefined;
  BONUS_PROGRAMS_REFERRAL: undefined;
  BONUS_PROMO_CODES_APPLY: undefined;
  BONUS_PROMO_CODES_CHECK: undefined;
  PAGES_MAIN: undefined;
  PAGES_MAIN_BLOCKS: undefined;
  PAGES_FRONTEND: undefined;
  PAGES_FRONTEND_SLUG: undefined;
  PARENTAL_CONTROL: undefined;
  PARENTAL_CONTROL_VERIFY: undefined;
  PARENTAL_CONTROL_CHECK_CODE: undefined;
  SESSIONS_BASE: undefined;
  SESSIONS_USERS: undefined;
  SESSIONS_USERS_CONFIRM_CREDENTIAL: undefined;
  SESSIONS_USERS_SEND_CONFIRMATION_CODE: undefined;
  SESSIONS_CHECK_RESET_PASSWORD_TOKEN: undefined;
  SESSIONS_USERS_DELETE_ACCOUNT: undefined;
  SESSIONS_REFRESH: undefined;
  SESSIONS_USER_PRESENCE: undefined;
  SESSIONS_USER_CURRENT: undefined;
  SESSIONS_USER_PROFILES: undefined;
  SESSIONS_USER_PROFILES_ID: undefined;
  SESSIONS_PASSWORDS: undefined;
  SESSIONS_PASSWORDS_SEND_TOKEN: undefined;
  SESSIONS_PASSWORD_SEND_OTP: undefined;
  SESSIONS_SIGN_UP: undefined;
  SESSIONS_ID: undefined;
  SESSIONS_CURRENT_CHANGE_PROFILE: undefined;
  CONTENT_ALL: undefined;
  CONTENT_SERIALS_SLUG: undefined;
  CONTENT_MOVIES_ID: undefined;
  CONTENT_WATCHING_ITEMS_CONTINUE: undefined;
  CONTENT_WATCHING_ITEMS: undefined;
  CONTENT_WATCHING_ITEMS_CREATE: undefined;
  CONTENT_WATCHING_ITEMS_UPDATE_DELETE: undefined;
  CONTENT_WATCHING_ITEMS_HIDE: undefined;
  CONTENTS_PERSONAL_RECOMMENDATIONS: undefined;
  CONTENTS_COLD_RECOMMENDATIONS: undefined;
  CONTENTS_SIMILAR: undefined;
  CONTENTS_MOMENTS: undefined;
  CONTENTS_COMPILATIONS: undefined;
  CONTENT_SERIAL_MANIFEST: undefined;
  CONTENT_MOMENT_PLAYLISTS: undefined;
  CONTENT_RECOMMENDATIONS_RANDOM: undefined;
  CONTENT_MOVIE_MANIFEST: undefined;
  MOMENTS: undefined;
  MOMENT: undefined;
  MOMENTS_CROP: undefined;
  MOMENTS_LIKE: undefined;
  MOMENTS_MULTIPLE_LIKES: undefined;
  MOMENTS_PLAYLISTS_ID: undefined;
  CONTENT_SEARCH_SEMANTIC: undefined;
  CONTENT_SEARCH_TEXT: undefined;
  CONTENTS_SEO_FILTER: undefined;
  COUNTRIES: undefined;
  CPA: undefined;
  GENRES: undefined;
  MOMENTS_FEED: undefined;
  OFFERS: undefined;
  OFFERS_V1: undefined;
  OFFERS_ID_V1: undefined;
  OFFERS_ID: undefined;
  OFFERS_BY_PROMO: undefined;
  SITEMAP: undefined;
  SITEMAPS: undefined;
  SUBSCRIPTION_INFO: undefined;
  SUBSCRIPTION_INFO_V1: undefined;
  SUBSCRIPTION_CANCEL_BONUSES_CHECK: undefined;
  SUBSCRIPTION_CANCEL_BONUSES_OBJECTION: undefined;
  SUBSCRIPTION_CANCEL_BONUSES_APPLY: undefined;
  SUBSCRIPTION_CANCEL_REASONS: undefined;
  SUBSCRIPTION_CLOUDPAYMENTS: undefined;
  SUBSCRIPTION_CLOUDPAYMENTS_ADD_CARD: undefined;
  SUBSCRIPTION_TOGGLE_AUTO_RENEW: undefined;
  SUBSCRIPTION_CLOUDPAYMENTS_INFO: undefined;
  SUBSCRIPTION_SBER_CANCEL_AUTO_RENEW: undefined;
  SUBSCRIPTION_USER: undefined;
  COLLECTION_ITEMS: undefined;
  TV_CATEGORIES: undefined;
  TV_CHANNELS: undefined;
  TV_CHANNELS_PROGRAM: undefined;
  TV_CHANNELS_PROGRAM_ID: undefined;
  TV_CHANNELS_PLAYLIST_ID: undefined;
  TV_CHANNELS_RECOMMENDATIONS: undefined;
  COMPLIMENT_PAGE: undefined;
  SMART_TV_SESSIONS: undefined;
  SMART_TV_SESSIONS_CHECK: undefined;
  SMART_TV_SESSIONS_CONFIRM: undefined;
  COMPILATION_GET_BY_SLUG: undefined;
  PARENTAL_SET_CHILDREN_ACCESS: undefined;
  PARENTAL_SET_NO_CHILDREN_ACCESS: undefined;
  PARENTAL_CHECK_PIN_CODE: undefined;
  PLAYLISTS: undefined;
  YEARS: undefined;
  COMPILATIONS: undefined;
  VPN_NOTICE: undefined;
  RC_TV_SESSION_STATE: undefined;
  RC_TV_TOKEN_GENERATE: undefined;
  PAGES_FRONTEND_GENERAL_INFO_LK: undefined;
  PAGES_FRONTEND_PAYMENT_SCREEN: undefined;
  SEARCH_HISTORIES: undefined;
  FLAGS_TOGGLES: undefined;
  FLAGS_TOGGLE_BY_NAME: undefined;
}

const BASE_ENDPOINTS: Record<keyof EndpointQueryMap, BaseEndpoint> = {
  // GET
  TOGGLES: {
    id: 'TOGGLES',
    path: 'toggles/',
    version: undefined,
    cacheStrategy: 'max-age',
    cacheTimeMilliseconds: time.oneDayMs,
  },
  // GET
  TOGGLE_BY_NAME: {
    id: 'TOGGLE_BY_NAME',
    path: 'toggles/{name}/',
    version: undefined,
    cacheStrategy: 'max-age',
    cacheTimeMilliseconds: oneDayMs,
  },

  // GET/PUT/DELETE
  PROFILES_WATCHING_ITEM_CONTENTS: {
    id: 'PROFILES_WATCHING_ITEM_CONTENTS',
    path: 'watching/profiles/{profile_id}/contents/{content_id}',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // GET
  PAYMENTS_QR_SIGNATURE: {
    id: 'PAYMENTS_QR_SIGNATURE',
    path: 'payments/qr/{payload}',
    version: 'v1',
    cacheStrategy: 'no-store',
  },

  PAYMENTS_QR_GENERATE: {
    id: 'PAYMENTS_QR_GENERATE',
    path: 'payments/qr',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // GET
  HEALTH: { id: 'HEALTH', path: 'health', version: 'v1', cacheStrategy: 'no-store' },
  // GET
  BONUS_PROGRAMS_REFERRAL: {
    id: 'BONUS_PROGRAMS_REFERRAL',
    path: 'bonus_programs/referral',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // POST
  BONUS_PROMO_CODES_APPLY: {
    id: 'BONUS_PROMO_CODES_APPLY',
    path: 'promo_codes/apply',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // POST
  BONUS_PROMO_CODES_CHECK: {
    id: 'BONUS_PROMO_CODES_CHECK',
    path: 'promo_codes/check',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // GET
  PAGES_MAIN: {
    id: 'PAGES_MAIN',
    path: 'main_page',
    version: 'v1',
    cacheStrategy: 'max-age',
    cacheTimeMilliseconds: oneDayMs,
  },
  // GET
  PAGES_MAIN_BLOCKS: {
    id: 'PAGES_MAIN_BLOCKS',
    path: 'main_page/blocks',
    version: 'v1',
    cacheStrategy: 'max-age',
    cacheTimeMilliseconds: oneHourMs,
  },
  // GET
  PAGES_FRONTEND: {
    id: 'PAGES_FRONTEND',
    path: 'frontend_pages/{platform}',
    version: 'v1',
    cacheStrategy: 'max-age',
    cacheTimeMilliseconds: oneDayMs * 7,
  },
  // GET
  PAGES_FRONTEND_SLUG: {
    id: 'PAGES_FRONTEND_SLUG',
    path: 'frontend_pages/{platform}/{slug}',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // POST/PUT/DELETE
  PARENTAL_CONTROL: { id: 'PARENTAL_CONTROL', path: 'parental_control', version: 'v1', cacheStrategy: 'no-store' },
  // GET
  PARENTAL_CONTROL_VERIFY: {
    id: 'PARENTAL_CONTROL_VERIFY',
    path: 'parental_control/verify',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // GET
  PARENTAL_CONTROL_CHECK_CODE: {
    id: 'PARENTAL_CONTROL_CHECK_CODE',
    path: 'parental_control/check_code',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // GET/POST
  SESSIONS_BASE: { id: 'SESSIONS_BASE', path: 'sessions', version: 'v1', cacheStrategy: 'no-store' },
  // PUT
  SESSIONS_USERS: { id: 'SESSIONS_USERS', path: 'users', version: 'v1', cacheStrategy: 'no-store' },
  // POST
  SESSIONS_USERS_CONFIRM_CREDENTIAL: {
    id: 'SESSIONS_USERS_CONFIRM_CREDENTIAL',
    path: 'users/confirm_credential',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // POST
  SESSIONS_USERS_SEND_CONFIRMATION_CODE: {
    id: 'SESSIONS_USERS_SEND_CONFIRMATION_CODE',
    path: 'users/send_confirmation_code',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // POST
  SESSIONS_CHECK_RESET_PASSWORD_TOKEN: {
    id: 'SESSIONS_CHECK_RESET_PASSWORD_TOKEN',
    path: 'users/check_reset_password_token',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // POST
  SESSIONS_USERS_DELETE_ACCOUNT: {
    id: 'SESSIONS_USERS_DELETE_ACCOUNT',
    path: 'users/delete_account',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // POST
  SESSIONS_REFRESH: { id: 'SESSIONS_REFRESH', path: 'sessions/refresh', version: 'v1', cacheStrategy: 'no-store' },
  // POST
  SESSIONS_USER_PRESENCE: {
    id: 'SESSIONS_USER_PRESENCE',
    path: 'users/presence',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // GET
  SESSIONS_USER_CURRENT: {
    id: 'SESSIONS_USER_CURRENT',
    path: 'users/current',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // GET/POST
  SESSIONS_USER_PROFILES: {
    id: 'SESSIONS_USER_PROFILES',
    path: 'users/profiles',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // PUT/DELETE
  SESSIONS_USER_PROFILES_ID: {
    id: 'SESSIONS_USER_PROFILES_ID',
    path: 'users/profiles/{id}',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // POST
  SESSIONS_PASSWORDS: { id: 'SESSIONS_PASSWORDS', path: 'passwords', version: 'v1', cacheStrategy: 'no-store' },
  // POST
  SESSIONS_PASSWORDS_SEND_TOKEN: {
    id: 'SESSIONS_PASSWORDS_SEND_TOKEN',
    path: 'passwords/send_token',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // POST
  SESSIONS_PASSWORD_SEND_OTP: {
    id: 'SESSIONS_PASSWORD_SEND_OTP',
    path: 'passwords/send_otp',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // POST
  SESSIONS_SIGN_UP: { id: 'SESSIONS_SIGN_UP', path: 'sign_up', version: 'v1', cacheStrategy: 'no-store' },
  // DELETE
  SESSIONS_ID: { id: 'SESSIONS_ID', path: 'sessions/{id}', version: 'v1', cacheStrategy: 'no-store' },
  // PATCH
  SESSIONS_CURRENT_CHANGE_PROFILE: {
    id: 'SESSIONS_CURRENT_CHANGE_PROFILE',
    path: 'sessions/current/change_profile',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  CONTENT_ALL: {
    id: 'CONTENT_ALL',
    path: 'contents',
    version: 'v1',
    cacheStrategy: 'max-age',
  },
  // GET
  CONTENT_SERIALS: {
    id: 'CONTENT_SERIALS',
    path: 'content/serials',
    version: 'v1',
    cacheStrategy: 'max-age',
    cacheTimeMilliseconds: oneDayMs,
  },
  // GET
  CONTENT_SERIALS_SLUG: {
    id: 'CONTENT_SERIALS_SLUG',
    path: 'content/serials/{id}',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // GET
  CONTENT_MOVIES: {
    id: 'CONTENT_MOVIES',
    path: 'content/movies',
    version: 'v1',
    cacheStrategy: 'max-age',
    cacheTimeMilliseconds: oneDayMs,
  },
  // GET
  CONTENT_MOVIES_ID: {
    id: 'CONTENT_MOVIES_ID',
    path: 'content/movies/{id}',
    version: 'v1',
    cacheStrategy: 'max-age',
    cacheTimeMilliseconds: oneDayMs,
  },
  // GET
  CONTENT_WATCHING_ITEMS_CONTINUE: {
    id: 'CONTENT_WATCHING_ITEMS_CONTINUE',
    path: 'contents/continue_watch',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // GET/POST
  CONTENT_WATCHING_ITEMS: {
    id: 'CONTENT_WATCHING_ITEMS',
    path: 'content/watching_items',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // POST
  CONTENT_WATCHING_ITEMS_CREATE: {
    id: 'CONTENT_WATCHING_ITEMS_CREATE',
    path: 'content/watching_items/fetch',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // PUT/DELETE
  CONTENT_WATCHING_ITEMS_UPDATE_DELETE: {
    id: 'CONTENT_WATCHING_ITEMS_UPDATE_DELETE',
    path: 'content/watching_items/{id}',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // POST
  CONTENT_WATCHING_ITEMS_HIDE: {
    id: 'CONTENT_WATCHING_ITEMS_HIDE',
    path: 'content/watching_items/{id}/hide',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // GET
  CONTENTS_PERSONAL_RECOMMENDATIONS: {
    id: 'CONTENTS_PERSONAL_RECOMMENDATIONS',
    path: 'contents/personal_compilation',
    version: 'v1',
    cacheStrategy: 'max-age',
    cacheTimeMilliseconds: oneDayMs,
  },
  // GET
  CONTENTS_COLD_RECOMMENDATIONS: {
    id: 'CONTENTS_COLD_RECOMMENDATIONS',
    path: 'contents/cold_recommendations',
    version: 'v1',
    cacheStrategy: 'max-age',
    cacheTimeMilliseconds: oneDayMs,
  },
  // GET
  CONTENTS_SIMILAR: {
    id: 'CONTENTS_SIMILAR',
    path: 'contents/{id}/similar',
    version: 'v1',
    cacheStrategy: 'max-age',
    cacheTimeMilliseconds: oneDayMs * 3,
  },
  // POST
  CONTENTS_MOMENTS: {
    id: 'CONTENTS_MOMENTS',
    path: 'contents/{id}/moments',
    version: 'v1',
    cacheStrategy: 'max-age',
    cacheTimeMilliseconds: oneDayMs * 3,
  },
  // GET
  CONTENTS_COMPILATIONS: {
    id: 'CONTENTS_COMPILATIONS',
    path: 'contents/{id}/collections',
    version: 'v1',
    cacheStrategy: 'max-age',
    cacheTimeMilliseconds: oneDayMs * 3,
  },
  // GET
  CONTENT_SERIAL_MANIFEST: {
    id: 'CONTENT_SERIAL_MANIFEST',
    path: 'content/episodes/{id}/stream_manifest',
    version: 'v1',
    cacheStrategy: 'no-store',
    cacheTimeMilliseconds: oneDayMs,
  },
  // GET
  CONTENT_MOMENT_PLAYLISTS: {
    id: 'CONTENT_MOMENT_PLAYLISTS',
    path: 'content/moment_playlists/{id}/stream_manifest',
    version: 'v1',
    cacheStrategy: 'max-age',
    cacheTimeMilliseconds: oneDayMs,
  },
  CONTENT_RECOMMENDATIONS_RANDOM: {
    id: 'CONTENT_RECOMMENDATIONS_RANDOM',
    path: 'contents/recommendations/random',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // GET
  CONTENT_MOVIE_MANIFEST: {
    id: 'CONTENT_MOVIE_MANIFEST',
    path: 'content/movies/{id}/stream_manifest',
    version: 'v1',
    cacheStrategy: 'no-store',
    cacheTimeMilliseconds: oneDayMs,
  },
  // GET/POST/DELETE
  MOMENTS: { id: 'MOMENTS', path: 'moments', version: 'v1', cacheStrategy: 'no-store' },
  // GET
  MOMENT: {
    id: 'MOMENT',
    path: 'moments/{id}',
    version: 'v1',
    cacheStrategy: 'max-age',
    cacheTimeMilliseconds: oneDayMs,
  },
  // POST
  MOMENTS_CROP: { id: 'MOMENTS_CROP', path: 'moments/{id}/crop', version: 'v1', cacheStrategy: 'no-store' },
  // POST
  MOMENTS_LIKE: { id: 'MOMENTS_LIKE', path: 'moments/{id}/like', version: 'v1', cacheStrategy: 'no-store' },
  // POST
  MOMENTS_MULTIPLE_LIKES: {
    id: 'MOMENTS_MULTIPLE_LIKES',
    path: 'moments/multiple_likes',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // GET
  MOMENTS_PLAYLISTS_ID: {
    id: 'MOMENTS_PLAYLISTS_ID',
    path: 'moment_playlists/{id}',
    version: 'v1',
    cacheStrategy: 'max-age',
    cacheTimeMilliseconds: oneDayMs,
  },
  // GET
  CONTENT_SEARCH_SEMANTIC: {
    id: 'CONTENT_SEARCH_SEMANTIC',
    path: 'contents/semantic_search',
    version: 'v1',
    cacheStrategy: 'max-age',
    cacheTimeMilliseconds: oneHourMs,
  },
  // GET
  CONTENT_SEARCH_TEXT: {
    id: 'CONTENT_SEARCH_TEXT',
    path: 'contents/text_search',
    version: 'v1',
    cacheStrategy: 'max-age',
    cacheTimeMilliseconds: oneHourMs,
  },
  // GET
  CONTENTS_SEO_FILTER: {
    id: 'CONTENTS_SEO_FILTER',
    path: 'contents/seo_filter',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // GET
  CONTENTS_SEO_DATA: { id: 'CONTENTS_SEO_DATA', path: 'contents/seo_data', version: 'v1', cacheStrategy: 'no-store' },
  // GET
  COUNTRIES: { id: 'COUNTRIES', path: 'countries', version: 'v1', cacheStrategy: 'default' },
  // POST
  CPA: { id: 'CPA', path: 'cpa', version: 'v1', cacheStrategy: 'no-store' },
  // GET
  GENRES: { id: 'GENRES', path: 'genres', version: 'v1', cacheStrategy: 'default' },
  // GET
  MOMENTS_FEED: {
    id: 'MOMENTS_FEED',
    path: 'moments_feed',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // GET
  OFFERS: {
    id: 'OFFERS',
    path: 'offers',
    version: 'v2',
    cacheStrategy: 'no-store',
  },
  // GET
  OFFERS_V1: {
    id: 'OFFERS_V1',
    path: 'offers',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  OFFERS_ID_V1: {
    id: 'OFFERS_ID_V1',
    path: 'offers/{id}',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // GET
  OFFERS_ID: {
    id: 'OFFERS_ID',
    path: 'offers/{id}',
    version: 'v2',
    cacheStrategy: 'no-store',
  },
  // GET
  OFFERS_BY_PROMO: {
    id: 'OFFERS_BY_PROMO',
    path: 'offers/by_promo',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // GET
  SITEMAP: {
    id: 'SITEMAP',
    path: 'sitemap',
    version: 'v1',
    cacheStrategy: 'max-age',
    cacheTimeMilliseconds: oneDayMs * 7,
  },
  // GET
  SITEMAPS: {
    id: 'SITEMAPS',
    path: 'sitemaps',
    version: 'v1',
    cacheStrategy: 'max-age',
    cacheTimeMilliseconds: oneDayMs * 7,
  },
  // GET
  SUBSCRIPTION_INFO: {
    id: 'SUBSCRIPTION_INFO',
    path: 'users/{id}/subscription',
    version: 'v2',
    cacheStrategy: 'no-store',
  },
  SUBSCRIPTION_INFO_V1: {
    id: 'SUBSCRIPTION_INFO_V1',
    path: 'users/{id}/subscriptions',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // POST
  SUBSCRIPTION_CANCEL_BONUSES_CHECK: {
    id: 'SUBSCRIPTION_CANCEL_BONUSES_CHECK',
    path: 'subscriptions/{id}/cancel_bonuses/check',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // POST
  SUBSCRIPTION_CANCEL_BONUSES_OBJECTION: {
    id: 'SUBSCRIPTION_CANCEL_BONUSES_OBJECTION',
    path: 'subscriptions/{id}/cancel_bonuses/objection',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // POST
  SUBSCRIPTION_CANCEL_BONUSES_APPLY: {
    id: 'SUBSCRIPTION_CANCEL_BONUSES_APPLY',
    path: 'subscriptions/{id}/cancel_bonuses/apply',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // POST
  SUBSCRIPTION_CANCEL_REASONS: {
    id: 'SUBSCRIPTION_CANCEL_REASONS',
    path: 'subscriptions/cancel_reasons',
    version: 'v1',
    cacheStrategy: 'default',
  },
  // POST
  SUBSCRIPTION_CLOUDPAYMENTS: {
    id: 'SUBSCRIPTION_CLOUDPAYMENTS',
    path: 'subscriptions',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // POST
  SUBSCRIPTION_CLOUDPAYMENTS_ADD_CARD: {
    id: 'SUBSCRIPTION_CLOUDPAYMENTS_ADD_CARD',
    path: 'payment_methods',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // PATCH
  SUBSCRIPTION_TOGGLE_AUTO_RENEW: {
    id: 'SUBSCRIPTION_TOGGLE_AUTO_RENEW',
    path: 'subscriptions/{id}/toggle_auto_renew',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // GET
  SUBSCRIPTION_CLOUDPAYMENTS_INFO: {
    id: 'SUBSCRIPTION_CLOUDPAYMENTS_INFO',
    path: 'payments/info',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // GET
  SUBSCRIPTION_SBER_CANCEL_AUTO_RENEW: {
    id: 'SUBSCRIPTION_SBER_CANCEL_AUTO_RENEW',
    path: 'subscriptions/{id}/disable',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // PATCH
  SUBSCRIPTION_USER: { id: 'SUBSCRIPTION_USER', path: 'users/subscription', version: 'v1', cacheStrategy: 'no-store' },
  // GET/POST/DELETE
  COLLECTION_ITEMS: {
    id: 'COLLECTION_ITEMS',
    path: 'users/collection/items',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // GET
  TV_CATEGORIES: { id: 'TV_CATEGORIES', path: 'tv/categories', version: 'v1', cacheStrategy: 'no-store' },
  // GET
  TV_CHANNELS: { id: 'TV_CHANNELS', path: 'tv/channels', version: 'v1', cacheStrategy: 'no-store' },
  // GET
  TV_CHANNELS_PROGRAM: {
    id: 'TV_CHANNELS_PROGRAM',
    path: 'tv/channels/program',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // GET
  TV_CHANNELS_PROGRAM_ID: {
    id: 'TV_CHANNELS_PROGRAM_ID',
    path: 'tv/channels/{id}/program',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // GET
  TV_CHANNELS_PLAYLIST_ID: {
    id: 'TV_CHANNELS_PLAYLIST_ID',
    path: 'tv/channels/{id}/playlist',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // GET
  TV_CHANNELS_RECOMMENDATIONS: {
    id: 'TV_CHANNELS_RECOMMENDATIONS',
    path: 'tv/channels/recommendations',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // GET
  COMPLIMENT_PAGE: { id: 'COMPLIMENT_PAGE', path: 'compliment_page', version: 'v1', cacheStrategy: 'default' },
  // POST
  SMART_TV_SESSIONS: { id: 'SMART_TV_SESSIONS', path: 'smart_tv_sessions', version: 'v1', cacheStrategy: 'no-store' },
  // POST
  SMART_TV_SESSIONS_CHECK: {
    id: 'SMART_TV_SESSIONS_CHECK',
    path: 'smart_tv_sessions/check',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // POST
  SMART_TV_SESSIONS_CONFIRM: {
    id: 'SMART_TV_SESSIONS_CONFIRM',
    path: 'smart_tv_sessions/confirm',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // GET
  COMPILATION_GET_BY_SLUG: {
    id: 'COMPILATION_GET_BY_SLUG',
    path: 'collections/{idOrSlug}',
    version: 'v1',
    cacheStrategy: 'max-age',
    cacheTimeMilliseconds: oneDayMs * 3,
  },
  // POST
  PARENTAL_SET_CHILDREN_ACCESS: {
    id: 'PARENTAL_SET_CHILDREN_ACCESS',
    path: 'parental_control/set_children_access',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // POST
  PARENTAL_SET_NO_CHILDREN_ACCESS: {
    id: 'PARENTAL_SET_NO_CHILDREN_ACCESS',
    path: 'parental_control/set_no_children_access',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // GET
  PARENTAL_CHECK_PIN_CODE: {
    id: 'PARENTAL_CHECK_PIN_CODE',
    path: 'parental_control/check_code',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  // GET
  PLAYLISTS: { id: 'PLAYLISTS', path: 'playlists', version: 'v1', cacheStrategy: 'default' },
  // GET
  YEARS: { id: 'YEARS', path: 'years', version: 'v1', cacheStrategy: 'default' },
  // GET
  COMPILATIONS: {
    id: 'COMPILATIONS',
    path: 'collections',
    version: 'v1',
    cacheStrategy: 'max-age',
    cacheTimeMilliseconds: oneDayMs * 3,
  },
  // GET
  VPN_NOTICE: { id: 'VPN_NOTICE', path: 'vpn-notice', version: 'v2', cacheStrategy: 'no-store' },
  // POST
  RC_TV_SESSION_STATE: {
    id: 'RC_TV_SESSION_STATE',
    path: 'tvs/{id}/state',
    version: 'v2',
    cacheStrategy: 'no-store',
  },
  RC_TV_TOKEN_GENERATE: {
    id: 'RC_TV_TOKEN_GENERATE',
    path: 'centrifugo/tokens/generate',
    version: 'v2',
    cacheStrategy: 'no-store',
  },
  PAGES_FRONTEND_GENERAL_INFO_LK: {
    id: 'PAGES_FRONTEND_GENERAL_INFO_LK',
    path: 'frontend_pages/smart_tv/offers_general_info_lk',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  PAGES_FRONTEND_PAYMENT_SCREEN: {
    id: 'PAGES_FRONTEND_PAYMENT_SCREEN',
    path: 'frontend_pages/smart_tv/offers_general_payment_screen',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  SEARCH_HISTORIES: {
    id: 'SEARCH_HISTORIES',
    path: 'search_histories',
    version: 'v1',
    cacheStrategy: 'no-store',
  },
  FLAGS_TOGGLES: {
    id: 'FLAGS_TOGGLES',
    path: '/toggles/',
    version: undefined,
    cacheStrategy: 'max-age',
    cacheTimeMilliseconds: time.oneDayMs,
  },
  FLAGS_TOGGLE_BY_NAME: {
    id: 'FLAGS_TOGGLE_BY_NAME',
    path: '/toggles/{name}/',
    version: undefined,
    cacheStrategy: 'max-age',
    cacheTimeMilliseconds: time.oneDayMs,
  },
};

function pathEndpointURL(endpoint: BaseEndpoint) {
  const { path, version } = endpoint;

  if (!version) {
    return path;
  }

  return `api/${version}/${path}`;
}

function addVersionAll(endpoints: typeof BASE_ENDPOINTS): Record<keyof typeof BASE_ENDPOINTS, BaseEndpoint> {
  const dict: any = {} as Record<keyof typeof BASE_ENDPOINTS, BaseEndpoint>;

  const entries = Object.entries(endpoints);

  for (const [key, value] of entries) {
    const path = pathEndpointURL(endpoints[key as keyof typeof BASE_ENDPOINTS]);

    dict[key] = {
      ...value,
      path,
    };
  }

  return dict as Record<keyof typeof BASE_ENDPOINTS, BaseEndpoint>;
}

export const ENDPOINTS = addVersionAll(BASE_ENDPOINTS);
