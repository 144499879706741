<template>
  <section :class="$style.content">
    <h3 v-if="!isLoading && !isContentChanging && !items.length" :class="$style.contentEmptyHeader">
      {{ emptyHeader }}
    </h3>

    <slot name="catalog-shuffle" />

    <ScrollViewport tag="ul" :y="offset" role="list">
      <li v-for="(item, index) in items" :key="index" ref="momentElements" :class="$style.contentRow">
        <UIPoster
          v-for="rowItem in item.row"
          :key="rowItem.id"
          tabindex="0"
          :autofocus="selectedItem && selectedItem.value.id == rowItem.id"
          :class="$style.contentCell"
          :scroll-block="scrollBlock"
          :src="contentType === CollectionContentType.ContentMoment ? rowItem.preview : rowItem.poster"
          :title="rowItem.title"
          :size="posterSize"
          :on-click="() => onSelect(rowItem, item.id)"
          @active="onActive(rowItem, item.id, index)"
          @hook:mounted="onPosterActivated"
          @hook:activated="onPosterActivated"
        />
      </li>
    </ScrollViewport>
  </section>
</template>

<script>
import { debounce } from '@package/sdk/src/core';
import { storeToRefs, useCatalogStore } from '@SMART/index';
import { computed, onActivated, onMounted, ref, watch } from '@vue/composition-api';

import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';

import UIPoster from '../poster/UIPoster.vue';

const CollectionContentType = {
  Content: 'Content',
  ContentMoment: 'ContentMoment',
};

export default {
  components: {
    ScrollViewport,
    UIPoster,
  },
  props: {
    contentType: { default: '' },
    emptyHeader: { default: '' },
    splitFirstLoad: { type: Number },
    onLoadChunk: { default: () => () => {} },
    itemsPerRow: { default: 3 },
    itemsPerScroll: { default: 3 },
    firstLoadSize: { default: 27 },
    forceUpdate: { default: false },
    variant: { default: 'column' },
    scrollBlock: { default: 'center' },
  },
  setup(props, { emit }) {
    const catalogStore = useCatalogStore();
    const onPosterActivated = debounce(() => {
      emit('activated');
    }, 20);

    const { selectedItem } = storeToRefs(catalogStore);

    const momentElements = ref();
    const offset = ref(0);

    const scrollerRef = ref();
    const isLoading = ref(false);
    const isContentChanging = ref(false);
    const items = ref([]);
    const posterSize = computed(() => (props.contentType === CollectionContentType.ContentMoment ? 'kinom' : 'medium'));

    const getBoundariesIds = () => {
      if (!items.value.length) {
        return { firstId: 1, lastId: 1 };
      }

      return {
        firstId: Math.floor(items.value[0].id / props.itemsPerScroll) + 1,
        lastId: Math.ceil((items.value[items.value.length - 1]?.id + 1) / props.itemsPerScroll),
      };
    };

    const splitItemsIntoRows = (data, lineIndex) => {
      const rows = [];

      for (let rowIndex = 0, lindeId = lineIndex; rowIndex < data.length; rowIndex += props.itemsPerRow, lindeId++) {
        rows.push({
          row: data.slice(rowIndex, rowIndex + props.itemsPerRow),
          id: lindeId,
        });
      }

      return rows;
    };

    let isSplitted = false;

    const loadChunk = async (direction, size, page) => {
      const { data, lineIndex } = await props.onLoadChunk({
        boundaries: getBoundariesIds(),
        direction,
        size,
        page,
      });
      const rows = splitItemsIntoRows(data, lineIndex);

      items.value = [...items.value, ...rows];
    };

    const loadChunks = async () => {
      try {
        isLoading.value = true;

        if (!props.splitFirstLoad) {
          return;
        }

        let chunkIndex = items.value.length
          ? Math.floor(
              props.splitFirstLoad -
                ((items.value.length * props.itemsPerRow) / props.firstLoadSize) * props.splitFirstLoad,
            )
          : props.splitFirstLoad - 1;

        let lineIndex = Math.floor(items.value.length / props.itemsPerRow) + 1;

        const size = Math.floor(props.firstLoadSize / props.splitFirstLoad);

        let itemsLoadedLength = items.value.reduce((result, row) => {
          result = result + row.row.length;
          return result;
        }, 0);

        // break if no more content for us
        if (itemsLoadedLength < size) {
          return;
        }

        while (chunkIndex--) {
          itemsLoadedLength = items.value.reduce((result, row) => {
            result = result + row.row.length;
            return result;
          }, 0);
          if (itemsLoadedLength < size * (lineIndex - 1)) {
            break;
          }

          await loadChunk(1, size, lineIndex++);
        }

        isSplitted = true;
      } catch {
        isSplitted = false;
      } finally {
        window.requestAnimationFrame(() => {
          isLoading.value = false;
        });
      }
    };

    const updateItems = (rows, direction) => () => {
      if (direction > 0 && rows.length) {
        items.value = [...items.value, ...rows];

        if (props.splitFirstLoad && !isSplitted) {
          loadChunks();
        }
      } else if (rows.length) {
        items.value = [...rows, ...items.value.slice(0, -props.itemsPerScroll)];
      }
    };

    const onLoadLines = async (dir, page) => {
      if (!isLoading.value || isContentChanging.value) {
        try {
          const boundaries = getBoundariesIds();

          if (dir < 0 && boundaries.firstId === 1) {
            return;
          }

          isLoading.value = true;
          const { data, lineIndex } = await props.onLoadChunk({
            boundaries,
            direction: dir,
            ...(props.splitFirstLoad &&
              !isSplitted && { size: Math.floor(props.firstLoadSize / props.splitFirstLoad) }),
            page,
          });

          const rows = splitItemsIntoRows(data, lineIndex);

          window.requestAnimationFrame(updateItems(rows, dir));
          emit('loaded', data.length);
          return data;
        } catch (e) {
          console.error(e);
        } finally {
          window.requestAnimationFrame(() => {
            isLoading.value = false;
          });
        }
      }
    };

    watch(items, (value) => {
      if (!value.length) {
        emit('loaded', 0);
      }
    });

    const onReloadContent = async () => {
      isContentChanging.value = true;
      isLoading.value = true;

      items.value = [];

      try {
        scrollerRef.value?.scrollToTop();
        await onLoadLines(1, 1);
      } finally {
        isContentChanging.value = false;
        isLoading.value = false;
      }
    };

    watch(
      () => props.forceUpdate,
      (value) => {
        if (value) {
          onReloadContent();
        }
      },
    );

    let isMounted = false;
    let selectedMoment = null;

    onActivated(() => {
      if (isMounted && !items.value.length) {
        return onReloadContent();
      }

      if (items.value.length && !isSplitted && isMounted) {
        return loadChunks();
      }
    });

    onMounted(async () => {
      await onReloadContent();
      isMounted = true;
    });

    const onSelectPlayer = (contentItem) => {
      selectedMoment = contentItem;
    };

    const onSelect = (contentItem, id) => {
      catalogStore.updateSelectedItem({ value: contentItem, rowId: id });

      emit('select:moment', contentItem);
      if (props.contentType === CollectionContentType.ContentMoment) {
        selectedMoment = contentItem;
      }
    };

    const activeItemId = ref(0);

    const isLastPage = ref(false);
    const onLoadNext = async (index) => {
      isLastPage.value = isLastPage.value || Boolean(items.value.length % props.itemsPerScroll);

      if (index >= items.value.length - 4 && !isLoading.value && !isLastPage.value) {
        const page = Math.ceil(items.value.length / props.itemsPerScroll) + 1;

        const data = await onLoadLines(1, page);

        if (!data?.length) {
          isLastPage.value = true;
        }
      }
    };

    const onActive = async (contentItem, id, index) => {
      const rows = props.firstLoadSize / props.itemsPerScroll;

      if (rows - index < 2) {
        onLoadNext(index);
      }

      setTimeout(() => {
        offset.value = momentElements.value?.[id]?.offsetTop - 120 || 0;
      }, 100);

      activeItemId.value = id;
      catalogStore.updateSelectedItem({ value: contentItem, rowId: id });
    };

    return {
      onPosterActivated,
      onActive,
      selectedItem,
      isLoading,
      activeItemId,
      onSelect,
      onSelectPlayer,
      isContentChanging,
      items,
      onLoadLines,
      posterSize,
      CollectionContentType,
      offset,
      momentElements,
    };
  },
};
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/mixins';
@import '@/styles/colors';

$poster-height: adjustPx(560px);
$poster-width: adjustPx(413px);

.contentRow {
  display: flex;
  margin-bottom: adjustPx(16px);
}

.contentCell {
  margin-right: adjustPx(16px);
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;

  &EmptyHeader {
    max-width: adjustPx(716px);

    @include f-body;
  }
}

.posters {
  display: flex;
  flex-flow: row;
  margin-bottom: adjustPx(16px);
  height: $poster-height;
  overflow: hidden;

  &Kinom {
    height: adjustPx(325px);
  }
}

.kinom {
  margin-right: adjustPx(16px);
  overflow: hidden;
}

.videoWrapper {
  outline: none;
}

.video {
  min-width: adjustPx(413px);
  max-width: adjustPx(413px);
  min-height: adjustPx(310px);
  max-height: adjustPx(310px);
  border-radius: adjustPx(35px);
  overflow: hidden;

  video {
    transform: scale(2, 2);
    min-width: adjustPx(413px);
    max-width: adjustPx(413px);
    min-height: adjustPx(310px);
    max-height: adjustPx(310px);
  }
}

.videoActive {
  border: adjustPx(7px) solid var(--color-bg-accent);
  border-radius: adjustPx(40px);
}

.active {
  border: adjustPx(7) solid var(--color-bg-accent);
}
</style>
