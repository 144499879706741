<template>
  <NavigatableItem
    :active-class="$style.active"
    :class="{ [$style.item]: true, [$style.selected]: isSelected }"
    :tag="AppSlotButton"
    :on-click="() => $emit('selected')"
    @active="$emit('active')"
  >
    <template>
      <div :class="$style.moment">
        <app-image
          :id="moment.id"
          :src="moment.preview"
          :alt="moment.contentTitle"
          :class="$style.poster"
          :width="187"
          loading="lazy"
        />
        <div :class="$style.text">
          <p :class="$style.title">{{ moment.contentTitle }}</p>
          <p :class="$style.subtitle">
            <span>{{ getTitle(moment) }}</span>
          </p>
        </div>
      </div>
    </template>
  </NavigatableItem>
</template>

<script>
import AppImage from '@/components/app-image/AppImage.vue';
import AppSlotButton from '@/components/app-slot-button/AppSlotButton.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';

export default {
  components: {
    NavigatableItem,
    AppImage,
  },
  props: {
    isSelected: Boolean,
    moment: Object,
  },
  data: () => ({ AppSlotButton }),
  methods: {
    getTitle(moment) {
      return [moment.contentYear, moment.genres?.[0]?.title || ''].filter((x) => !!x).join(', ');
    },
  },
};
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.item {
  margin-bottom: adjustPx(32px) !important;
  padding: 0;
  border: none;
  background-color: transparent;
  overflow: hidden;

  &:hover {
    border: adjustPx(4px) solid;
    border-color: var(--color-bg-accent);
    border-radius: adjustPx(24px);
    background-color: transparent !important;
    overflow: hidden;
  }
}

.poster {
  width: adjustPx(280px) !important;
  border-radius: adjustPx(32px);
}

.moment {
  display: flex;
  width: adjustPx(708px);
  height: adjustPx(180px);
  border-radius: adjustPx(24px);
  background-color: transparent;
}

.text {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: adjustPx(32px);
  width: adjustPx(364px);
  overflow: hidden;

  .title {
    width: adjustPx(364px);
    max-width: adjustPx(364px);
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
    @include f-label-1;
  }

  .subtitle {
    margin-top: adjustPx(4px);
    color: var(--color-notheme-white-50);
    @include f-body-3;
  }
}

.active {
  border: adjustPx(4px) solid;
  border-color: var(--color-bg-accent);
  border-radius: adjustPx(32px);
  background: transparent;
  overflow: hidden;
}

.selected {
  border-radius: adjustPx(32px);
  background: var(--color-notheme-bg-hover-70);
}

.pinned {
  height: adjustPx(140px);
}
</style>
