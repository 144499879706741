<template>
  <button
    :class="{
      [$style.active]: isActive,
      [$style.button]: true,
      [$style.primary]: variation === 'primary',
      [$style.secondary]: variation === 'secondary',
    }"
    :disabled="disabled"
    :type="type"
    :role="role"
  >
    <slot name="icon" />

    <span v-if="$slots.text" :class="$style.text">
      <slot name="text" />
    </span>

    <slot name="default" />
  </button>
</template>

<script>
export default {
  props: {
    disabled: { default: false },
    variation: { default: 'primary' },
    type: { default: 'button' },
    role: { default: '' },
    isActive: { default: false },
  },
};
</script>

<style module lang="scss">
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '@/styles/colors';

.button {
  margin: 0;
  padding: 0 adjustPx(50px);
  min-height: adjustPx(83px);
  border: adjustPx(4px) solid;
  border-radius: adjustPx(45px);
  outline: none;
  color: var(--color-text-primary);
  cursor: pointer;

  @include f-subtitle;

  &:disabled {
    background-color: var(--color-states-bg-secondary-disabled);
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.primary {
  border-color: transparent;
  background-color: var(--color-bg-ghost);

  &.active,
  &:not(:disabled):hover,
  &:not(:disabled):active {
    background-color: var(--color-bg-accent);
  }
}

.secondary {
  border-color: var(--color-bg-ghost);
  background-color: transparent;

  &.active,
  &:not(:disabled):hover,
  &:not(:disabled):active {
    border-color: var(--color-bg-accent);
  }
}

.text {
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
